import { type IMainModel } from "./IMainModel"
import { type IStorageFile } from "./IStorageFile"
import { type IEmailTemplate } from "./IEmailTemplate"

export const SETTING_ENDPOINT = "settings"

export interface ISetting extends IMainModel {
  id: number
  company_name: string
  enable_all_emails: boolean
  enable_username_password_login: boolean
  primary_color: string
  secondary_color: string
  units: string
  currency: string
  date_format: string
  file_logo_large?: IStorageFile
  file_logo_small?: IStorageFile
  file_logo_large_readonly?: IStorageFile
  file_logo_small_readonly?: IStorageFile

  // Email settings
  enable_email_user_created: boolean
  email_template_user_created: IEmailTemplate | null

  enable_email_user_onboarding: boolean
  email_template_user_onboarding: IEmailTemplate | null

  enable_email_user_forgot_password: boolean
  email_template_user_forgot_password: IEmailTemplate | null

  enable_email_vendor_assigned: boolean
  email_template_vendor_assigned: IEmailTemplate | null

  enable_email_vendor_member_assigned: boolean
  email_template_vendor_member_assigned: IEmailTemplate | null

  enable_email_inspection_returned: boolean
  email_template_inspection_returned: IEmailTemplate | null

  enable_email_inspection_completed: boolean
  email_template_inspection_completed: IEmailTemplate | null

  enable_email_inspection_share: boolean
  email_template_inspection_share: IEmailTemplate | null
}
