import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react"
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  List,
  Popper,
  type PopperProps,
  TextField,
  Typography,
} from "@mui/material"
import FilterIcon from "@mui/icons-material/FilterAlt"
import DeleteIcon from "@mui/icons-material/Delete"
import { type IUseApiPagedResultsResponse } from "../../hooks/useApiPagedLocal"
import { type IFilter } from "../../models/components/IFilter"
import ListItem from "@mui/material/ListItem"
import { type IComboItem } from "../../models/components/IComboItem"
import DrawerRight from "../containers/DrawerRight"
import { Clear } from "@mui/icons-material"
import FilterBookmarksDialog from "./FilterBookmarksDialog"
import { type TFilterBookmarkModelNames } from "../../models/service/IFilterBookmark"

interface IFiltersDataContext {
  availableFilters: IFilter[]
  setAvailableFilters: React.Dispatch<React.SetStateAction<IFilter[]>>
  selectedFilter: IComboItem | null
  addFilter: (filter: IFilter) => void
}

export const FiltersDataContext = createContext<IFiltersDataContext | null>(null)

/**
 * Updates the available filters in the FiltersDataContext based on the provided field and title.
 *
 * @param {string} field - The field represented by the filter.
 * @param {string} title - The title of the filter.
 * @returns {IFiltersDataContext | null} - The updated FiltersDataContext.
 */
export const useFilterDataAvailable = (field: string, title: string): IFiltersDataContext | null => {
  const filtersDataContext = useContext(FiltersDataContext)

  useEffect(() => {
    filtersDataContext?.setAvailableFilters(filters => {
      if (!filters.some(filter => filter.field === field)) {
        return [...filters, { field, title }]
      }
      return filters
    })
  }, [field, title, filtersDataContext])

  return filtersDataContext
}

interface IProps {
  children: React.JSX.Element | React.JSX.Element[] | any
  pagingResults: IUseApiPagedResultsResponse<any>
  modelName?: TFilterBookmarkModelNames
  showButton?: boolean
  openUrlPrefix?: string
  openCloseWithUrl?: boolean
}

/**
 * Renders a button with a filter icon to trigger data filtering.
 *
 * @param {IProps} props - The props object containing any necessary data.
 * @returns {React.ReactElement} The rendered button component with filter icon.
 */
const FiltersData: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children, pagingResults, modelName, showButton = true, openUrlPrefix, openCloseWithUrl = false } = props
  const [availableFilters, setAvailableFilters] = useState<IFilter[]>([])
  const [selectedFilter, setSelectedFilter] = useState<IComboItem | null>(null)

  const filtersToShowComboItems: IComboItem[] = useMemo(() => {
    const existingFilterFields = pagingResults.paging?.filters?.map(filter => filter.field) ?? []
    return availableFilters
      .filter(filter1 => !existingFilterFields.some(f2 => f2 === filter1.field))
      .map(filter1 => ({
        label: filter1.title,
        value: filter1.field,
      }))
  }, [availableFilters?.length, pagingResults.paging?.filters])

  const handleFilterCombo = useCallback(
    (_event: any, comboItem: IComboItem | null) => {
      setSelectedFilter(comboItem)
    },
    [availableFilters],
  )

  const addFilter = useCallback(
    (filter: IFilter) => {
      const filters = pagingResults.paging?.filters
      const theFilters: IFilter[] = filters !== undefined ? [...filters, filter] : [filter]
      pagingResults.handleFilter(theFilters)
      setSelectedFilter(null)
    },
    [pagingResults.handleFilter, pagingResults.paging?.filters],
  )

  const handleDeleteFilter = useCallback(
    (index: number) => () => {
      const filters = pagingResults.paging?.filters
      if (filters !== undefined) {
        const newFilters = [...filters.filter((_, i) => i !== index)]
        pagingResults.handleFilter(newFilters)
      }
      setSelectedFilter(null)
    },
    [pagingResults.handleFilter, pagingResults.paging?.filters],
  )

  const handleDrawerOpen = useCallback(async () => {
    setSelectedFilter(null)
  }, [])

  const handleClear = useCallback(async () => {
    if (pagingResults.paging?.filters !== undefined) {
      pagingResults.handleFilter(pagingResults.paging.filters.filter(f => f.canBeDelete === false))
      setSelectedFilter(null)
    }
  }, [pagingResults.handleFilter, pagingResults.paging?.filters])

  /**
   * This is needed so the autocomplete can be used in right drawer on the report writer.
   * See public/index.html for styling.
   */
  const PopperSelectFilter = useCallback((props: PopperProps) => {
    return <Popper {...props} id="AutocompletePopper" />
  }, [])

  return (
    <FiltersDataContext.Provider value={{ availableFilters, setAvailableFilters, selectedFilter, addFilter }}>
      <DrawerRight
        title="Filters"
        icon={<FilterIcon />}
        badge={pagingResults.paging?.filters?.length}
        showButton={showButton}
        openUrlPrefix={openUrlPrefix}
        openCloseWithUrl={openCloseWithUrl}
        onDrawerOpen={handleDrawerOpen}>
        <Grid container spacing={2} sx={{ mt: 1 }} alignItems="center">
          {modelName !== undefined && (
            <Grid item xs={12}>
              <FilterBookmarksDialog pagingResults={pagingResults} modelName={modelName} />
            </Grid>
          )}
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              autoHighlight
              PopperComponent={PopperSelectFilter}
              onChange={handleFilterCombo}
              value={selectedFilter}
              renderInput={params => <TextField {...params} autoFocus label="Filter" />}
              options={filtersToShowComboItems}
            />
          </Grid>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
        <Box sx={{ mt: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <Typography variant="h5">Current Filters</Typography>
            </Grid>
            <Grid item>
              <Button startIcon={<Clear />} onClick={handleClear}>
                Clear All
              </Button>
            </Grid>
          </Grid>
          <List>
            {pagingResults.paging?.filters?.map((filter: IFilter, index: number) => (
              <ListItem
                key={filter.field}
                secondaryAction={
                  <IconButton edge="end" disabled={filter.canBeDelete === false} onClick={handleDeleteFilter(index)}>
                    <DeleteIcon />
                  </IconButton>
                }>
                {filter.title} - {filter.display}
              </ListItem>
            ))}
          </List>
          {(pagingResults.paging?.filters?.length === undefined || pagingResults.paging.filters.length === 0) && (
            <Alert severity="info">No filters created.</Alert>
          )}
        </Box>
      </DrawerRight>
    </FiltersDataContext.Provider>
  )
}

export default FiltersData
