import React, { useCallback, useState } from "react"
import { Button, Grid } from "@mui/material"
import { useFilterDataAvailable } from "./FiltersData"
import { RestRepository } from "../../repositories/RestRepository"
import { type IListItem } from "../../models/components/IListItem"
import SelectFilteredSingle from "../forms/SelectFilteredSingle"
import AddIcon from "@mui/icons-material/Add"
import { type IFilter } from "../../models/components/IFilter"
import { SERVICE_USER_ENDPOINT } from "../../models/service/IServiceUser"

interface IProps {
  field: string
  title?: string
  children?: React.JSX.Element[]
}

const repository = new RestRepository<IListItem>(SERVICE_USER_ENDPOINT)

/**
 * Filters a service user based on the provided props.
 *
 * @param {IProps} props - The props object containing the field to be filtered.
 * @returns {React.ReactElement} - The filtered serviceUser component.
 */
const FilterServiceUser = (props: IProps): React.ReactElement => {
  const { field, title = "Service User" } = props

  const [item, setItem] = useState<IListItem | null>(null)
  const filtersDataContext = useFilterDataAvailable(field, title)

  const handleAddFilter = useCallback(() => {
    const filter: IFilter = { title, field, value: item?.id, display: item?.name }
    filtersDataContext?.addFilter(filter)
  }, [item, title, field, filtersDataContext?.addFilter])

  return (
    <>
      {filtersDataContext?.selectedFilter?.value === field && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <SelectFilteredSingle
              name="serviceUser"
              label={title}
              defaultValue={item}
              repository={repository}
              onChange={setItem}
            />
          </Grid>
          <Grid item xs />
          <Grid item>
            <Button startIcon={<AddIcon />} onClick={handleAddFilter}>
              Add Filter
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default FilterServiceUser
