import React, { useCallback, useMemo } from "react"
import { Button, TableCell } from "@mui/material"
import { type ILogEntry } from "../../models/service/ILogEntry"
import TruncateText from "../TruncateText"
import { nameToLabel } from "../../utilities/form_utility"

interface IProps {
  children: ILogEntry
  field?: string
  onClick?: (logEntry: ILogEntry) => void
  nameMap?: Record<string, string>
}

/**
 * Render a table cell with truncated log entry changes title.
 *
 * @param {IProps} props - The props object containing the children.
 * @returns {React.ReactElement} - The rendered table cell component.
 */
const TableCellChanges: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children, onClick, nameMap } = props

  const logEntryChangesTitle = useMemo((): string => {
    if (children !== undefined && children !== null) {
      return Object.keys(children.changes)
        .map((key: string) => {
          console.log(key, nameMap)
          if (nameMap?.[key] !== undefined) {
            return nameMap[key]
          }
          return nameToLabel(key)
        })
        .join(", ")
    }
    return "Unknown Changes"
  }, [children, nameMap])

  const handleClick = useCallback(() => {
    onClick?.(children)
  }, [onClick, children])

  return (
    <TableCell>
      <Button size="small" onClick={handleClick}>
        <TruncateText>{logEntryChangesTitle}</TruncateText>
      </Button>
    </TableCell>
  )
}

export default TableCellChanges
