export enum RiskInputType {
  RICH_TEXT = "rich_text",
  TEXT = "text",
  IMAGE = "image",
  INTEGER = "integer",
  DATE = "date",
  FLOAT = "float",
  CURRENCY = "currency",
  AREA = "area",
  PERCENT = "percent",
  YEAR = "year",
  CHECKBOX = "checkbox",
  SELECT = "select",
  LABEL = "label",
  SPACE = "space",
}

export interface IRiskCalc {
  field1: string | null
  field2: string | null
  fields: string[] | null
}

export interface IRiskWriterField {
  name: string
  helper_text: string
  default_value: string
  units: string
  input_type: RiskInputType
  required: boolean
  read_only: boolean | null
  title: string | null
  cols: number | null
  options: string | null
  calc: IRiskCalc | null
  show_if_name: string | null
  show_if_value: string | number | boolean | null
}
