import React, { useCallback, useEffect, useRef } from "react"
import { Container, Grid, Tab, Typography } from "@mui/material"

import { useApiRead } from "../../../../shared/hooks/useApiRead"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import PageHeader from "../../../../shared/components/pages/PageHeader"
import { type IServiceUser, SERVICE_USER_ENDPOINT } from "../../../../shared/models/service/IServiceUser"
import ViewProperty from "../../../../shared/components/ViewProperty"
import { SERVICE_USER_EDIT_URL } from "../../config/urls"
import PaperLocal from "../../../../shared/components/containers/PaperLocal"
import ViewPhone from "../../../../shared/components/display/ViewPhone"
import ViewEmail from "../../../../shared/components/display/ViewEmail"
import ViewRichText from "../../../../shared/components/display/ViewRichText"
import FormatDate from "../../../../shared/components/format/FormatDate"
import ItemViewerDrawer from "../../../../shared/components/item_viewer/ItemViewerDrawer"
import ContactInfo from "../contacts/components/ContactInfo"
import useApiAction, { type IUseApiActionProps } from "../../../../shared/hooks/useApiAction"
import { LoadingButton } from "@mui/lab"
import { ItemPrefixes } from "../../../../config/config"
import ViewListItems from "../../../../shared/components/display/ViewListItems"
import TabPanel, { useTabPanel } from "../../../../shared/components/tabs/TabPanel"
import ViewLoading from "../../../../shared/components/ViewLoading"
import ErrorMessage from "../../../../shared/components/ErrorMessage"
import { useTranslation } from "react-i18next"
import { useOnDemandPaged } from "../../../../shared/hooks/useApiPagedLocal"
import { type IFile, SERVICE_USER_FILE_ENDPOINT } from "../../../../shared/models/service/IFile"
import type { IMainModel } from "../../../../shared/models/service/IMainModel"
import FilesIndex from "../../../../shared/components/files/FilesIndex"
import { type IFilter } from "../../../../shared/models/components/IFilter"
import ServiceUserPermissions from "./components/ServiceUserPermissions"
import TabsList from "../../../../shared/components/tabs/TabsList"
import ViewMap from "../../../../shared/components/display/ViewMap"
import CheckMark from "../../../../shared/components/display/CheckMark"
import VendorInfo from "../vendors/components/VendorInfo"

const repository = new RestRepository<IServiceUser>(SERVICE_USER_ENDPOINT)
const filesRepository = new RestRepository<IFile | IMainModel>(SERVICE_USER_FILE_ENDPOINT)

/**
 * Renders a page to view locations impairment details.
 *
 * @returns {React.ReactElement} The rendered component.
 */
const ViewPage: React.FC = (): React.ReactElement => {
  const apiRead = useApiRead<IServiceUser>({ apiFunction: repository.read })
  const initialLoad = useRef<boolean>(false)
  const { data, error, loading, call } = apiRead
  const { tab, handleTabChange } = useTabPanel()
  const { t } = useTranslation()

  const filesPagingResults = useOnDemandPaged<IFile>(SERVICE_USER_FILE_ENDPOINT, [], "FILES")

  const actionProps: IUseApiActionProps<IServiceUser> = { repository, itemId: data?.id }
  const apiAction = useApiAction<IServiceUser>(actionProps)

  const handleToggleEnabled = useCallback(async () => {
    await apiAction.callAction("toggle_enabled_user")
    await apiRead.call()
  }, [apiAction.callAction])

  const handleDisable2FA = useCallback(async () => {
    await apiAction.callAction("disable_2fa")
    await apiRead.call()
  }, [apiAction.callAction])

  const handleSendOnboardEmail = useCallback(async () => {
    await apiAction.callAction("send_onboard_email")
    await apiRead.call()
  }, [apiAction.callAction])

  useEffect(() => {
    if (!initialLoad.current && data?.id !== undefined && tab === 1) {
      initialLoad.current = true
      const theFilter: IFilter[] = [
        {
          field: "service_user",
          value: data.id,
          title: "Files",
          display: data.name,
          canBeDelete: false,
        },
      ]
      filesPagingResults.handleFilter(theFilter)
    }
  }, [initialLoad, data?.id, tab])

  return (
    <Container fixed>
      <ErrorMessage error={error} />
      <ViewLoading loading={loading} />
      {data !== undefined && (
        <Grid container spacing={2}>
          <PageHeader
            title={t("Service User")}
            onRefresh={call}
            titlePlural={data.name}
            toEdit={`${SERVICE_USER_EDIT_URL}/${data.id}`}
          />

          <Grid item xs={12}>
            <ViewLoading loading={apiRead.loading || apiAction.saving} />
            {apiRead?.error !== undefined && <ErrorMessage error={apiRead.error} />}
            {apiAction?.connectionError !== undefined && <ErrorMessage error={apiAction.connectionError} />}

            <TabsList value={tab} onChange={handleTabChange}>
              <Tab label={t("Overview")} value={0} />
              <Tab label={t("Files")} value={1} />
              <Tab label={t("Permissions")} value={2} />
            </TabsList>
          </Grid>

          <Grid item xs={12}>
            <TabPanel value={tab} index={0}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <ItemViewerDrawer title={t("Contact")} prefix={ItemPrefixes.contact} infoView={ContactInfo} />
                  <ItemViewerDrawer title={t("Vendor")} prefix={ItemPrefixes.vendor} infoView={VendorInfo} />
                  <PaperLocal>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Job Title")}>{data.job_title}</ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Phone")}>
                          <ViewPhone ext={data.phone_ext}>{data.phone}</ViewPhone>
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Phone 2")}>
                          <ViewPhone ext={data.phone_2_ext}>{data.phone_2}</ViewPhone>
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label="Email">
                          <ViewEmail>{data.email}</ViewEmail>
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Email 2")}>
                          <ViewEmail>{data.email_2}</ViewEmail>
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Notes")} vertical>
                          <ViewRichText>{data.notes}</ViewRichText>
                        </ViewProperty>
                      </Grid>
                    </Grid>
                  </PaperLocal>
                  <PaperLocal sx={{ mt: 2 }}>
                    <Typography variant="h5" color="textSecondary">
                      {t("Vendors")}
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Lead")}>
                          <ViewListItems prefix={ItemPrefixes.vendor}>{data.vendor_leads}</ViewListItems>
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Member")}>
                          <ViewListItems prefix={ItemPrefixes.vendor}>{data.vendor_leads}</ViewListItems>
                        </ViewProperty>
                      </Grid>
                    </Grid>
                  </PaperLocal>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <PaperLocal>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Last Login")}>
                          <FormatDate value={data.last_login} />
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Linked Contact")}>
                          <ViewListItems prefix={ItemPrefixes.contact}>{data.contact}</ViewListItems>
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Enabled User")}>
                          <LoadingButton
                            onClick={handleToggleEnabled}
                            color={data.has_enabled_user_account ? "error" : "primary"}
                            loading={apiAction.saving}>
                            {data.has_enabled_user_account ? t("Disable User") : t("Enable User")}
                          </LoadingButton>
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Disable 2-Factor Authentication")}>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs>
                              {data.has_2fa_enabled && <CheckMark value={data.has_2fa_enabled} />}
                            </Grid>
                            <Grid item>
                              {data.has_2fa_enabled ? (
                                <LoadingButton onClick={handleDisable2FA} color="error" loading={apiAction.saving}>
                                  {t("Disable 2FA")}
                                </LoadingButton>
                              ) : (
                                t("2FA Not Enabled")
                              )}
                            </Grid>
                          </Grid>
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Onboard Email")}>
                          <LoadingButton onClick={handleSendOnboardEmail} loading={apiAction.saving}>
                            {t("Send Onboard Email")}
                          </LoadingButton>
                        </ViewProperty>
                      </Grid>
                    </Grid>
                  </PaperLocal>
                  <PaperLocal sx={{ mt: 2 }}>
                    <ViewMap place={data} />
                  </PaperLocal>
                  <PaperLocal sx={{ mt: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Address")}>{data.address}</ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("City")}>{data.city}</ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("State/Region")}>{data.state_region}</ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Postal Code")}>{data.postal_code}</ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Country")}>{data.country}</ViewProperty>
                      </Grid>
                    </Grid>
                  </PaperLocal>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <FilesIndex
                parentId={data.id}
                parentFieldName="service_user"
                repository={filesRepository}
                pagingResults={filesPagingResults}
              />
            </TabPanel>
            <TabPanel value={tab} index={2}>
              <ServiceUserPermissions serviceUser={data} onChange={apiRead.call} />
            </TabPanel>
          </Grid>
        </Grid>
      )}
    </Container>
  )
}

export default ViewPage
