import React, { useCallback, useContext, useEffect, useState } from "react"
import { Box, Button, Card, CardActions, CardMedia, Grid, useMediaQuery, useTheme } from "@mui/material"
import { type IFile, INSPECTION_FILE_ENDPOINT } from "../../../../../shared/models/service/IFile"
import { ImageChooserContext } from "../../../../../shared/components/files/ImageChooser"
import useEffectAsync from "../../../../../shared/hooks/useEffectAsync"
import FileAddEdit from "../../../../../shared/components/files/FileAddEdit"
import { useOnDemandPaged } from "../../../../../shared/hooks/useApiPagedLocal"
import type { IFilter } from "../../../../../shared/models/components/IFilter"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import type { IMainModel } from "../../../../../shared/models/service/IMainModel"
import {
  type IInspectionRecommendation,
  INSPECTION_RECOMMENDATION_ENDPOINT,
} from "../../../../../shared/models/service/IInspectionRecommendation"
import useApiEdit, { type IUseApiEditProps } from "../../../../../shared/hooks/useApiEdit"
import ErrorMessage from "../../../../../shared/components/ErrorMessage"
import type { IConnectionError } from "../../../../../shared/models/components/IConnectionError"
import FileViewerDialog from "../../../../../shared/components/files/FileViewerDialog"
import AlertDialog from "../../../../../shared/components/AlertDialog"
import DeleteIcon from "@mui/icons-material/Delete"
import ViewLoading from "../../../../../shared/components/ViewLoading"
import HtmlToolTip from "../../../../../shared/components/HtmlToolTip"
import AddIcon from "@mui/icons-material/Add"

interface IPatch extends IMainModel {
  id: number | string
  inspection_files: Array<number | string>
}

interface IProps {
  inspectionRecommendation: IInspectionRecommendation
  onChange?: () => Promise<void>
}

const fileRepository = new RestRepository<IFile | IMainModel>(INSPECTION_FILE_ENDPOINT)
const repository = new RestRepository<IPatch>(INSPECTION_RECOMMENDATION_ENDPOINT)

/**
 * Renders a grid of inspection recommendation images.
 *
 * @param {IProps} props - The properties for the component.
 * @returns {React.ReactElement} The rendered grid of images.
 */
const InspectionRecommendationImages: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { inspectionRecommendation, onChange} = props
  const { setDrawerOpen, fileSelected } = useContext(ImageChooserContext)
  const [width, setWidth] = useState<number>(450)
  const isSmall = useMediaQuery(useTheme().breakpoints.down("md"))
  const [error, setError] = useState<string | null>(null)

  const [theFile, setTheFile] = useState<IFile | null>(null)

  const editProps: IUseApiEditProps<IPatch> = { apiFunction: repository.patch, setError, redirect: false }
  const apiEdit = useApiEdit<IPatch>(editProps)

  const theFilter: IFilter[] = [
    {
      canBeDelete: false,
      title: "Inspection",
      display: "This Inspection",
      field: "inspection",
      value: inspectionRecommendation.inspection.id,
    },
  ]

  const pagingResults = useOnDemandPaged<IFile>(INSPECTION_FILE_ENDPOINT, theFilter, "FILES")

  const handleOpenDrawer = useCallback(() => {
    setDrawerOpen?.(true)
  }, [])

  const handleChange = useCallback(async () => {
    await onChange?.()
  }, [])

  const handleRemove = useCallback(
    (file: IFile) => async () => {
      const patch: IPatch = {
        id: inspectionRecommendation.id,
        name: inspectionRecommendation.name,
        inspection_files: inspectionRecommendation.inspection_files.filter(f => f.id !== file.id).map(f => f.id),
      }
      await apiEdit.handleEdit(patch)
      await onChange?.()
    },
    [inspectionRecommendation.id, inspectionRecommendation.inspection_files.length],
  )

  useEffect(() => {
    if (fileSelected !== undefined && fileSelected !== null) {
      setTheFile(fileSelected)
    }
  }, [fileSelected?.id])

  useEffectAsync(async () => {
    if (theFile !== undefined && theFile !== null) {
      const patch: IPatch = {
        id: inspectionRecommendation.id,
        name: inspectionRecommendation.name,
        inspection_files: [theFile.id, ...inspectionRecommendation.inspection_files.map(file => file.id)],
      }
      await apiEdit.handleEdit(patch)
      await onChange?.()
      setTheFile(null)
    }
  }, [theFile?.id, inspectionRecommendation.id, inspectionRecommendation.inspection_files.length])

  useEffect(() => {
    setWidth(isSmall ? window.outerWidth - 20 : 450)
  }, [isSmall])

  return (
    <>
      <FileAddEdit
        parentId={inspectionRecommendation.inspection.id}
        useDropzone
        onChange={pagingResults.call}
        repository={fileRepository}
        fieldName="inspection"
        openCloseWithUrl={true}
      />
      <ViewLoading loading={apiEdit.saving} />
      <Grid container spacing={2} alignItems="center">
        {error !== null && (
          <Grid item xs={12}>
            <ErrorMessage error={{ data: { Error: [error] } } satisfies IConnectionError} />
          </Grid>
        )}
        {apiEdit.connectionError !== null && (
          <Grid item xs={12}>
            <ErrorMessage error={apiEdit.connectionError} />
          </Grid>
        )}
        <Grid item xs={12} sx={{ textAlign: "right" }}>
          <Button startIcon={<AddIcon />} onClick={handleOpenDrawer}>
            Add Image
          </Button>
        </Grid>
        {inspectionRecommendation.inspection_files.map((file: IFile) => {
          return (
            <Grid key={file.id} item xs={12} lg={3}>
              <Card sx={{ maxWidth: (width - 10) / 2 }}>
                <HtmlToolTip
                  placement="top"
                  title={
                    <>
                      <Box>
                        <strong>{file.name}</strong>
                      </Box>
                      <Box>{file.caption}</Box>
                    </>
                  }>
                  <CardMedia
                    sx={{ minHeight: (width - 10) / 2, width: (width - 10) / 2 }}
                    image={file?.file_thumbnail?.read_url}
                    title={file?.caption}
                  />
                </HtmlToolTip>
                <CardActions>
                  <Grid container spacing={2}>
                    <Grid item xs>
                      <AlertDialog
                        buttonText="Remove image."
                        buttonIcon={<DeleteIcon />}
                        title="Confirm Remove Image"
                        message={
                          <>
                            Are you sure you want to remove:{" "}
                            <p>
                              <em>{file.name}</em>
                            </p>
                          </>
                        }
                        onYes={handleRemove(file)}
                      />
                    </Grid>
                    <Grid item>
                      <FileAddEdit
                        parentId={inspectionRecommendation.inspection.id}
                        useEdit
                        selectedFile={file}
                        onChange={handleChange}
                        repository={fileRepository}
                        fieldName={"inspection"}
                      />
                    </Grid>
                    <Grid item>
                      <FileViewerDialog selectedFile={file} files={inspectionRecommendation.inspection_files} />
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}

export default InspectionRecommendationImages
