import React from "react"
import { TableCell } from "@mui/material"
import FormatNumber from "../format/FormatNumber"

interface IProps {
  children: number
  twoDecimalPlaces?: boolean
  field?: string
  textAlign?: "left" | "center" | "right"
}

/**
 * Renders a TableCell with formatted currency value.
 *
 * @param {IProps} props - The component props.
 * @returns {React.ReactElement} - The rendered TableCell with formatted currency value.
 */
const TableCellNumber: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children, twoDecimalPlaces = false, textAlign = "center" } = props

  return (
    <TableCell sx={{ textAlign }}>
      <FormatNumber value={children} twoDecimalPlaces={twoDecimalPlaces} prefixUnits={false} />
    </TableCell>
  )
}

export default TableCellNumber
