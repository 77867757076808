import React from "react"
import { Grid, TableBody, TableHead, TableRow } from "@mui/material"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import { useApiPagedLocal } from "../../../../shared/hooks/useApiPagedLocal"
import { EMAIL_TEMPLATE_ADD_URL, EMAIL_TEMPLATE_VIEW_URL } from "../../config/urls"
import PageHeader from "../../../../shared/components/pages/PageHeader"
import TableData from "../../../../shared/components/tables/TableData"
import TableCellCenter from "../../../../shared/components/tables/TableCellCenter"
import ListData from "../../../../shared/components/lists/ListData"
import ListDataItem from "../../../../shared/components/lists/ListDataItem"
import TableCellData from "../../../../shared/components/tables/TableCellData"
import { type IListItem } from "../../../../shared/models/components/IListItem"
import TableCellTruncate from "../../../../shared/components/tables/TableCellTruncate"
import TableCellAction from "../../../../shared/components/tables/TableCellAction"
import ItemDelete from "../../../../shared/components/item_delete/ItemDelete"
import { type IMainModel } from "../../../../shared/models/service/IMainModel"
import { EMAIL_TEMPLATE_ENDPOINT, type IEmailTemplate } from "../../../../shared/models/service/IEmailTemplate"
import TableCellDate from "../../../../shared/components/tables/TableCellDate"
import { useTranslation } from "react-i18next"

const repository = new RestRepository<IEmailTemplate | IMainModel>(EMAIL_TEMPLATE_ENDPOINT)
const gotoRepo = new RestRepository<IListItem>(EMAIL_TEMPLATE_ENDPOINT)

/**
 * Renders the index page for the email templates.
 *
 * @returns {React.ReactElement} The rendered index page.
 */
const IndexPage: React.FC = (): React.ReactElement => {
  const pagingResults = useApiPagedLocal<IEmailTemplate>({
    apiFunction: (repository as RestRepository<IEmailTemplate>).findAll,
  })
  const { data } = pagingResults

  const { t } = useTranslation()

  return (
    <Grid container alignItems="center" spacing={2}>
      <PageHeader title={t("Email Template")} titlePlural={t("Email Templates")} toAdd={EMAIL_TEMPLATE_ADD_URL} />
      <TableData pagingResults={pagingResults} to={EMAIL_TEMPLATE_VIEW_URL} repository={gotoRepo}>
        <TableHead>
          <TableCellCenter field="name" pagingResults={pagingResults}>
            Name
          </TableCellCenter>
          <TableCellCenter field="subject">Subject</TableCellCenter>
          <TableCellCenter field="created">Created</TableCellCenter>
          <TableCellCenter field="updated">Updated</TableCellCenter>
          <TableCellAction field="action" />
        </TableHead>
        <TableBody>
          {data?.results.map((item: IEmailTemplate) => (
            <TableRow key={item.id}>
              <TableCellData field="name">{item.name}</TableCellData>
              <TableCellTruncate field="subject">{item.subject}</TableCellTruncate>
              <TableCellDate field="created">{item.created}</TableCellDate>
              <TableCellDate field="updated">{item.updated}</TableCellDate>
              <TableCellAction field="action">
                <ItemDelete repository={repository} item={item} onChange={pagingResults.call} />
              </TableCellAction>
            </TableRow>
          ))}
        </TableBody>
      </TableData>

      <ListData pagingResults={pagingResults}>
        {data?.results.map((item: IEmailTemplate) => (
          <ListDataItem key={item.id} to={`${EMAIL_TEMPLATE_VIEW_URL}/${item.id}`} title={item.name} />
        ))}
      </ListData>
    </Grid>
  )
}

export default IndexPage
