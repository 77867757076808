import React from "react"
import FormatNumber from "../format/FormatNumber"

interface IProps {
  children: string | boolean | number | null | undefined
  isFloat?: boolean
}

/**
 * Renders a formatted number with a percentage symbol.
 *
 * @param {IProps} props - The input props object.
 * @returns {React.ReactElement} - The formatted number with a percentage symbol.
 */
const ViewNumber: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children: value, isFloat = false } = props

  if (value === undefined || value === null || value === "") {
    return <>Not Set</>
  }

  return (
    <FormatNumber
      value={Number(value) ?? null}
      twoDecimalPlaces={isFloat}
      prefixUnits={false}
      suffixUnits={false}
    />
  )
}

export default ViewNumber
