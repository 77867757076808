export const LOGIN_URL = "/login/"
export const PASSWORD_RESET_URL = "/password_reset/"
export const DASHBOARD_URL = "/dashboard/"
export const PROFILE_URL = "/profile/"
export const PROFILE_EDIT_URL = "/profile/edit"
export const ABOUT_URL = "/about/"

export const ADDONS_INDEX_URL = "/addons/"

export const INSPECTION_RECOMMENDATION_INDEX_URL = "/inspection_recommendations/"
export const INSPECTION_RECOMMENDATION_VIEW_URL = "/inspection_recommendations/view"

export const LOCATION_IMPAIRMENT_INDEX_URL = "/impairments/"
export const LOCATION_IMPAIRMENT_VIEW_URL = "/impairments/view"
export const LOCATION_IMPAIRMENT_ADD_URL = "/impairments/add"
export const LOCATION_IMPAIRMENT_EDIT_URL = "/impairments/edit"