import React, { useCallback, useContext, useEffect, useState } from "react"
import { type UseFormReturn } from "react-hook-form/dist/types"
import { Box, Card, CardActionArea, CardActions, CardMedia, Grid, Typography } from "@mui/material"
import useLoadFormData from "../../../hooks/useLoadFormData"
import PaperLocal from "../../containers/PaperLocal"
import { type TRiskWriterFormData } from "../models/TRiskWriterFormData"
import type { IRiskWriterFormat } from "../models/IRiskWriterFormat"
import { nameToLabel } from "../../../utilities/form_utility"
import { RiskInputType } from "../models/IRiskWriterField"
import { useRiskWriterOptions } from "../hooks/useRiskWriterOptions"
import FhMuiTextField from "../../forms/FhMuiTextField"
import FhMuiRichTextField from "../../forms/FhMuiRichTextField"
import FhMuiSelectField from "../../forms/FhMuiSelectField"
import AlertDialog from "../../AlertDialog"
import DeleteIcon from "@mui/icons-material/Delete"
import { type IFile } from "../../../models/service/IFile"
import useEffectAsync from "../../../hooks/useEffectAsync"
import { RestRepository } from "../../../repositories/RestRepository"
import { useAxiosRequest } from "../../../hooks/useAxiosRequest"
import type { IMainModel } from "../../../models/service/IMainModel"
import FhMuiHiddenField from "../../forms/FhMuiHiddenField"
import { ImageChooserContext } from "../../files/ImageChooser"
import { omit } from "lodash"
import FhMuiCheckboxField from "../../forms/FhMuiCheckboxField"
import FormatNumberAndError from "../../format/FormatNumberAndError"
import FhMuiDateField from "../../forms/FhMuiDateField"

interface IProps {
  format: IRiskWriterFormat
  formType: "brief" | "building"
  form: UseFormReturn
  fileEndpoint: string
  data?: TRiskWriterFormData | null
  isEdit?: boolean
}

/**
 * Use this forms to add or edit an inspection building.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} returns the forms.
 */
const RiskWriterForm: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { format, formType, fileEndpoint, data = null, form, isEdit = false } = props
  const filesRepository = new RestRepository<IFile | IMainModel>(fileEndpoint)

  const { getSelectOptions } = useRiskWriterOptions({ format })
  const [triggerRender, setTriggerRender] = useState<boolean>(false)

  const { fileSelected, setDrawerOpen } = useContext(ImageChooserContext)
  const reportWriterForm = formType === "brief" ? format.brief_form : format.building_form

  const axiosRequest = useAxiosRequest()
  const [images, setImages] = useState<Record<string, IFile>>({})
  const [currentFileField, setCurrentFileField] = useState<string | null>(null)

  useLoadFormData<TRiskWriterFormData>(
    (data1: TRiskWriterFormData) => {
      if (reportWriterForm !== null && data1 !== null) {
        reportWriterForm?.content.forEach(group => {
          group.fields.forEach(field => {
            if (data1[field.name] === undefined || data1[field.name] === null || data1[field.name] === "") {
              form.setValue(field.name, field.default_value)
            } else {
              form.setValue(field.name, data1[field.name])
            }
          })
        })
      }
    },
    data,
    isEdit,
    form.setValue,
  )

  const handleGetFile = useCallback(async (fileId: number) => {
    let file = null
    await axiosRequest.callRequest(async () => {
      file = await (filesRepository as RestRepository<IFile>).read(fileId)
    })
    return file
  }, [])

  /**
   * This is a beast, not sure if this can be refactored.
   */
  useEffectAsync(async () => {
    if (reportWriterForm !== null && data !== null) {
      for (const group of reportWriterForm.content) {
        for (const field of group.fields) {
          const value = data[field.name]
          if (field.input_type === RiskInputType.IMAGE && value !== undefined && value !== null) {
            const file = await handleGetFile(value as number)
            if (file !== null) {
              setImages(images1 => ({ ...images1, [field.name]: file }))
            }
          }
        }
      }
    }
  }, [reportWriterForm, data])

  const handleDeleteImage = useCallback(
    (fieldName: string) => () => {
      form.setValue(fieldName, null)
      setImages(images1 => omit(images1, fieldName))
      setCurrentFileField(null)
    },
    [],
  )

  const handleFileRequest = useCallback(
    (fieldName: string) => () => {
      setCurrentFileField(fieldName)
      setDrawerOpen?.(true)
    },
    [setDrawerOpen],
  )

  const handleFileSelected = useCallback(
    (file: IFile) => {
      if (currentFileField !== null) {
        setImages(images1 => ({ ...images1, [currentFileField]: file }))
        form.setValue(currentFileField, file.id)
        setCurrentFileField(null)
      }
    },
    [currentFileField],
  )

  const handleShouldRefresh = useCallback(() => {
    setTriggerRender(tr => !tr)
  }, [form])

  useEffect(() => {
    if (fileSelected !== undefined && fileSelected !== null) {
      handleFileSelected(fileSelected)
    }
  }, [fileSelected, handleFileSelected])

  return (
    <>
      {reportWriterForm?.content.map(group => {
        return (
          <Box key={group.name}>
            <Box sx={{ color: "primary.main", mb: 1, mt: 3 }}>
              <Typography variant="h5">{nameToLabel(group.name, group.title)}</Typography>
            </Box>
            <PaperLocal sx={{ mb: 2 }}>
              <Grid container spacing={2}>
                {group.fields.map(field => {
                  // eslint-disable-next-line eqeqeq
                  if (field.show_if_name !== null && form.getValues(field.show_if_name) != field.show_if_value) {
                    return <React.Fragment key={field.name}></React.Fragment>
                  }
                  const disabled = field.calc !== null || field.read_only === true

                  return (
                    <Grid key={field.name} item xs={12} lg={field.cols ?? 4}>
                      <>
                        {triggerRender}
                        {field.input_type === RiskInputType.SELECT && field.options !== null && (
                          <FhMuiSelectField
                            control={form.control}
                            items={getSelectOptions(field.options)}
                            name={field.name}
                            label={field.title}
                            allowBlank
                            helperText={field.helper_text}
                          />
                        )}
                        {field.input_type === RiskInputType.CHECKBOX && (
                          <FhMuiCheckboxField
                            control={form.control}
                            name={field.name}
                            label={field.title}
                            onChange={handleShouldRefresh}
                            helperText={field.helper_text}
                          />
                        )}
                        {field.input_type === RiskInputType.LABEL && (
                          <Box>
                            <Typography variant="h6">{nameToLabel(field.name, field.title)}</Typography>
                          </Box>
                        )}
                        {field.input_type === RiskInputType.DATE && (
                          <FhMuiDateField
                            control={form.control}
                            name={field.name}
                            label={field.title}
                            disabled={disabled}
                            helperText={field.helper_text}
                          />
                        )}
                        {field.input_type === RiskInputType.CURRENCY && (
                          <FhMuiTextField
                            control={form.control}
                            name={field.name}
                            label={field.title}
                            showLabelRequired={field.required}
                            disabled={disabled}
                            formatter={FormatNumberAndError}
                            helperText={field.helper_text}
                          />
                        )}
                        {field.input_type === RiskInputType.PERCENT && (
                          <FhMuiTextField
                            control={form.control}
                            name={field.name}
                            label={field.title}
                            showLabelRequired={field.required}
                            disabled={disabled}
                            formatter={FormatNumberAndError}
                            suffixUnits="%"
                            prefixUnits=""
                            helperText={field.helper_text}
                          />
                        )}
                        {field.input_type === RiskInputType.RICH_TEXT && (
                          <FhMuiRichTextField
                            control={form.control}
                            name={field.name}
                            label={field.title}
                            helperText={field.helper_text}
                          />
                        )}
                        {field.input_type === RiskInputType.TEXT && (
                          <FhMuiTextField
                            control={form.control}
                            name={field.name}
                            label={field.title}
                            showLabelRequired={field.required}
                            disabled={disabled}
                            helperText={field.helper_text}
                            prefixUnits={""}
                            suffixUnits={field.units !== null ? ` ${field.units}` : ""}
                            formatter={field.units !== null ? FormatNumberAndError : undefined}
                          />
                        )}
                        {(field.input_type === RiskInputType.FLOAT || field.input_type === RiskInputType.AREA) && (
                          <FhMuiTextField
                            control={form.control}
                            name={field.name}
                            label={field.title}
                            showLabelRequired={field.required}
                            disabled={disabled}
                            helperText={field.helper_text}
                            prefixUnits={""}
                            suffixUnits={field.units !== null ? ` ${field.units}` : ""}
                            formatter={field.units !== null ? FormatNumberAndError : undefined}
                          />
                        )}
                        {field.input_type === RiskInputType.INTEGER && (
                          <FhMuiTextField
                            control={form.control}
                            name={field.name}
                            label={field.title}
                            showLabelRequired={field.required}
                            disabled={disabled}
                            helperText={field.helper_text}
                            prefixUnits={""}
                            suffixUnits={field.units !== null ? ` ${field.units}` : ""}
                            formatter={field.units !== null ? FormatNumberAndError : undefined}
                          />
                        )}
                        {field.input_type === RiskInputType.YEAR && (
                          <FhMuiTextField
                            control={form.control}
                            name={field.name}
                            label={field.title}
                            showLabelRequired={field.required}
                            disabled={disabled}
                            helperText={field.helper_text}
                          />
                        )}
                        {field.input_type === RiskInputType.IMAGE && (
                          <>
                            <FhMuiHiddenField control={form.control} name={field.name} />
                            <Card sx={{ maxWidth: "100%" }}>
                              <CardActionArea onClick={handleFileRequest(field.name)}>
                                <CardMedia
                                  sx={theme => ({
                                    minHeight: 300,
                                    width: "100%",
                                    border: "1px dashed",
                                    borderColor: theme.palette.grey["500"],
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  })}
                                  image={images[field.name]?.file_thumbnail?.read_url}
                                  title={images[field.name]?.caption}>
                                  <Box
                                    sx={theme => ({
                                      width: "100%",
                                      backgroundColor: theme.palette.grey["500"],
                                      textAlign: "center",
                                      p: 1,
                                    })}>
                                    Click to change.
                                  </Box>
                                </CardMedia>
                              </CardActionArea>
                              {images[field.name] !== undefined && (
                                <CardActions>
                                  <Grid container spacing={2}>
                                    <Grid item xs>
                                      <AlertDialog
                                        buttonText="Remove"
                                        buttonIcon={<DeleteIcon />}
                                        onYes={handleDeleteImage(field.name)}
                                        message={`Are you sure you want to remove this image?`}
                                      />
                                    </Grid>
                                  </Grid>
                                </CardActions>
                              )}
                            </Card>
                          </>
                        )}
                        {field.input_type !== RiskInputType.SELECT && field.options !== null && (
                          <FhMuiTextField
                            control={form.control}
                            name={field.name}
                            label={field.title}
                            showLabelRequired={field.required}
                            helperText={field.helper_text}
                          />
                        )}
                      </>
                    </Grid>
                  )
                })}
              </Grid>
            </PaperLocal>
          </Box>
        )
      })}
    </>
  )
}

export default RiskWriterForm
