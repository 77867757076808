import React, { useMemo } from "react"
import { Grid, useTheme } from "@mui/material"
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, XAxis, YAxis, PieChart, Pie, Tooltip, Legend } from "recharts"
import { CHART_COLORS } from "../../../../../../config/config"
import { type IStats } from "../../../../../models/service/IStats"
import { type IRiskWriterTable } from "../../../models/IRiskWriterTable"
import { nameToLabel } from "../../../../../utilities/form_utility"
import {
  type ISectionDataTable,
  SectionDataTableDisplayAggregate,
  SectionDataTableDisplayType,
} from "../../../models/IRiskWriterSectionData"
import InfoValueCard from "../../../../display/InfoValueCard"

interface IProps {
  tableData: Array<Array<string | number | boolean | null>>
  tableFormat: IRiskWriterTable
  displayType: SectionDataTableDisplayType
  sectionDataTable: ISectionDataTable
  small?: boolean
}

/**
 * ChartEditor Component
 *
 * A React functional component that renders a chart and a section for table options.
 * ResponsiveContainer and BarChart from Recharts for rendering a bar chart, and Typography for
 * displaying text.
 *
 * @param {IProps} props - The properties passed to the component
 * @returns {React.ReactElement} The rendered ChartEditor component
 */
const ChartViewer: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { tableData, displayType, tableFormat, sectionDataTable, small=false } = props
  const theme = useTheme()

  const data: IStats[] = useMemo(() => {
    const { label_column: labelColumn, data_column: dataColumn } = sectionDataTable
    const labelColumnIndex = tableFormat.columns.findIndex(item => item.name === labelColumn)
    const dataColumnIndex = tableFormat.columns.findIndex(item => item.name === dataColumn)
    return tableData.map(td => {
      return { value: td?.[dataColumnIndex] as number, label: td?.[labelColumnIndex] as string } satisfies IStats
    })
  }, [tableData, tableFormat, sectionDataTable])

  const singleValue: number = useMemo(() => {
    const sum = data.reduce((sum, current) => Number(sum) + Number(current.value), 0)
    if (sectionDataTable.aggregate === SectionDataTableDisplayAggregate.Average) {
      return data.length > 0 ? sum / data.length : 0
    }
    return sum
  }, [data, sectionDataTable.aggregate])

  const dataColumnName: string = useMemo(() => {
    const { data_column: dataColumn } = sectionDataTable
    const column = tableFormat.columns.find(item => item.name === dataColumn)
    if (column !== undefined) {
      return nameToLabel(column.name, column.title)
    }
    return ""
  }, [tableFormat, sectionDataTable])


  const dataRefined = useMemo((): IStats[] => {
    return data.map(({ value, label }) => ({label, value: Number(value)}))
  },[data])

  // todo: add a line chart with multiple data columns
  // todo: add log scale to graph
  return (
    <Grid container spacing={2}>
      <Grid item xs />
      {displayType === SectionDataTableDisplayType.PieChart && (
        <Grid item xs={12} lg={6}>
          <ResponsiveContainer width="100%" aspect={1}>
            <PieChart>
              <Pie data={dataRefined} dataKey="value" nameKey="label" cx="50%" cy="50%">
                {dataRefined.map((_entry, index) => (
                  <Cell key={`cell-${index}`} fill={CHART_COLORS[index % CHART_COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </Grid>
      )}

      {displayType === SectionDataTableDisplayType.BarChart && (
        <Grid item xs={12} lg={6}>
          <ResponsiveContainer width="100%" aspect={1}>
            <BarChart data={data} margin={{ bottom: 75 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="category" dataKey="label" angle={-40} textAnchor="end" />
              <YAxis type="number" dataKey="value" />
              <Bar dataKey="value" barSize={40}>
                {data.map((_entry, index) => (
                  <Cell key={`cell-${index}`} fill={CHART_COLORS[index % CHART_COLORS.length]} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Grid>
      )}

      {displayType === SectionDataTableDisplayType.SingleValue && (
        <Grid item xs={12} lg={small ? 12 : 4}>
          <InfoValueCard
            title={
              <>
                {sectionDataTable?.aggregate} {dataColumnName}
              </>
            }
            value={singleValue}
            color={sectionDataTable?.color ?? theme.palette.info.main}
            icon={sectionDataTable?.icon ?? ""}
          />
        </Grid>
      )}
      <Grid item xs />
    </Grid>
  )
}

export default ChartViewer
