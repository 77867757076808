import React from "react"
import { Grid } from "@mui/material"

import { useApiRead } from "../../../../../shared/hooks/useApiRead"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import { ACCOUNT_ENDPOINT, type IAccount } from "../../../../../shared/models/service/IAccount"
import ViewProperty from "../../../../../shared/components/ViewProperty"
import FormatNumber from "../../../../../shared/components/format/FormatNumber"
import FormatDate from "../../../../../shared/components/format/FormatDate"
import ViewListItems from "../../../../../shared/components/display/ViewListItems"
import ViewRichText from "../../../../../shared/components/display/ViewRichText"
import { type IInfoViewProps } from "../../../../../shared/components/item_viewer/ItemViewerDrawer"
import ItemViewerTitle from "../../../../../shared/components/item_viewer/ItemViewerTitle"
import { ACCOUNT_VIEW_URL } from "../../../config/urls"

const repository = new RestRepository<IAccount>(ACCOUNT_ENDPOINT)

/**
 * Renders a page to view locations impairment details.
 *
 * @param {IInfoViewProps} props See props for details
 * @returns {React.ReactElement} The rendered component.
 */
const AccountInfo: React.FC<IInfoViewProps> = (props: IInfoViewProps): React.ReactElement => {
  const { infoId } = props
  const { data } = useApiRead<IAccount>({ apiFunction: repository.read, itemId: infoId })

  return (
    <>
      {data !== undefined && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ItemViewerTitle to={`${ACCOUNT_VIEW_URL}/${data.id}`}>{data.name}</ItemViewerTitle>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label="Locations">
              <FormatNumber value={data.location_count} prefixUnits={false} />
            </ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label="Created">
              <FormatDate value={data.created} />
            </ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label="Account Managers">
              <ViewListItems>{data.account_managers}</ViewListItems>
            </ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label="Primary Contact">
              <ViewListItems>{data.contact}</ViewListItems>
            </ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label="Additional Contacts">
              <ViewListItems>{data.contacts}</ViewListItems>
            </ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label="Notes" vertical>
              <ViewRichText>{data.notes}</ViewRichText>
            </ViewProperty>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default AccountInfo
