import React from "react"
import { Container, Grid } from "@mui/material"
import { useForm } from "react-hook-form"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import EmailTemplateForm from "./components/EmailTemplateForm"
import ErrorMessage from "../../../../shared/components/ErrorMessage"
import { EMAIL_TEMPLATE_VIEW_URL } from "../../config/urls"
import useApiAdd, { type IUseApiAddProps } from "../../../../shared/hooks/useApiAdd"
import FormHeader from "../../../../shared/components/forms/FormHeader"
import FormBox from "../../../../shared/components/forms/FormBox"
import { EMAIL_TEMPLATE_ENDPOINT, type IEmailTemplate } from "../../../../shared/models/service/IEmailTemplate"

const repository = new RestRepository<IEmailTemplate>(EMAIL_TEMPLATE_ENDPOINT)
const redirectView = EMAIL_TEMPLATE_VIEW_URL

/**
 * This page will allow adding of an email template.
 *
 * @returns {React.FC} the email template add page.
 */
const AddPage: React.FC = (): React.ReactElement => {
  const form = useForm()

  const props: IUseApiAddProps<IEmailTemplate> = { apiFunction: repository.add, setError: form.setError, redirectView }
  const { saving, handleAdd, connectionError } = useApiAdd<IEmailTemplate>(props)

  return (
    <Container fixed>
      <Grid container spacing={2}>
        <FormHeader title="Add Email Template" loading={saving} errors={form.formState.errors} />
        <Grid item xs={12}>
          <ErrorMessage error={connectionError} />
        </Grid>
        <FormBox form={form} onSubmit={handleAdd}>
          <EmailTemplateForm form={form} />
        </FormBox>
      </Grid>
    </Container>
  )
}

export default AddPage
