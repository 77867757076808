import {
  Alert,
  alpha,
  Box,
  CircularProgress,
  Grid,
  Link,
  Paper,
  type Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import React, { useCallback, useContext, useState } from "react"
import useWindowResize from "beautiful-react-hooks/useWindowResize"
import { type IConnectionError } from "../../models/components/IConnectionError"
import useAuth from "../../hooks/useAuth"
import { APP_VERSION, COMPANY_NAME } from "../../../config/config"
import Maintenance, { type IMaintenance } from "../../components/Maintenance"
import ErrorMessage from "../../components/ErrorMessage"
import { PanariskAppContext } from "../../../app/PanariskApp"
import useEffectInit from "../../hooks/useEffectInit"
import MsAuthButton from "../../components/auth_providers/MsAuthButton"
import GoogleAuthButton from "../../components/auth_providers/GoogleAuthButton"
import UsernamePasswordForm from "../../components/auth_providers/UsernamePasswordForm"
import useLocalStorageHelpers, { StoragePrefixes } from "../../hooks/useLocalStorageHelpers"
import { DASHBOARD_URL, LOGIN_URL } from "../../../apps/admin/config/urls"
import { useLocation, useNavigate } from "react-router-dom"
import ViewImage from "../../components/display/ViewImage"
import axios from "axios"

const REACT_APP_DEV = process.env.REACT_APP_DEV === "true"
const maintenanceUrl = process.env.REACT_APP_MAINTENANCE_URL ?? ""

interface ILocationState {
  refer?: string
}

/**
 * This component allows the user to log in to the site.
 *
 * @returns {React.FC} the login page.
 */
const LoginPage: React.FC = (): React.ReactElement => {
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState<IConnectionError | null>(null)
  const [loading, setLoading] = useState(false)
  const [showPasswordForm, setShowPasswordForm] = useState(false)

  const { isLoggedIn } = useAuth()
  const location = useLocation()
  const navigate = useNavigate()
  const { appSettings, siteName } = useContext(PanariskAppContext)

  const { clearByPrefix } = useLocalStorageHelpers()

  const isSmall = useMediaQuery(useTheme().breakpoints.down("lg"))

  const [windowHeight, setWindowHeight] = useState(window !== undefined ? window.innerHeight : 600)

  const handleShowPasswordForm = useCallback(() => {
    setShowPasswordForm(show => !show)
  }, [])

  useWindowResize()(() => {
    setWindowHeight(window.innerHeight)
  })

  useEffectInit(async () => {
    const maintenance: IMaintenance = await axios.get(`${maintenanceUrl}?v=${new Date().getTime()}`)
    // Adding maintenance fixes the redirect looping.
    if (isLoggedIn === true && maintenance.loginEnabled && !maintenance.maintenanceEnabled) {
      const refer = (location.state as ILocationState)?.refer
      if (refer?.endsWith(LOGIN_URL) === true) {
        navigate(DASHBOARD_URL)
      } else {
        navigate(refer ?? DASHBOARD_URL)
      }
    }
    clearByPrefix(StoragePrefixes.paging)
  }, [])

  return (
    <Box sx={isSmall ? {} : { height: windowHeight }}>
      <Box sx={{ position: "absolute", p: 2, top: 0 }}>
        <ViewImage src={appSettings.serverInfo?.logo_large.read_url} />
      </Box>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs md={8}>
          <Grid
            container
            sx={(theme: Theme) =>
              isSmall
                ? { mt: 20 }
                : {
                    width: "100hw",
                    height: "100vh",
                    backgroundColor: alpha(theme.palette.text.secondary, 0.05),
                  }
            }
            alignItems="center"
            justifyContent="center"
            direction="column">
            <Grid item xs={isSmall ? 12 : undefined}>
              <Typography
                variant={isSmall ? "h2" : "h1"}
                component="h1"
                sx={{ color: "primary.main", fontWeight: 500 }}>
                {appSettings?.serverInfo?.company_name}
              </Typography>
              <Typography variant="h5" component="h3" sx={{ color: "secondary.main", ml: 0.75 }}>
                {siteName}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs md={4}>
          <Paper elevation={0} variant="elevation" sx={{ p: 2, mr: 4, ml: 4 }}>
            <Typography variant="h5" component="h3" gutterBottom sx={{ color: "primary.main", fontWeight: 600 }}>
              Sign in to {siteName}
              {REACT_APP_DEV && (
                <sup>
                  <small>DEV</small>
                </sup>
              )}
            </Typography>
            <Maintenance asButton={false} codeVersion={APP_VERSION}>
              <Box sx={{ mt: 4 }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Alert severity="info">Don&apos;t have an account? Contact your administrator.</Alert>
                  </Grid>
                </Grid>
                {loading && (
                  <Grid container spacing={3} sx={{ minHeight: 320, pt: 2, pb: 10 }}>
                    <Grid item xs={12}>
                      <Grid container justifyContent="center" spacing={3}>
                        <Grid item>
                          <CircularProgress size="1rem" />
                        </Grid>
                        <Grid item>Logging in...</Grid>
                        <Grid item xs={12} />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Box sx={{ minHeight: 320, pt: 2, pb: 10, display: loading ? "none" : "block" }}>
                  {error && (
                    <Box sx={{ mb: 2 }}>
                      <ErrorMessage error={errorMessage} />
                    </Box>
                  )}
                  <Grid container justifyContent="center" spacing={2}>
                    {!showPasswordForm && (
                      <>
                        <Grid item xs={12}>
                          <Grid container justifyContent="center">
                            <Grid item>
                              <GoogleAuthButton
                                setLoading={setLoading}
                                setError={setError}
                                setErrorMessage={setErrorMessage}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: "center" }}>
                          <MsAuthButton setLoading={setLoading} setError={setError} setErrorMessage={setErrorMessage} />
                        </Grid>
                        {appSettings?.serverInfo?.enable_username_password_login === true && (
                          <Grid item xs={12} sx={{ textAlign: "center" }}>
                            <small>
                              <Link onClick={handleShowPasswordForm} sx={{ cursor: "pointer" }}>
                                Login with Email and Password
                              </Link>
                            </small>
                            <Box sx={{ mt: 2 }}>
                              <small>
                                <Link href="/">Back to Sites</Link>
                              </small>
                            </Box>
                          </Grid>
                        )}
                      </>
                    )}

                    {appSettings?.serverInfo?.enable_username_password_login === true && (
                      <Grid item xs={12} sx={{ textAlign: "center" }}>
                        {showPasswordForm && (
                          <>
                            <UsernamePasswordForm
                              setLoading={setLoading}
                              setError={setError}
                              setErrorMessage={setErrorMessage}
                            />
                            <Box sx={{ mt: 2 }}>
                              <small>
                                <Link onClick={handleShowPasswordForm} sx={{ cursor: "pointer" }}>
                                  Login with SSO
                                </Link>
                              </small>
                            </Box>
                            <Box sx={{ mt: 2 }}>
                              <small>
                                <Link href="/">Back to Sites</Link>
                              </small>
                            </Box>
                          </>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Box>
            </Maintenance>
          </Paper>
        </Grid>
      </Grid>
      <Box sx={{ position: "absolute", bottom: 0, p: 1 }}>
        <Typography>
          © {COMPANY_NAME} {new Date().getFullYear()}
          <Box component="small" sx={{ ml: 1 }}>
            v{APP_VERSION}
          </Box>
        </Typography>
      </Box>
    </Box>
  )
}

export default LoginPage
