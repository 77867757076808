/* eslint-disable */
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import type { IPanariskWidget, IPanariskWidgetProps } from "../models/IPanariskWidgetProps"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material"
import PaperLocal from "../../containers/PaperLocal"
import WidgetMenu from "../components/WidgetMenu"
import useProfileStorage from "../../../hooks/useProfileStorage"
import DialogControls from "../../DialogControls"
import { RestRepository } from "../../../repositories/RestRepository"
import { type IListItem } from "../../../models/components/IListItem"
import { ISummary, SUMMARY_ENDPOINT } from "../../../models/service/ISummary"
import SelectFilteredSingle from "../../forms/SelectFilteredSingle"
import RiskWriterProvider from "../../risk_writer/context/RiskWriterProvider"
import type { IPanariskAppContext } from "../../../models/app/IPanariskAppContext"
import { PanariskAppContext } from "../../../../app/PanariskApp"
import useEffectAsync from "../../../hooks/useEffectAsync"
import { useAxiosRequest } from "../../../hooks/useAxiosRequest"
import RiskWriterContentViewer from "../../risk_writer/components/RiskWriterContentViewer"
import { IRiskWriterSectionData } from "../../risk_writer/models/IRiskWriterSectionData"
import { IFile, SUMMARY_FILE_ENDPOINT } from "../../../models/service/IFile"
import type { IMainModel } from "../../../models/service/IMainModel"

export interface ISummaryContentState {
  title: string
  description: string
  size: number
  summary?: IListItem | null
  contentName?: string
}

const DEFAULT_STATE: ISummaryContentState = {
  title: "Summary Content",
  description: "An interesting chart.",
  size: 6,
}

const SUMMARY_RISK_WRITER_CONTENT_KEY = "SUMMARY_RISK_WRITER_CONTENT_KEY"

const repository = new RestRepository<IListItem>(SUMMARY_ENDPOINT)
const filesRepository = new RestRepository<IFile | IMainModel>(SUMMARY_FILE_ENDPOINT)

/**
 * Generates a storage key based on the given ID.
 *
 * @param {string} id - The ID used to generate the storage key.
 * @returns {string} The generated storage key.
 */
const storageKey = (id: string): string => {
  return `${SUMMARY_RISK_WRITER_CONTENT_KEY}-${id}`
}

/**
 * Represents a sample widget component.
 *
 * @returns {React.ReactElement} - The rendered widget component.
 */
const SummaryRiskWriterContentWidget: IPanariskWidget = (props: IPanariskWidgetProps): React.ReactElement => {
  const { id, onMoveUp, onMoveDown, onDelete } = props
  const { appSettings } = useContext<IPanariskAppContext>(PanariskAppContext)
  const units = appSettings?.serverInfo?.units

  const [content, setContent] = useState<IRiskWriterSectionData | undefined>()
  const [contentIndex, setContentIndex] = useState<number>(0)

  const profileStorage = useProfileStorage<ISummaryContentState>(storageKey(id), DEFAULT_STATE)
  const [summaryContentState, setSummaryContentState] = profileStorage.data
  const axiosRequest = useAxiosRequest()

  const [summary, setSummary] = useState<ISummary | null>(null)

  const [openDialog, setOpenDialog] = useState<boolean>(false)

  const reportWriterFormat = useMemo(() => {
    return summary?.report_writer_format?.data?.summary_formats?.find(
      format => format.name === summary?.report_format_summary_name,
    )
  }, [summary])

  const handleLoadData = useCallback(async () => {
    if (summaryContentState?.summary !== undefined && summaryContentState.summary !== null) {
      const summaryId = summaryContentState.summary.id
      await axiosRequest.callRequest(async () => {
        const response = await repository?.read(summaryId)
        setSummary(response as ISummary)
      })
    }
  }, [summaryContentState])

  const handleChangeState = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (summaryContentState !== null) {
        await setSummaryContentState({
          ...summaryContentState,
          [event.target.name]: event.target.value,
        })
      }
    },
    [summaryContentState],
  )

  const handleSummary = useCallback(
    async (item: IListItem | null) => {
      if (item !== null) {
        const summary1 = (await repository.read(item.id)) as ISummary
        setSummary(summary1)
        await setSummaryContentState({ ...(summaryContentState ?? DEFAULT_STATE), summary: item ?? null })
      }
    },
    [summaryContentState],
  )

  const handleCloseDialog = useCallback(async () => {
    if (summaryContentState !== null && content?.name !== undefined) {
      await setSummaryContentState({ ...summaryContentState, contentName: content.name })
    }
    setOpenDialog(false)
    setContent(undefined)
  }, [summaryContentState, content])

  const handleOpenDialog = useCallback(async () => {
    setContent(summary?.risk_writer_data?.sections?.[contentIndex])
    setOpenDialog(true)
  }, [summary, contentIndex])

  const numSections = useMemo(() => {
    return summary?.risk_writer_data?.sections?.length
  }, [summary?.risk_writer_data?.sections?.length])

  const handlePrev = useCallback(() => {
    if (numSections !== undefined && contentIndex === 0) {
      setContentIndex(numSections - 1)
    } else {
      setContentIndex(index => index - 1)
    }
  }, [contentIndex, numSections])

  const handleNext = useCallback(() => {
    if (numSections !== undefined && numSections - 1 === contentIndex) {
      setContentIndex(0)
    } else {
      setContentIndex(index => index + 1)
    }
  }, [contentIndex, numSections])

  const handleGetFile = useCallback(async (fileId: number) => {
    let file = null
    await axiosRequest.callRequest(async () => {
      file = await (filesRepository as RestRepository<IFile>).read(fileId)
    })
    return file
  }, [])

  useEffect(() => {
    setContent(summary?.risk_writer_data?.sections?.[contentIndex])
  }, [contentIndex])

  useEffectAsync(async () => {
    await handleLoadData()
  }, [summaryContentState?.summary])

  return (
    <Grid item xs={12} lg={summaryContentState?.size}>
      <PaperLocal>
        <Grid container spacing={0} alignItems="center">
          <Grid item xs>
            <h4>{summaryContentState?.title}</h4>
          </Grid>
          <Grid item>
            <WidgetMenu>
              <MenuItem onClick={onMoveUp}>Move Up</MenuItem>
              <MenuItem onClick={onMoveDown}>Move Down</MenuItem>
              <Divider />
              <MenuItem onClick={handleOpenDialog}>Options...</MenuItem>
              <Divider />
              <MenuItem onClick={onDelete}>Delete Widget</MenuItem>
            </WidgetMenu>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">{summaryContentState?.description}</Typography>
          </Grid>
          {summary !== null && summaryContentState?.contentName !== undefined && (
            <Grid item xs={12}>
              <RiskWriterProvider
                units={units}
                format={reportWriterFormat}
                data={summary.risk_writer_data}
                getFile={handleGetFile}>
                <RiskWriterContentViewer contentName={summaryContentState.contentName} />
              </RiskWriterProvider>
            </Grid>
          )}
        </Grid>
      </PaperLocal>
      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="md">
        <DialogTitle>Top 5 List Options</DialogTitle>
        {summaryContentState !== null && (
          <DialogContent>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} lg={5}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Title"
                      name="title"
                      value={summaryContentState.title}
                      onChange={handleChangeState}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={7}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Description"
                      name="description"
                      value={summaryContentState.description}
                      onChange={handleChangeState}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={5}>
                <SelectFilteredSingle
                  name="summary"
                  defaultValue={summaryContentState.summary}
                  repository={repository}
                  onChange={handleSummary}
                />
              </Grid>
              <Grid item xs={12} lg={5}>
                <TextField
                  fullWidth
                  select
                  label="Width"
                  name="size"
                  value={summaryContentState?.size ?? ""}
                  onChange={handleChangeState}>
                  <MenuItem value={3}>Small</MenuItem>
                  <MenuItem value={6}>Medium</MenuItem>
                  <MenuItem value={12}>Large</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item>
                    <Button onClick={handlePrev}>Prev</Button>
                  </Grid>
                  <Grid item>
                    <Button onClick={handleNext}>Next</Button>
                  </Grid>
                </Grid>
                {summary !== null && content !== undefined && (
                  <RiskWriterProvider
                    units={units}
                    format={reportWriterFormat}
                    data={summary.risk_writer_data}
                    getFile={handleGetFile}>
                    <RiskWriterContentViewer contentName={content.name} />
                  </RiskWriterProvider>
                )}
              </Grid>
            </Grid>
          </DialogContent>
        )}
        <DialogActions>
          <DialogControls onSave={handleCloseDialog} buttonLabel="Done" />
        </DialogActions>
      </Dialog>
    </Grid>
  )
}

SummaryRiskWriterContentWidget.nameId = "summary_risk_writer_content_widget"
SummaryRiskWriterContentWidget.title = "Summary Content Widget"
SummaryRiskWriterContentWidget.description = "Displays the content of the summary report."

export default SummaryRiskWriterContentWidget
