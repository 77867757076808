import { type IStorageFile } from "./IStorageFile"
import { type IFileTag } from "./IFileTag"
import { type IMainModel } from "./IMainModel"

export const ACCOUNT_FILE_ENDPOINT = "account_files"
export const SUMMARY_FILE_ENDPOINT = "summary_files"
export const POLICY_FILE_ENDPOINT = "policy_files"
export const LOCATION_FILE_ENDPOINT = "location_files"
export const INSPECTION_FILE_ENDPOINT = "inspection_files"
export const LOCATION_IMPAIRMENT_FILE_ENDPOINT = "location_impairment_files"
export const SERVICE_USER_FILE_ENDPOINT = "service_user_files"
export const PROFILE_SERVICE_USER_FILE_ENDPOINT = "profile_service_user_files"
export const EMAIL_TEMPLATE_FILE_ENDPOINT = "email_template_files"

export interface IFile extends IMainModel {
  id: number
  name: string
  ext: string
  size: number | null
  exists: boolean
  is_report: boolean
  caption?: string
  file?: IStorageFile
  file_thumbnail?: IStorageFile
  file_report?: IStorageFile
  file_preview?: IStorageFile
  file_tags?: IFileTag[] | number[]
  created?: string
  updated?: string
}

export const RASTER_IMAGE_EXTENSIONS = ["jpg", "jpeg", "png", "gif", "bmp", "webp", "heif", "heic", "raw"]

/**
 * Checks if a given file is an image based on its extension.
 *
 * @param {IFile} file - The file to check.
 * @returns {boolean} True if the file is an image, false otherwise.
 */
export const isImage = (file: IFile): boolean => {
  return RASTER_IMAGE_EXTENSIONS.includes(file.ext.toLowerCase())
}
