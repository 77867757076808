import { alpha, Box, Button, Grid, Paper, type Theme, Typography, useMediaQuery, useTheme } from "@mui/material"
import React, { useContext, useState } from "react"
import useWindowResize from "beautiful-react-hooks/useWindowResize"
import { APP_VERSION, COMPANY_NAME } from "../../../config/config"
import { PanariskAppContext } from "../../../app/PanariskApp"
import ViewImage from "../../components/display/ViewImage"

/**
 * Represents the main landing page for the application. Displays the company's logo, name,
 * and site name prominently, alongside a sign-in section.
 *
 * @returns {React.ReactElement} The rendered landing page component.
 */
const LandingPage: React.FC = (): React.ReactElement => {
  const { appSettings, siteName } = useContext(PanariskAppContext)

  const isSmall = useMediaQuery(useTheme().breakpoints.down("lg"))

  const [windowHeight, setWindowHeight] = useState(window !== undefined ? window.innerHeight : 600)

  useWindowResize()(() => {
    setWindowHeight(window.innerHeight)
  })

  return (
    <Box sx={isSmall ? {} : { height: windowHeight }}>
      <Box sx={{ position: "absolute", p: 2, top: 0 }}>
        <ViewImage src={appSettings.serverInfo?.logo_large.read_url} />
      </Box>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs md={8}>
          <Grid
            container
            sx={(theme: Theme) =>
              isSmall
                ? { mt: 20 }
                : {
                    width: "100hw",
                    height: "100vh",
                    backgroundColor: alpha(theme.palette.text.secondary, 0.05),
                  }
            }
            alignItems="center"
            justifyContent="center"
            direction="column">
            <Grid item xs={isSmall ? 12 : undefined}>
              <Typography
                variant={isSmall ? "h2" : "h1"}
                component="h1"
                sx={{ color: "primary.main", fontWeight: 500 }}>
                {appSettings?.serverInfo?.company_name}
              </Typography>
              <Typography variant="h5" component="h3" sx={{ color: "secondary.main", ml: 0.75 }}>
                {siteName}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs md={4}>
          <Paper elevation={0} variant="elevation" sx={{ p: 2, mr: 4, ml: 4 }}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Button variant="outlined" href="/admin/login" fullWidth sx={{ fontSize: "1.15rem" }}>
                  Administration
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button variant="outlined" href="/inspect/login" fullWidth sx={{ fontSize: "1.15rem" }}>
                  Inspection
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button variant="outlined" href="/recs/login" fullWidth sx={{ fontSize: "1.15rem" }}>
                  Recommendation
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Box sx={{ position: "absolute", bottom: 0, p: 1 }}>
        <Typography>
          © {COMPANY_NAME} {new Date().getFullYear()}
          <Box component="small" sx={{ ml: 1 }}>
            v{APP_VERSION}
          </Box>
        </Typography>
      </Box>
    </Box>
  )
}

export default LandingPage
