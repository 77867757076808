import React from "react"
import { Box, TableCell } from "@mui/material"
import TableOrdering from "./TableOrdering"
import { type IUseApiPagedResultsResponse } from "../../hooks/useApiPagedLocal"

interface IProps {
  children: string | boolean | React.JSX.Element | number | undefined | null
  field?: string
  pagingResults?: IUseApiPagedResultsResponse<any>
  wrap?: boolean
}

/**
 * Renders a TableCell aligned center.
 *
 * @param {IProps} props - The component props.
 * @returns {React.ReactElement} - The rendered TableCell aligned center.
 */
const TableCellCenter: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children, field, pagingResults, wrap = true } = props

  const content = typeof children === "string" ? <Box dangerouslySetInnerHTML={{ __html: children }} /> : children

  return (
    <TableCell sx={{ textAlign: "center", whiteSpace: wrap ? "wrap" : "nowrap" }}>
      {pagingResults !== undefined && field !== undefined ? (
        <TableOrdering
          ordering={pagingResults.paging?.ordering}
          field={field}
          title={content}
          onOrdering={pagingResults.handleOrdering}
        />
      ) : (
        content
      )}
    </TableCell>
  )
}

export default TableCellCenter
