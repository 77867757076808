import React, { useContext, useMemo } from "react"
import RiskWriterContentEditor from "./RiskWriterContentEditor"
import { RiskWriterContext } from "../context/RiskWriterProvider"

interface IProps {
  contentName: string
}

/**
 * RiskWriterContentViewer is a React functional component designed to render a specific content viewer
 * for a risk writer report. It utilizes context from RiskWriterContext and filters the appropriate
 * content and section data based on provided props.
 *
 * This component uses `useMemo` hooks to efficiently derive the required `content` and `section` data
 * based on the current report writer context and the provided content name. It renders the
 * RiskWriterContentEditor in viewer-only mode if the required data is available.
 *
 * @param {IProps} props - The props provided to the component.
 * @param {string} props.contentName - The name of the content to be displayed in the viewer.
 * @returns {React.ReactElement} A React element rendering the content viewer, or nothing if
 *                               the required content and section are not found.
 */
const RiskWriterContentViewer: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { reportWriterFormat, reportWriterData } = useContext(RiskWriterContext)
  const { contentName } = props

  const content = useMemo(() => {
    return reportWriterData?.sections?.filter(content => content.name === contentName)?.[0]
  }, [reportWriterData, contentName])

  const section = useMemo(() => {
    for (const section of reportWriterFormat?.sections ?? []) {
      if (section.sections !== null) {
        for (const subSection of section.sections) {
          if (subSection.name === content?.section_name) {
            return subSection
          }
        }
      }
      if (section.name === content?.section_name) {
        return section
      }
    }
  }, [reportWriterFormat, content])

  return (
    <>
      {section !== undefined && content !== undefined && (
        <RiskWriterContentEditor section={section} content={content} viewerOnly small />
      )}
    </>
  )
}

export default RiskWriterContentViewer
