import React, { useCallback, useState } from "react"
import { Box, Card, CardContent, CardMedia, Grid, Typography } from "@mui/material"
import ViewProperty from "../../ViewProperty"
import { type IRiskWriterFormat } from "../models/IRiskWriterFormat"
import { nameToLabel } from "../../../utilities/form_utility"
import { RiskInputType } from "../models/IRiskWriterField"
import ViewCurrency from "../../display/ViewCurrency"
import ViewPercent from "../../display/ViewPercent"
import ViewRichText from "../../display/ViewRichText"
import PaperLocal from "../../containers/PaperLocal"
import { useRiskWriterOptions } from "../hooks/useRiskWriterOptions"
import type { TRiskWriterFormData } from "../models/TRiskWriterFormData"
import ViewArea from "../../display/ViewArea"
import TruncateText from "../../TruncateText"
import { type IFile } from "../../../models/service/IFile"
import useEffectAsync from "../../../hooks/useEffectAsync"
import { RestRepository } from "../../../repositories/RestRepository"
import { useAxiosRequest } from "../../../hooks/useAxiosRequest"
import type { IMainModel } from "../../../models/service/IMainModel"
import CheckMark from "../../display/CheckMark"
import ViewNumber from "../../display/ViewNumber"
import FormatDate from "../../format/FormatDate"

interface IProps {
  format: IRiskWriterFormat | null | undefined
  units: string | undefined
  formType: "brief" | "building"
  children: TRiskWriterFormData | null
  fileEndpoint: string
  isInfoPanel?: boolean
}

/**
 * Renders the inspection data.data.
 *
 * @param {IProps} props - The properties for the component.
 * @param {React.ReactElement} props.children - The brief data to be displayed.
 * @returns {React.ReactElement} - The rendered inspection data.data.
 */
const RiskWriterFormView: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children: data, units, formType, format, fileEndpoint, isInfoPanel = false } = props

  const filesRepository = new RestRepository<IFile | IMainModel>(fileEndpoint)
  const { getOptionValue } = useRiskWriterOptions({ format })
  const [images, setImages] = useState<Record<string, IFile>>({})
  const axiosRequest = useAxiosRequest()

  if (format === undefined || format === null) {
    return <></>
  }

  const form = formType === "brief" ? format.brief_form : format.building_form

  const handleGetFile = useCallback(async (fileId: number) => {
    let file = null
    await axiosRequest.callRequest(async () => {
      file = await (filesRepository as RestRepository<IFile>).read(fileId)
    })
    return file
  }, [])

  /**
   * This is a beast, not sure if this can be refactored.
   */
  useEffectAsync(async () => {
    if (form !== null && data !== null) {
      const images1: Record<string, IFile> = {}
      for (const group of form.content) {
        for (const field of group.fields) {
          const value = data[field.name]
          if (field.input_type === RiskInputType.IMAGE && value !== undefined && value !== null) {
            const file = await handleGetFile(value as number)
            if (file !== null) {
              images1[field.name] = file
            }
          }
        }
      }
      setImages(images1)
    }
  }, [form, data])

  return (
    <>
      {form?.content.map(group => {
        return (
          <Box key={group.name}>
            <Box sx={{ color: "primary.main", mb: 1, mt: 3 }} id={`brief-${group.name}`}>
              <Typography variant="h5">{nameToLabel(group.name, group.title)}</Typography>
            </Box>
            <PaperLocal sx={{ mb: 2 }}>
              <Grid container spacing={2}>
                {group.fields.map(field => {
                  if (field.input_type === RiskInputType.SPACE) {
                    return <Grid key={field.name} item xs={12} lg={isInfoPanel ? 12 : field.cols ?? 4} />
                  }
                  // eslint-disable-next-line eqeqeq
                  if (field.show_if_name !== null && data?.[field.show_if_name] != field.show_if_value) {
                    return <React.Fragment key={field.name}></React.Fragment>
                  }
                  return (
                    <Grid key={field.name} item xs={12} lg={isInfoPanel ? 12 : field.cols ?? 4}>
                      <ViewProperty
                        label={nameToLabel(field.name, field.title)}
                        vertical={isInfoPanel ? field.input_type === RiskInputType.RICH_TEXT : true}>
                        <>
                          {field.input_type === RiskInputType.SELECT && field.options !== null && (
                            <ViewRichText>{getOptionValue(field.options, data?.[field.name]) as string}</ViewRichText>
                          )}
                          {field.input_type === RiskInputType.CURRENCY && (
                            <ViewCurrency>{data?.[field.name] as number}</ViewCurrency>
                          )}
                          {field.input_type === RiskInputType.CHECKBOX && <CheckMark value={data?.[field.name]} />}
                          {field.input_type === RiskInputType.PERCENT && (
                            <ViewPercent>{data?.[field.name] as number}</ViewPercent>
                          )}
                          {field.input_type === RiskInputType.RICH_TEXT && (
                            <ViewRichText>{data?.[field.name] as string}</ViewRichText>
                          )}
                          {(field.input_type === RiskInputType.TEXT || field.input_type === RiskInputType.LABEL) && (
                            <ViewRichText>{data?.[field.name] as string}</ViewRichText>
                          )}
                          {field.input_type === RiskInputType.FLOAT && (
                            <ViewNumber isFloat>{data?.[field.name]}</ViewNumber>
                          )}
                          {field.input_type === RiskInputType.INTEGER && <ViewNumber>{data?.[field.name]}</ViewNumber>}
                          {field.input_type === RiskInputType.YEAR && (
                            <ViewRichText>{data?.[field.name] as string}</ViewRichText>
                          )}
                          {field.input_type === RiskInputType.AREA && (
                            <ViewArea units={units}>{data?.[field.name] as number}</ViewArea>
                          )}
                          {field.input_type === RiskInputType.DATE && (
                            <FormatDate value={data?.[field.name] as string} />
                          )}
                          {field.input_type === RiskInputType.IMAGE && (
                            <Card sx={{ maxWidth: "100%" }}>
                              {images[field.name] !== undefined && (
                                <CardMedia
                                  sx={{ minHeight: 300, width: "100%" }}
                                  image={images[field.name].file_thumbnail?.read_url}
                                  title={images[field.name].caption}
                                />
                              )}
                              <CardContent>
                                <Grid container spacing={2} alignItems="center">
                                  <Grid item xs>
                                    <Typography variant="body2" color="text.secondary">
                                      <TruncateText>{images[field.name]?.caption ?? "Not Set"}</TruncateText>
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          )}
                        </>
                      </ViewProperty>
                    </Grid>
                  )
                })}
              </Grid>
            </PaperLocal>
          </Box>
        )
      })}
    </>
  )
}

export default RiskWriterFormView
