export const LOGIN_URL = "/login/"
export const PASSWORD_RESET_URL = "/password_reset/"
export const DASHBOARD_URL = "/dashboard/"
export const IMPORT_URL = "/bulk/"
export const PROFILE_URL = "/profile/"
export const PROFILE_EDIT_URL = "/profile/edit"
export const ABOUT_URL = "/about/"

export const ADDONS_INDEX_URL = "/addons/"

export const SETTING_VIEW_URL = "/settings/"
export const SETTING_EDIT_URL = "/settings/edit"

export const ACCOUNT_INDEX_URL = "/accounts/"
export const ACCOUNT_ADD_URL = "/accounts/add"
export const ACCOUNT_EDIT_URL = "/accounts/edit"
export const ACCOUNT_VIEW_URL = "/accounts/view"

export const POLICY_INDEX_URL = "/policies/"
export const POLICY_ADD_URL = "/policies/add"
export const POLICY_EDIT_URL = "/policies/edit"
export const POLICY_VIEW_URL = "/policies/view"

export const SUMMARY_INDEX_URL = "/summaries/"
export const SUMMARY_ADD_URL = "/summaries/add"
export const SUMMARY_EDIT_URL = "/summaries/edit"
export const SUMMARY_VIEW_URL = "/summaries/view"

export const BUILDING_ADD_URL = "/buildings/add"
export const BUILDING_EDIT_URL = "/buildings/edit"
export const BUILDING_VIEW_URL = "/buildings/view"

export const CONTACT_INDEX_URL = "/contacts/"
export const CONTACT_ADD_URL = "/contacts/add"
export const CONTACT_EDIT_URL = "/contacts/edit"
export const CONTACT_VIEW_URL = "/contacts/view"

export const FILTER_BOOKMARK_EDIT_URL = "/filter_bookmarks/edit"
export const FILTER_BOOKMARK_VIEW_URL = "/filter_bookmarks/view"

export const LOCATION_INDEX_URL = "/locations/"
export const LOCATION_VIEW_URL = "/locations/view"
export const LOCATION_ADD_URL = "/locations/add"
export const LOCATION_EDIT_URL = "/locations/edit"

export const MAP_URL = "/map"

export const INSPECTION_INDEX_URL = "/inspections/"
export const INSPECTION_ADD_URL = "/inspections/add"
export const INSPECTION_EDIT_URL = "/inspections/edit"
export const INSPECTION_VIEW_URL = "/inspections/view"

export const INSPECTION_BUILDING_EDIT_URL = "/inspection_buildings/edit"
export const INSPECTION_BUILDING_VIEW_URL = "/inspection_buildings/view"

export const LOCATION_IMPAIRMENT_INDEX_URL = "/impairments/"
export const LOCATION_IMPAIRMENT_VIEW_URL = "/impairments/view"
export const LOCATION_IMPAIRMENT_ADD_URL = "/impairments/add"
export const LOCATION_IMPAIRMENT_EDIT_URL = "/impairments/edit"

export const INSPECTION_RECOMMENDATION_INDEX_URL = "/inspection_recommendations/"
export const INSPECTION_RECOMMENDATION_VIEW_URL = "/inspection_recommendations/view"
export const INSPECTION_RECOMMENDATION_ADD_URL = "/inspection_recommendations/add"
export const INSPECTION_RECOMMENDATION_EDIT_URL = "/inspection_recommendations/edit"

export const FILE_TAG_INDEX_URL = "/file_tags/"
export const FILE_TAG_VIEW_URL = "/file_tags/view"
export const FILE_TAG_ADD_URL = "/file_tags/add"
export const FILE_TAG_EDIT_URL = "/file_tags/edit"

export const EMAIL_TEMPLATE_INDEX_URL = "/email_templates/"
export const EMAIL_TEMPLATE_VIEW_URL = "/email_templates/view"
export const EMAIL_TEMPLATE_ADD_URL = "/email_templates/add"
export const EMAIL_TEMPLATE_EDIT_URL = "/email_templates/edit"

export const OCCUPANCY_INDEX_URL = "/occupancies/"
export const OCCUPANCY_VIEW_URL = "/occupancies/view"
export const OCCUPANCY_ADD_URL = "/occupancies/add"
export const OCCUPANCY_EDIT_URL = "/occupancies/edit"

export const RECOMMENDATION_INDEX_URL = "/recommendations/"
export const RECOMMENDATION_VIEW_URL = "/recommendations/view"
export const RECOMMENDATION_ADD_URL = "/recommendations/add"
export const RECOMMENDATION_EDIT_URL = "/recommendations/edit"

export const REST_API_KEY_INDEX_URL = "/rest_api_keys/"
export const REST_API_KEY_VIEW_URL = "/rest_api_keys/view"
export const REST_API_KEY_ADD_URL = "/rest_api_keys/add"
export const REST_API_KEY_EDIT_URL = "/rest_api_keys/edit"

export const REPORT_FORMAT_INDEX_URL = "/report_formats/"
export const REPORT_FORMAT_VIEW_URL = "/report_formats/view"
export const REPORT_FORMAT_ADD_URL = "/report_formats/add"
export const REPORT_FORMAT_EDIT_URL = "/report_formats/edit"

export const SERVICE_USER_INDEX_URL = "/service_users/"
export const SERVICE_USER_VIEW_URL = "/service_users/view"
export const SERVICE_USER_ADD_URL = "/service_users/add"
export const SERVICE_USER_EDIT_URL = "/service_users/edit"

export const VENDOR_INDEX_URL = "/vendors/"
export const VENDOR_VIEW_URL = "/vendors/view"
export const VENDOR_ADD_URL = "/vendors/add"
export const VENDOR_EDIT_URL = "/vendors/edit"
