import React, { useContext } from "react"
import { type IInspectionBuilding, INSPECTION_BUILDING_ENDPOINT } from "../../../models/service/IInspectionBuilding"
import { Grid } from "@mui/material"
import { RestRepository } from "../../../repositories/RestRepository"
import { useApiRead } from "../../../hooks/useApiRead"
import { type IInfoViewProps } from "../../item_viewer/ItemViewerDrawer"
import ItemViewerTitle from "../../../components/item_viewer/ItemViewerTitle"
import { BUILDING_VIEW_URL } from "../../../../apps/admin/config/urls"
import ViewMap from "../../../components/display/ViewMap"
import ViewProperty from "../../../components/ViewProperty"
import FormatDate from "../../../components/format/FormatDate"
import { checkHasRiskWriter } from "../../../models/service/IReportFormat"
import type { IPanariskAppContext } from "../../../models/app/IPanariskAppContext"
import { PanariskAppContext } from "../../../../app/PanariskApp"
import RiskWriterFormView from "../../risk_writer/components/RiskWriterFormView"
import { INSPECTION_FILE_ENDPOINT } from "../../../models/service/IFile"

const repository = new RestRepository<IInspectionBuilding>(INSPECTION_BUILDING_ENDPOINT)

/**
 * Renders a view page for an inspection report building.
 *
 * @param {IInfoViewProps} props See props for details
 * @returns {React.ReactElement} The rendered view page.
 */
const InspectionBuildingInfo: React.FC<IInfoViewProps> = (props: IInfoViewProps): React.ReactElement => {
  const { infoId } = props
  const apiRead = useApiRead<IInspectionBuilding>({ apiFunction: repository.read, itemId: infoId })
  const { data } = apiRead

  const { appSettings } = useContext<IPanariskAppContext>(PanariskAppContext)
  const units = appSettings?.serverInfo?.units

  const format = data?.inspection.report_writer_format?.data
  const hasReportWriter = checkHasRiskWriter(data?.inspection)

  return (
    <>
      {data !== undefined && (
        <>
          <Grid container spacing={2}>
            <Grid item xs>
              <ItemViewerTitle to={hasReportWriter && `${BUILDING_VIEW_URL}/${data.building.id}`}>
                {data.building.name}
              </ItemViewerTitle>
            </Grid>
            <Grid item xs={12}>
              <ViewMap initHide place={data.building} showInfo={false} />
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label="Created">
                <FormatDate value={data.created} />
              </ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label="Updated">
                <FormatDate value={data.updated} />
              </ViewProperty>
            </Grid>
          </Grid>

          <RiskWriterFormView
            isInfoPanel
            units={units}
            format={format}
            formType="building"
            fileEndpoint={INSPECTION_FILE_ENDPOINT}>
            {data.data}
          </RiskWriterFormView>
        </>
      )}
    </>
  )
}

export default InspectionBuildingInfo
