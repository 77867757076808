const error = console.error
console.error = (...args: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  if (/defaultProps/.test(args[0])) return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  if (/UNSAFE_componentWillMount/.test(args[0])) return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  if (/mainState/.test(args[1])) return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  if (/aria-hidden/.test(args[0])) return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  if (/"key" prop/.test(args[0])) return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  error(...args)
}

const warn = console.warn
console.warn = (...args: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  if (/Too many active WebGL contexts. Oldest context will be lost./.test(args[0])) return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  if (/Cannot remove a WebglOverlay/.test(args[0])) return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  if (/mainState/.test(args[0])) return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  if (/isOptionEqualToValue/.test(args[0])) return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  if (/Autocomplete/.test(args[1])) return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  if (/basename/.test(args[0])) return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  if (/RFC2822 or ISO format/.test(args[0])) return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  if (/MUI: You have provided an out-of-range value/.test(args[0])) return
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  warn(...args)
}
