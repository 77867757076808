import React, { useCallback } from "react"
import { Container, Grid } from "@mui/material"
import { useForm } from "react-hook-form"
import { RestRepository } from "../../repositories/RestRepository"
import FormHeader from "../forms/FormHeader"
import { useApiRead } from "../../hooks/useApiRead"
import useApiEdit, { type IUseApiEditProps } from "../../hooks/useApiEdit"
import { INSPECTION_BUILDING_VIEW_URL } from "../../../apps/admin/config/urls"
import FormBox from "../forms/FormBox"
import FormErrors from "../forms/FormErrors"
import { useTranslation } from "react-i18next"
import { type IInspectionBuilding, INSPECTION_BUILDING_ENDPOINT } from "../../models/service/IInspectionBuilding"
import RiskWriterForm from "../risk_writer/components/RiskWriterForm"
import { type TRiskWriterFormData } from "../risk_writer/models/TRiskWriterFormData"
import { ImageChooserProvider } from "../files/ImageChooser"
import { INSPECTION_FILE_ENDPOINT } from "../../models/service/IFile"

const repository = new RestRepository<IInspectionBuilding>(INSPECTION_BUILDING_ENDPOINT)
const redirectView = INSPECTION_BUILDING_VIEW_URL

/**
 * This page will allow editing of an inspection.
 *
 * @returns {React.FC} the inspection edit page.
 */
const EditPage: React.FC = (): React.ReactElement => {
  const form = useForm()
  const { t } = useTranslation()
  const apiRead = useApiRead<IInspectionBuilding>({ apiFunction: repository.read })

  const props: IUseApiEditProps<IInspectionBuilding> = {
    apiFunction: repository.edit,
    setError: form.setError,
    redirectView,
  }
  const apiEdit = useApiEdit<IInspectionBuilding>(props)

  const reportWriterFormat = apiRead.data?.inspection.report_writer_format?.data

  const handleSubmit = useCallback(
    async (data: TRiskWriterFormData) => {
      if (apiRead.data !== undefined) {
        await apiEdit.handleEdit({ ...apiRead.data, data })
      }
    },
    [apiEdit, apiRead.data],
  )

  return (
    <Container fixed>
      <Grid container spacing={2}>
        <FormHeader
          title={t("Edit Inspection Building")}
          loading={apiEdit.saving || apiRead.loading}
          errors={form.formState.errors}
        />
        <FormErrors apiError={apiRead.error} connectionError={apiEdit.connectionError} />
        <FormBox form={form} onSubmit={handleSubmit}>
          {reportWriterFormat !== undefined && reportWriterFormat !== null && (
            <ImageChooserProvider
              itemId={apiRead.data?.inspection.id}
              field="inspection"
              fileEndpoint={INSPECTION_FILE_ENDPOINT}>
              <RiskWriterForm
                data={apiRead.data?.data}
                format={reportWriterFormat}
                formType="building"
                fileEndpoint={INSPECTION_FILE_ENDPOINT}
                form={form}
                isEdit
              />
            </ImageChooserProvider>
          )}
        </FormBox>
      </Grid>
    </Container>
  )
}

export default EditPage
