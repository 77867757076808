import React from "react"
import { type UseFormReturn } from "react-hook-form/dist/types"
import { Grid } from "@mui/material"
import FhMuiHiddenField from "../../../../../shared/components/forms/FhMuiHiddenField"
import FhMuiTextField from "../../../../../shared/components/forms/FhMuiTextField"
import { type IVendor } from "../../../../../shared/models/service/IVendor"
import { requiredRule } from "../../../../../shared/utilities/form_utility"
import useLoadFormData from "../../../../../shared/hooks/useLoadFormData"
import SelectFilteredSingle, { useSelectFilteredSingle } from "../../../../../shared/components/forms/SelectFilteredSingle"
import SelectFilteredMultiple, {
  useSelectFilteredMultiple,
} from "../../../../../shared/components/forms/SelectFilteredMultiple"
import FhMuiRichTextField from "../../../../../shared/components/forms/FhMuiRichTextField"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import { CONTACT_ENDPOINT, type IContact } from "../../../../../shared/models/service/IContact"
import { type IListItem } from "../../../../../shared/models/components/IListItem"
import PaperLocal from "../../../../../shared/components/containers/PaperLocal"
import { type IServiceUser, SERVICE_USER_ENDPOINT } from "../../../../../shared/models/service/IServiceUser"
import { type IOccupancy, OCCUPANCY_ENDPOINT } from "../../../../../shared/models/service/IOccupancy"
import { type IInspectionType, INSPECTION_TYPE_ENDPOINT } from "../../../../../shared/models/service/IInspectionType"

const serviceUserRepository = new RestRepository<IServiceUser | IListItem>(SERVICE_USER_ENDPOINT)
const contactRepository = new RestRepository<IContact | IListItem>(CONTACT_ENDPOINT)
const occupancyRepository = new RestRepository<IOccupancy | IListItem>(OCCUPANCY_ENDPOINT)
const inspectionTypeRepository = new RestRepository<IInspectionType | IListItem>(INSPECTION_TYPE_ENDPOINT)

interface IProps {
  form: UseFormReturn
  vendor?: IVendor | undefined | null
  isEdit?: boolean
}

/**
 * Use this forms to add or edit a vendor.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} returns the forms.
 */
const VendorForm: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { vendor, form, isEdit = false } = props

  const [lead, setLead] = useSelectFilteredSingle("lead", form)
  const [contacts, setContacts] = useSelectFilteredMultiple("contacts", form)
  const [occupancies, setOccupancies] = useSelectFilteredMultiple("occupancies", form)
  const [inspectionTypes, setInspectionTypes] = useSelectFilteredMultiple("inspection_types", form)

  useLoadFormData<IVendor>(
    (data: IVendor) => {
      form.setValue("id", data.id)
      form.setValue("name", data.name)
      form.setValue("notes", data.notes)
      setLead(data.lead)
      setContacts(data.contacts)
      setOccupancies(data.occupancies)
      setInspectionTypes(data.inspection_types)
    },
    vendor,
    isEdit,
    form.setValue,
  )

  return (
    <PaperLocal>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          {isEdit && <FhMuiHiddenField control={form.control} />}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FhMuiTextField control={form.control} label="Name" name="name" rules={requiredRule()} />
            </Grid>
            <Grid item xs={12}>
              <SelectFilteredSingle
                name="lead"
                label="Lead"
                defaultValue={lead}
                repository={serviceUserRepository}
                onChange={setLead}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectFilteredMultiple
                name="contacts"
                defaultValue={contacts}
                repository={contactRepository}
                onChange={setContacts}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectFilteredMultiple
                name="occupancies"
                defaultValue={occupancies}
                repository={occupancyRepository}
                onChange={setOccupancies}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectFilteredMultiple
                name="inspection_types"
                defaultValue={inspectionTypes}
                repository={inspectionTypeRepository}
                onChange={setInspectionTypes}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6}>
          <FhMuiRichTextField control={form.control} name="notes" />
        </Grid>
      </Grid>
    </PaperLocal>
  )
}

export default VendorForm
