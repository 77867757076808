import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { type IRiskWriterSection } from "../../models/IRiskWriterSection"
import {
  type IRiskWriterSectionData,
  type ISectionDataRichText,
  replaceSpansWithTemplate,
  replaceTemplateWithSpans,
  SectionType,
} from "../../models/IRiskWriterSectionData"
import { Box, Button, Chip, Grid, IconButton, List, type SxProps, type Theme, Typography } from "@mui/material"
import ViewLoading from "../../../ViewLoading"
import { cleanHtml, nameToLabel, sanitizeOptions } from "../../../../utilities/form_utility"
import {
  BtnBold,
  BtnBulletList,
  BtnClearFormatting,
  BtnItalic,
  BtnNumberedList,
  BtnRedo,
  BtnUnderline,
  BtnUndo,
  type ContentEditableEvent,
  createButton,
  Editor,
  EditorProvider,
  type EditorState,
  Separator,
  Toolbar,
} from "react-simple-wysiwyg"
import AlertDialog from "../../../AlertDialog"
import DeleteIcon from "@mui/icons-material/Delete"
import { RiskWriterContext } from "../../context/RiskWriterProvider"
import AutoModeIcon from "@mui/icons-material/AutoMode"
import ContentPasteIcon from "@mui/icons-material/ContentPaste"
import PermDataSettingIcon from "@mui/icons-material/PermDataSetting"
import ClearAllIcon from "@mui/icons-material/ClearAll"
import HtmlToolTip from "../../../HtmlToolTip"
import { ArrowDownward, ArrowUpward } from "@mui/icons-material"
import useAuth from "../../../../hooks/useAuth"
import DrawerRight, { useDrawerWithUrl } from "../../../containers/DrawerRight"
import { useNavigate } from "react-router-dom"
import PaperLocal from "../../../containers/PaperLocal"
import ListItemButton from "@mui/material/ListItemButton"
import { type IRiskWriterField, RiskInputType } from "../../models/IRiskWriterField"
import ListItemText from "@mui/material/ListItemText"
import ListItem from "@mui/material/ListItem"

interface IProps {
  section: IRiskWriterSection
  content: IRiskWriterSectionData
  viewerOnly: boolean
}

/**
 * Generates a set of CSS styles to create a pill-shaped element.
 *
 * @param {Theme} theme - The theme object to customize the styles based on the application's theme.
 * @returns {SxProps} The styles object containing CSS properties for the pill-shaped element.
 */
const pillStyles = (theme: Theme): SxProps => ({
  display: "inline-block",
  // padding: "0.25em 0.5em", // Adds space for the pill shape
  // borderRadius: "999px", // Makes it pill-shaped
  backgroundColor: theme.palette.secondary.light,
  color: theme.palette.secondary.contrastText,
  lineHeight: "1.6em",
  fontSize: "0.9em", // Adjusts text size
  textAlign: "center", // Centers the text
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Adds a subtle shadow
})

const editorStyles = {
  p: 1,
  "& .rsw-ce": { mt: 0, mb: 0, border: "none" },
  "& span.pill": pillStyles,
}

/**
 * ContentTextEditor is a React functional component that renders a text editor for content.
 *
 * @param {object} props - The props for the component.
 * @param {object} props.section - The section object.
 * @param {object} props.content - The content object.
 * @returns {React.ReactElement} The rendered component.
 */
const ContentTextEditor: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { section, content, viewerOnly } = props

  const [html, setHtml] = useState<string>("<p><br /></p>")
  const [loading, setLoading] = useState<boolean>(false)
  const editorRef = useRef<HTMLDivElement>(null)
  const [insertPanelOpen, setInsertPanelOpen] = useState(false)

  const navigate = useNavigate()
  const drawerUrl = useDrawerWithUrl()

  const { currentUser } = useAuth()

  const {
    reportWriterFormat,
    currentContent,
    currentSection,
    isSectionEmpty,
    onGenerateContent,
    onChangeContent,
    onDeleteContent,
    onEditContent,
    onContentMove,
  } = useContext(RiskWriterContext)

  const handleChange = useCallback(
    (event: ContentEditableEvent) => {
      const cleaned = cleanHtml(event.target.value)
      if (html !== cleaned) {
        setHtml(cleaned)
      }
    },
    [html, sanitizeOptions],
  )

  const handleContentMoveUp = useCallback(() => {
    onContentMove?.(content, "up")
  }, [html, content])

  const handleContentMoveDown = useCallback(() => {
    onContentMove?.(content, "down")
  }, [html, content])

  const handleSaveText = useCallback(() => {
    if (!insertPanelOpen) {
      const cleaned = replaceSpansWithTemplate(cleanHtml(html))
      const sectionContent: ISectionDataRichText = { html: cleaned }
      onChangeContent?.(content.name, sectionContent)
    }
  }, [html, content, insertPanelOpen])

  const handleDeleteContent = useCallback(async () => {
    onDeleteContent?.(content)
  }, [html, content])

  const handleEditContent = useCallback(() => {
    if (!viewerOnly) {
      onEditContent?.(section, content)
    }
  }, [section, content, viewerOnly])

  const handleGenerateContent = useCallback(
    async (_state: EditorState) => {
      if (currentSection !== null && currentSection !== undefined) {
        setLoading(true)
        try {
          const data = await onGenerateContent?.(currentSection)
          setHtml(html => `${html}<p>${data}</p>`)
        } catch (e: any) {
          console.log(e)
        }
        setLoading(false)
      }
    },
    [currentSection],
  )

  const BtnGetAi = createButton(
    `Write content for ${nameToLabel(section.name, section.title)}.`,
    <AutoModeIcon fontSize="small" />,
    handleGenerateContent,
  )

  const handleSavePasteText = useCallback(async () => {
    const pasteText = await navigator.clipboard.readText()

    // Find the caret position
    const selection = window.getSelection()
    if (selection !== null && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0)

      // Insert the text at the caret position
      range.deleteContents()
      const spaceNode = document.createTextNode(pasteText)
      range.insertNode(spaceNode)

      // Update the caret position to be after the newly inserted text
      range.setStartAfter(spaceNode)
      range.setEndAfter(spaceNode)
      const newHtml = editorRef?.current?.querySelector(".rsw-ce")?.innerHTML
      if (newHtml !== undefined && newHtml !== null && newHtml !== html) {
        setHtml(newHtml)
      }
    } else {
      const cleaned = replaceSpansWithTemplate(cleanHtml(pasteText))
      setHtml(html => `${html}<p>${cleaned}</p>`)
    }
  }, [])

  const BtnPasteText = createButton(
    `Paste text without formatting.`,
    <ContentPasteIcon fontSize="small" />,
    handleSavePasteText,
  )

  const openInsertPanel = useCallback(() => {
    navigate(drawerUrl("risk_writer_insert_value"))
  }, [])

  const handleInsertPanelOpen = useCallback(async () => {
    setInsertPanelOpen(true)
  }, [])

  const handleInsertPanelClose = useCallback(async () => {
    setInsertPanelOpen(false)
  }, [])

  const handleInsertKey = useCallback(
    (prefix: string, field: IRiskWriterField) => () => {
      // Find the caret position
      const selection = window.getSelection()
      if (selection !== null && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0)

        // Insert the text at the caret position
        range.deleteContents()
        const textNode = document.createElement("span")
        textNode.innerHTML = nameToLabel(field.name, field.title)
        textNode.id = `${prefix}_${field.name}`
        textNode.className = "pill"
        range.insertNode(textNode)
        const spaceNode = document.createTextNode(" ")
        range.insertNode(spaceNode)

        // Update the caret position to be after the newly inserted text
        range.setStartAfter(spaceNode)
        range.setEndAfter(spaceNode)
        const newHtml = editorRef?.current?.querySelector(".rsw-ce")?.innerHTML
        if (newHtml !== undefined && newHtml !== null && newHtml !== html) {
          setHtml(newHtml)
        }
      }
    },
    [html, editorRef],
  )

  const BtnInsertKey = createButton("Insert Key", <PermDataSettingIcon fontSize="small" />, openInsertPanel)

  const handleClearEditor = useCallback((_state: EditorState) => {
    setHtml("<p><br /></p>")
  }, [])

  const BtnClear = createButton(`Clear and reset the editor.`, <ClearAllIcon fontSize="small" />, handleClearEditor)

  const handleSelect = useCallback(() => {
    const selection = window.getSelection()
    const element = selection?.anchorNode?.parentElement
    if (element?.className === "pill" && element.tagName === "SPAN") {
      const range = document.createRange()
      range.selectNodeContents(element)
      const selection = window.getSelection()
      selection?.removeAllRanges()
      selection?.addRange(range)
    }
  }, [html])

  const replaceBriefTemplateWithSpans = useCallback(
    (html: string): string => {
      if (reportWriterFormat?.brief_form?.content !== undefined) {
        for (const content of reportWriterFormat.brief_form.content) {
          for (const field of content.fields) {
            html = replaceTemplateWithSpans(html, `brief_${field.name}`, nameToLabel(field.name, field.title))
          }
        }
      }
      return html
    },
    [reportWriterFormat],
  )

  useEffect(() => {
    if (content.name === currentContent?.name) {
      if (content.section_type === SectionType.RICH_TEXT_EDITOR) {
        const sectionRichText = content.data as ISectionDataRichText
        if (
          content.data === null &&
          currentSection?.default_value !== undefined &&
          currentSection.default_value !== null &&
          isSectionEmpty?.(currentSection) === true
        ) {
          const html = (currentSection.default_value as ISectionDataRichText).html
          setHtml(replaceBriefTemplateWithSpans(html))
        }
        if (content.data !== null && sectionRichText.html.trim() !== "") {
          setHtml(replaceBriefTemplateWithSpans(sectionRichText.html))
        }
      }
    }
  }, [content, currentContent, currentSection, isSectionEmpty, reportWriterFormat, replaceBriefTemplateWithSpans])

  const maxHeight = useMemo(() => {
    if (currentUser?.user.profile.rich_text_boxes_full_length === true) {
      return "auto"
    }
    return "40em"
  }, [currentUser])

  return (
    <>
      {content.section_type === SectionType.RICH_TEXT_EDITOR && (
        <Box sx={{ m: 1, mr: 0, ml: 0, border: "1px dashed #ccc" }}>
          <ViewLoading
            loading={loading}
            message={`Writing content for ${nameToLabel(section.name, section.title)}...`}
          />
          {content.name === currentContent?.name && !viewerOnly ? (
            <Box sx={editorStyles} ref={editorRef}>
              <DrawerRight
                title={"Insert Value"}
                showButton={false}
                openCloseWithUrl
                openUrlPrefix="risk_writer_insert_value"
                onDrawerClose={handleInsertPanelClose}
                onDrawerOpen={handleInsertPanelOpen}>
                <Box>
                  {insertPanelOpen && (
                    <>
                      {reportWriterFormat?.brief_form?.content.map(content => {
                        return (
                          <Box key={content.name}>
                            <Typography variant="h5" sx={{ mt: 2, mb: 1 }}>
                              {nameToLabel(content.name, content.title)}
                            </Typography>
                            <PaperLocal sx={{ p: 0 }}>
                              <List>
                                {content.fields
                                  .filter(
                                    f =>
                                      f.input_type !== RiskInputType.LABEL &&
                                      f.input_type !== RiskInputType.SPACE &&
                                      f.input_type !== RiskInputType.IMAGE,
                                  )
                                  .map(field => {
                                    return (
                                      <ListItem
                                        key={field.name}
                                        secondaryAction={<Chip size="small" label={nameToLabel(field.input_type)} />}
                                        disablePadding>
                                        <ListItemButton onClick={handleInsertKey("brief", field)}>
                                          <ListItemText primary={nameToLabel(field.name, field.title)} />
                                        </ListItemButton>
                                      </ListItem>
                                    )
                                  })}
                              </List>
                            </PaperLocal>
                          </Box>
                        )
                      })}
                    </>
                  )}
                </Box>
              </DrawerRight>
              <EditorProvider>
                <Editor
                  value={html}
                  onChange={handleChange}
                  onBlur={handleSaveText}
                  onSelect={handleSelect}
                  style={{ maxHeight, overflow: "auto" }}
                  containerProps={{ style: { resize: "vertical" } }}>
                  <Toolbar>
                    <BtnUndo />
                    <BtnRedo />
                    <Separator />
                    <BtnPasteText />
                    <Separator />
                    <BtnBold />
                    <BtnItalic />
                    <BtnUnderline />
                    <Separator />
                    <BtnNumberedList />
                    <BtnBulletList />
                    <Separator />
                    <BtnClearFormatting />
                    <Separator />
                    <BtnClear />
                    <Separator />
                    <BtnGetAi />
                    <BtnInsertKey />
                  </Toolbar>
                </Editor>
              </EditorProvider>
              <Grid container spacing={1}>
                <Grid item>
                  <HtmlToolTip title={"Move this text up in the section."}>
                    <IconButton color="primary" onClick={handleContentMoveUp} size="small">
                      <ArrowUpward />
                    </IconButton>
                  </HtmlToolTip>
                  <HtmlToolTip title={"Move this text down in the section."}>
                    <IconButton color="primary" onClick={handleContentMoveDown} size="small">
                      <ArrowDownward />
                    </IconButton>
                  </HtmlToolTip>
                </Grid>
                <Grid item xs>
                  <AlertDialog
                    buttonText="Delete"
                    buttonIcon={<DeleteIcon />}
                    onYes={handleDeleteContent}
                    message={`Are you sure you want to delete this content?`}
                  />
                </Grid>
                <Grid item>
                  <Button onClick={handleSaveText}>Done</Button>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <>
              {content.data !== null ? (
                <Box
                  onClick={handleEditContent}
                  sx={(theme: Theme) => ({
                    p: 1,
                    "& span.pill": pillStyles(theme),
                    "&:hover": viewerOnly ? {} : { background: "#ddd", cursor: "pointer" },
                  })}
                  dangerouslySetInnerHTML={{
                    __html: replaceBriefTemplateWithSpans((content.data as ISectionDataRichText).html),
                  }}
                />
              ) : (
                <Box
                  onClick={handleEditContent}
                  sx={{ p: 1, "&:hover": viewerOnly ? {} : { background: "#ddd", cursor: "pointer" } }}>
                  Click here to edit text.
                </Box>
              )}
            </>
          )}
        </Box>
      )}
    </>
  )
}

export default ContentTextEditor
