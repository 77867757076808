export const ACCESS_BETA_TESTER_GROUP = "Access Beta Tester"
export const ACCESS_TESTER_GROUP = "Tester"

// Role Groups
export const ROLE_SYSTEM_MANAGER_GROUP: string = "Role System Manager"
export const ROLE_ACCOUNT_MANAGER_GROUP: string = "Role Account Manager"

// Access Site Groups
export const ACCESS_ADMIN_SITE_GROUP: string = "Access Admin Site"
export const ACCESS_INSPECTION_SITE_GROUP: string = "Access Inspection Site"
export const ACCESS_RECOMMENDATION_SITE_GROUP: string = "Access Recommendation Site"

// Feature Access Groups
export const ACCESS_XLSX_EXPORT_FEATURE_GROUP: string = "Access XLSX Export Feature"
