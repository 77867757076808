import React from "react"
import { Alert, Container, Grid } from "@mui/material"

import { useApiRead } from "../../../../shared/hooks/useApiRead"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import PageHeader from "../../../../shared/components/pages/PageHeader"
import { CONTACT_ENDPOINT, type IContact } from "../../../../shared/models/service/IContact"
import ViewProperty from "../../../../shared/components/ViewProperty"
import { CONTACT_EDIT_URL } from "../../config/urls"
import PaperLocal from "../../../../shared/components/containers/PaperLocal"
import ViewPhone from "../../../../shared/components/display/ViewPhone"
import ViewEmail from "../../../../shared/components/display/ViewEmail"
import ViewListItems from "../../../../shared/components/display/ViewListItems"
import ItemViewerDrawer from "../../../../shared/components/item_viewer/ItemViewerDrawer"
import ServiceUserInfo from "../service_users/components/ServiceUserInfo"
import ViewRichText from "../../../../shared/components/display/ViewRichText"
import { ItemPrefixes } from "../../../../config/config"
import ViewMap from "../../../../shared/components/display/ViewMap"
import { useTranslation } from "react-i18next"

const repository = new RestRepository<IContact>(CONTACT_ENDPOINT)

/**
 * Renders a page to view locations impairment details.
 *
 * @returns {React.ReactElement} The rendered component.
 */
const ViewPage: React.FC = (): React.ReactElement => {
  const { data } = useApiRead<IContact>({ apiFunction: repository.read })
  const { t } = useTranslation()

  return (
    <Container fixed>
      {data !== undefined && (
        <Grid container spacing={2}>
          <PageHeader
            title="Contact"
            titlePlural={data.name}
            toEdit={data.service_user === null ? `${CONTACT_EDIT_URL}/${data.id}` : undefined}
          />
          <Grid item xs={12} lg={6}>
            <PaperLocal>
              <ItemViewerDrawer prefix={ItemPrefixes.serviceUser} infoView={ServiceUserInfo} />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ViewProperty label={t("Job Title")}>{data.job_title}</ViewProperty>
                </Grid>
                <Grid item xs={12}>
                  <ViewProperty label={t("Business Name")}>{data.business_name}</ViewProperty>
                </Grid>
                <Grid item xs={12}>
                  <ViewProperty label={t("Phone")}>
                    <ViewPhone ext={data.phone_ext}>{data.phone}</ViewPhone>
                  </ViewProperty>
                </Grid>
                <Grid item xs={12}>
                  <ViewProperty label="Phone 2">
                    <ViewPhone ext={data.phone_2_ext}>{data.phone_2}</ViewPhone>
                  </ViewProperty>
                </Grid>
                <Grid item xs={12}>
                  <ViewProperty label="Email">
                    <ViewEmail>{data.email}</ViewEmail>
                  </ViewProperty>
                </Grid>
                <Grid item xs={12}>
                  <ViewProperty label="Email 2">
                    <ViewEmail>{data.email_2}</ViewEmail>
                  </ViewProperty>
                </Grid>
                <Grid item xs={12}>
                  <ViewProperty label="Linked Service User">
                    <ViewListItems prefix={ItemPrefixes.serviceUser}>{data.service_user}</ViewListItems>
                  </ViewProperty>
                </Grid>
                <Grid item xs={12}>
                  <ViewProperty label="Notes" vertical>
                    <ViewRichText>{data.notes}</ViewRichText>
                  </ViewProperty>
                </Grid>
              </Grid>
            </PaperLocal>
          </Grid>
          <Grid item xs={12} lg={6}>
            {data.service_user !== null && (
              <PaperLocal sx={{ mb: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Alert severity="warning">
                      Editing contact is not available. Make updates to the linked Service User.
                    </Alert>
                  </Grid>
                </Grid>
              </PaperLocal>
            )}
            <PaperLocal>
              <ViewMap place={data} />
            </PaperLocal>
            <PaperLocal sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ViewProperty label="Address">{data.address}</ViewProperty>
                </Grid>
                <Grid item xs={12}>
                  <ViewProperty label="City">{data.city}</ViewProperty>
                </Grid>
                <Grid item xs={12}>
                  <ViewProperty label="State/Region">{data.state_region}</ViewProperty>
                </Grid>
                <Grid item xs={12}>
                  <ViewProperty label="Postal Code">{data.postal_code}</ViewProperty>
                </Grid>
                <Grid item xs={12}>
                  <ViewProperty label="Country">{data.country}</ViewProperty>
                </Grid>
              </Grid>
            </PaperLocal>
          </Grid>
        </Grid>
      )}
    </Container>
  )
}

export default ViewPage
