import React, { type ChangeEvent, useCallback, useState } from "react"
import {
  Alert,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material"
import { DASHBOARD_URL } from "../../../apps/admin/config/urls"
import type { AxiosError } from "axios"
import { CONNECTION_ERROR, type IConnectionError } from "../../models/components/IConnectionError"
import { useLocation, useNavigate } from "react-router-dom"
import useAuth from "../../hooks/useAuth"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import type IToken from "../../models/users/IToken"

interface ILocationState {
  refer?: string
}

interface IProps {
  setLoading: (loading: boolean) => void
  setError: (loading: boolean) => void
  setErrorMessage: (errorMessage: IConnectionError | null) => void
}

/**
 * React component for a username password form.
 *
 * @param {IProps} props - The props for the component.
 * @returns {React.ReactElement} - The rendered component.
 */
const UsernamePasswordForm: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { setLoading, setError, setErrorMessage } = props

  const [username, setUsername] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [otpCode, setOtpCode] = useState<string>("")
  const [otpToken, setOtpToken] = useState<string>("")

  const [reset, setReset] = useState<boolean>(false)
  const [passwordResetInfo, setPasswordResetInfo] = useState<boolean>(false)

  const location = useLocation()
  const navigate = useNavigate()

  const { loginWithCredentials, resetPassword, loginWithOtpToken } = useAuth()


  const handleSubmit = useCallback(async () => {
    if (resetPassword !== null && reset) {
      setError(false)
      try {
        await resetPassword(username)
        setReset(false)
        setPasswordResetInfo(true)
        setErrorMessage(null)
      } catch (reason) {
        setError(true)
        if ((reason as AxiosError)?.response !== undefined) {
          setErrorMessage((reason as AxiosError).response as IConnectionError)
        } else {
          setErrorMessage(CONNECTION_ERROR)
        }
      }
    }
    if (loginWithCredentials !== null && !reset) {
      setLoading(true)
      setError(false)
      try {
        setErrorMessage(null)
        const response = await loginWithCredentials({ username, password })
        const optToken1 = (response as IToken).otp_token
        if (optToken1 !== undefined) {
          setOtpToken(optToken1)
        } else {
          const refer = (location.state as ILocationState)?.refer
          navigate(refer ?? DASHBOARD_URL)
        }
      } catch (reason) {
        setError(true)
        if ((reason as AxiosError)?.response !== undefined) {
          setErrorMessage((reason as AxiosError).response as IConnectionError)
        } else {
          setErrorMessage(CONNECTION_ERROR)
        }
      }
      setLoading(false)
    }
  }, [loginWithCredentials, username, password, reset])

  const handleLoginWithOtpCode = useCallback(async () => {
    if (otpToken !== null && otpCode !== "" && loginWithOtpToken !== null) {
      setLoading(true)
      setError(false)
      try {
        setErrorMessage(null)
        await loginWithOtpToken(otpToken, otpCode)
        const refer = (location.state as ILocationState)?.refer
        navigate(refer ?? DASHBOARD_URL)
      } catch (reason) {
        setError(true)
        if ((reason as AxiosError)?.response !== undefined) {
          setErrorMessage((reason as AxiosError).response as IConnectionError)
        } else {
          setErrorMessage(CONNECTION_ERROR)
        }
      }
      setLoading(false)
    }
  }, [otpToken, otpCode])

  const handleOptCodeChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setOtpCode(event.target.value)
  }, [])

  const handleUsernameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value)
  }, [])

  const handlePasswordChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }, [])

  const handleClickShowPassword = useCallback(() => {
    setShowPassword(show => !show)
  }, [])

  const handleMouseDownPassword = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }, [])

  const handleToggleRest = useCallback(() => {
    setPasswordResetInfo(false)
    setOtpCode("")
    setOtpToken("")
    setReset(reset1 => !reset1)
  }, [])

  return (
    <>
      <Grid container spacing={2}>
        {passwordResetInfo && (
          <Grid item xs={12}>
            <Alert severity="success">A password reset link has been set to your inbox.</Alert>
          </Grid>
        )}
        {otpToken !== "" ? (
          <>
            <Grid item xs={12}>
              <TextField label="2FA Code" value={otpCode} fullWidth onChange={handleOptCodeChange} />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <TextField label="Email" name="username" value={username} fullWidth onChange={handleUsernameChange} />
            </Grid>
            {!reset && (
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={password}
                    fullWidth
                    onChange={handlePasswordChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
            )}
          </>
        )}
        <Grid item xs={12}>
          {otpToken !== "" ? (
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs sx={{ textAlign: "left" }}>
                <Button onClick={handleToggleRest}>Back to Login</Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="secondary" onClick={handleLoginWithOtpCode}>
                  Verify Code
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs sx={{ textAlign: "left" }}>
                {!reset ? (
                  <Button onClick={handleToggleRest}>Password Reset</Button>
                ) : (
                  <Button onClick={handleToggleRest}>Back to Login</Button>
                )}
              </Grid>
              <Grid item>
                {!reset ? (
                  <Button variant="contained" color="secondary" onClick={handleSubmit}>
                    Login
                  </Button>
                ) : (
                  <Button variant="contained" color="secondary" onClick={handleSubmit}>
                    Send Reset Email
                  </Button>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default UsernamePasswordForm
