import React, { useCallback, useContext, useMemo, useState } from "react"
import {
  Alert,
  AlertTitle,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material"
import PageHeader from "../../components/pages/PageHeader"
import { PanariskAppContext } from "../../../app/PanariskApp"
import useEffectInit from "../../hooks/useEffectInit"
import type { IPanariskWidget } from "../../components/widgets/models/IPanariskWidgetProps"
import { type IWidgetEntry } from "../../components/widgets/WidgetSelection"
import DialogControls from "../../components/DialogControls"
import ExtensionIcon from "@mui/icons-material/Extension"
import AddIcon from "@mui/icons-material/Add"
import { PANARISK_DASHBOARD_KEY } from "../../../config/config"
import useProfileStorage from "../../hooks/useProfileStorage"
import { getBaseApp } from "../../utilities/request_utility"

const storageKey = `${PANARISK_DASHBOARD_KEY}_${getBaseApp()}`

interface IProps {
  siteWidgets: IPanariskWidget[]
}

/**
 * DashboardPage component.
 *
 * This component displays a user dashboard with various widgets. Users can add,
 * remove, and reorder widgets. It contains the following primary elements:
 * - PageHeader: Title and the "Add" button for opening the widget selection dialog.
 * - WidgetSelection: List of available widgets for the user to pick from.
 * - Dynamic display of selected widgets.
 * - Dialog for widget selection.
 *
 * The component uses several hooks:
 * - useMemo for memorizing the list of available widgets.
 * - useState for managing widget entries, dialog state, and hovered widget state.
 * - useContext for accessing global context values.
 * - useCallback for event handlers to manage widgets and dialog.
 * - useEffectInit for initializing the component.
 *
 * @param {IProps} props - The component props
 * @returns {React.ReactElement} The rendered component
 */
const DashboardPage: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { siteWidgets } = props

  const { setActionItem, addonWidgets } = useContext(PanariskAppContext)
  const profileStorage = useProfileStorage<IWidgetEntry[]>(storageKey, [])
  const [widgetEntries, setWidgetEntries] = profileStorage.data
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  const availableWidgets = useMemo(() => {
    if (addonWidgets !== undefined && addonWidgets !== null) {
      return siteWidgets.concat(addonWidgets)
    }
    return siteWidgets
  }, [addonWidgets])

  const handleWidgetUp = useCallback(
    (widget: IWidgetEntry) => async () => {
      if (widgetEntries === null) {
        return
      }

      const widgetEntriesCopy = [...widgetEntries]
      const widgetIndex = widgetEntriesCopy.findIndex(entry => entry.id === widget.id)

      if (widgetIndex < 0) {
        return
      }

      if (widgetIndex > 0) {
        // Normal move up
        const temp = widgetEntriesCopy[widgetIndex - 1]
        widgetEntriesCopy[widgetIndex - 1] = widgetEntriesCopy[widgetIndex]
        widgetEntriesCopy[widgetIndex] = temp
      } else {
        // Move first to last
        const widgetToMove = widgetEntriesCopy.shift()
        if (widgetToMove !== undefined) {
          widgetEntriesCopy.push(widgetToMove)
        }
      }

      await setWidgetEntries(widgetEntriesCopy)
    },
    [widgetEntries],
  )

  const handleWidgetDown = useCallback(
    (widget: IWidgetEntry) => async () => {
      if (widgetEntries === null) {
        return
      }

      const widgetEntriesCopy = [...widgetEntries]
      const widgetIndex = widgetEntriesCopy.findIndex(entry => entry.id === widget.id)

      if (widgetIndex < 0) {
        return
      }

      if (widgetIndex < widgetEntriesCopy.length - 1) {
        // Normal move down
        const temp = widgetEntriesCopy[widgetIndex + 1]
        widgetEntriesCopy[widgetIndex + 1] = widgetEntriesCopy[widgetIndex]
        widgetEntriesCopy[widgetIndex] = temp
      } else {
        // Move last to first
        const widgetToMove = widgetEntriesCopy.pop()
        if (widgetToMove !== undefined) {
          widgetEntriesCopy.unshift(widgetToMove)
        }
      }

      await setWidgetEntries(widgetEntriesCopy)
    },
    [widgetEntries],
  )
  const handleRemoveWidget = useCallback(
    (widget: IWidgetEntry) => async () => {
      if (widgetEntries === null) {
        return
      }

      const widgetEntriesCopy = widgetEntries.filter(entry => entry.id !== widget.id)
      await setWidgetEntries(widgetEntriesCopy)
    },
    [widgetEntries],
  )

  const handleOpenDialog = useCallback(() => {
    setOpenDialog(true)
  }, [])

  const handleCloseDialog = useCallback(async () => {
    setOpenDialog(false)
  }, [])

  const handleAddWidget = useCallback(
    (widget: IPanariskWidget) => async () => {
      await setWidgetEntries([{ id: crypto.randomUUID(), nameId: widget.nameId }, ...(widgetEntries ?? [])])
    },
    [widgetEntries],
  )

  useEffectInit(async () => {
    setActionItem?.(undefined)
  }, [])

  return (
    <>
      <Container fixed disableGutters>
        <Grid container spacing={2} justifyContent="center">
          <PageHeader
            title="Dashboard"
            extraOptions={
              <Button onClick={handleOpenDialog} startIcon={<AddIcon />}>
                Add
              </Button>
            }
          />
          {widgetEntries?.map(widgetEntry => {
            const Widget = availableWidgets.find((widget: IPanariskWidget) => widget.nameId === widgetEntry.nameId)
            if (Widget === undefined) {
              return null
            }
            return (
              <Widget
                key={widgetEntry.id}
                id={widgetEntry.id}
                onMoveUp={handleWidgetUp(widgetEntry)}
                onMoveDown={handleWidgetDown(widgetEntry)}
                onDelete={handleRemoveWidget(widgetEntry)}
              />
            )
          })}
          {(widgetEntries === null || widgetEntries.length === 0) && (
            <Grid item xs={12}>
              <Alert severity="warning">
                Very sad, you have not added any Dashboard Widgets. You should add a widget or 2, you are going to like
                them.
              </Alert>
            </Grid>
          )}
        </Grid>
      </Container>
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="lg" fullWidth>
        <DialogTitle>Widget Selection</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {availableWidgets.map(widget => {
              return (
                <Grid key={widget.nameId} item xs={12} lg={6}>
                  <Alert
                    severity="info"
                    icon={<ExtensionIcon fontSize="inherit" />}
                    action={
                      <Button startIcon={<AddIcon />} onClick={handleAddWidget(widget)}>
                        Add
                      </Button>
                    }>
                    <AlertTitle>{widget.title}</AlertTitle>
                    {widget.description}
                  </Alert>
                </Grid>
              )
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogControls onSave={handleCloseDialog} buttonLabel="Close" />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DashboardPage
