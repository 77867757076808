import React, { useContext } from "react"
import { PanariskAppContext } from "../../../app/PanariskApp"
import { Grid } from "@mui/material"
import { nameToLabel } from "../../utilities/form_utility"
import ViewProperty from "../ViewProperty"
import { MetadataInputType, type TMetadataData, type TModelNames } from "../../models/service/IMetadataConfig"
import ViewRichText from "../display/ViewRichText"
import ViewCurrency from "../display/ViewCurrency"
import ViewNumber from "../display/ViewNumber"
import ViewArea from "../display/ViewArea"
import FormatDate from "../format/FormatDate"
import CheckMark from "../display/CheckMark"
import ViewPercent from "../display/ViewPercent"

interface IProps {
  modelName: TModelNames
  data: TMetadataData | null
  isInfoPanel?: boolean
}

/**
 * Displays metadata information within a grid layout. This component renders details such as text,
 * rich text, currency, percentages, checkboxes, numbers, and dates based on the metadata configuration
 * provided by the server. Each field's input type determines how the data is displayed.
 *
 * @param {IProps} props - The properties object passed to the component.
 * @param {TModelNames} props.modelName - The model name used to retrieve the appropriate metadata configuration.
 * @param {boolean} props.isInfoPanel - A flag indicating if the component is being used in an informational panel.
 * @param {TMetadataData | null} props.data - The data object containing the values to display based on the metadata fields.
 * @returns {React.ReactElement} A React element containing the metadata display grid.
 */
const MetadataDisplay: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { modelName, isInfoPanel = false, data } = props
  const { appSettings } = useContext(PanariskAppContext)
  const metadata = appSettings?.serverInfo?.[`${modelName}_metadata_config`]
  const units = appSettings?.serverInfo?.units

  return (
    <Grid container spacing={2}>
      {metadata?.fields.map(field => {
        return (
          <Grid key={field.name} item xs={12} lg={isInfoPanel ? 12 : field.cols ?? 12}>
            <ViewProperty
              label={nameToLabel(field.name, field.title)}
              vertical={isInfoPanel ? field.input_type === MetadataInputType.RICH_TEXT : false}>
              <>
                {field.input_type === MetadataInputType.TEXT && (
                  <ViewRichText>{data?.[field.name] as string}</ViewRichText>
                )}
                {field.input_type === MetadataInputType.RICH_TEXT && (
                  <ViewRichText>{data?.[field.name] as string}</ViewRichText>
                )}
                {field.input_type === MetadataInputType.CURRENCY && (
                  <ViewCurrency>{data?.[field.name] as number}</ViewCurrency>
                )}
                {field.input_type === MetadataInputType.PERCENT && (
                  <ViewPercent>{data?.[field.name] as number}</ViewPercent>
                )}
                {field.input_type === MetadataInputType.CHECKBOX && <CheckMark value={data?.[field.name]} />}
                {field.input_type === MetadataInputType.FLOAT && <ViewNumber isFloat>{data?.[field.name]}</ViewNumber>}
                {field.input_type === MetadataInputType.INTEGER && <ViewNumber>{data?.[field.name]}</ViewNumber>}
                {field.input_type === MetadataInputType.YEAR && (
                  <ViewRichText>{data?.[field.name] as string}</ViewRichText>
                )}
                {field.input_type === MetadataInputType.AREA && (
                  <ViewArea units={units}>{data?.[field.name] as number}</ViewArea>
                )}
                {field.input_type === MetadataInputType.DATE && <FormatDate value={data?.[field.name] as string} />}
              </>
            </ViewProperty>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default MetadataDisplay
