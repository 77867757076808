import React from "react"
import LoginLayout from "../../../shared/layouts/LoginLayout"
import LoginPage from "../../../shared/pages/auth/LoginPage"
import PasswordResetPage from "../../../shared/pages/auth/PasswordResetPage"
import {
  DASHBOARD_URL,
  INSPECTION_RECOMMENDATION_INDEX_URL,
  INSPECTION_RECOMMENDATION_VIEW_URL,
  LOCATION_IMPAIRMENT_ADD_URL,
  LOCATION_IMPAIRMENT_EDIT_URL,
  LOCATION_IMPAIRMENT_INDEX_URL,
  LOCATION_IMPAIRMENT_VIEW_URL,
  LOGIN_URL,
  PASSWORD_RESET_URL,
  PROFILE_EDIT_URL,
  PROFILE_URL,
  ABOUT_URL
} from "./urls"
import DashboardIndexPage from "../pages/dashboard/IndexPage"
import ProfileIndexPage from "../../../shared/pages/profile/IndexPage"
import AboutIndexPage from "../../../shared/pages/about/IndexPage"
import ProfileEditPage from "../../../shared/pages/profile/EditPage"
import NotFound from "../../../shared/pages/misc/404"
import { createBrowserRouter, type RouteObject } from "react-router-dom"
import { addRoute, routesMerge } from "../../../shared/utilities/routing_utility"
import type { Router as RemixRouter } from "@remix-run/router/dist/router"
import DefaultLayout from "../../../shared/layouts/DefaultLayout"
import InspectionRecommendationIndexPage from "../pages/inspection_recommendations/IndexPage"
import InspectionRecommendationViewPage from "../pages/inspection_recommendations/ViewPage"
import ImpairmentIndexPage from "../../../shared/pages/impairments/IndexPage"
import ImpairmentViewPage from "../../../shared/pages/impairments/ViewPage"
import ImpairmentEditPage from "../../../shared/pages/impairments/EditPage"
import ImpairmentAddPage from "../../../shared/pages/impairments/AddPage"

const routes: RouteObject[] = [
  {
    path: "/",
    element: <LoginLayout title="Login" component={LoginPage} />,
  },
  {
    path: LOGIN_URL,
    element: <LoginLayout title="Login" component={LoginPage} />,
  },
  {
    path: `${PASSWORD_RESET_URL}/*`,
    element: <LoginLayout title="Password Reset" component={PasswordResetPage} />,
  },
  addRoute({ path: DASHBOARD_URL, title: "Dashboard", page: DashboardIndexPage, layout: DefaultLayout }),
  addRoute({ path: `${PROFILE_URL}/*`, title: "Profile", page: ProfileIndexPage, layout: DefaultLayout }),
  addRoute({ path: `${ABOUT_URL}/*`, title: "About", page: AboutIndexPage, layout: DefaultLayout }),
  addRoute({
    path: `${PROFILE_EDIT_URL}/:id`,
    title: "Profile Edit",
    page: ProfileEditPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${INSPECTION_RECOMMENDATION_INDEX_URL}/*`,
    title: "Recommendations",
    page: InspectionRecommendationIndexPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${INSPECTION_RECOMMENDATION_VIEW_URL}/:id/*`,
    title: "Recommendation",
    page: InspectionRecommendationViewPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${LOCATION_IMPAIRMENT_VIEW_URL}/:id/*`,
    title: "Impairment",
    page: ImpairmentViewPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${LOCATION_IMPAIRMENT_EDIT_URL}/:id`,
    title: "Impairment Edit",
    page: ImpairmentEditPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: LOCATION_IMPAIRMENT_ADD_URL,
    title: "Impairment Add",
    page: ImpairmentAddPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${LOCATION_IMPAIRMENT_INDEX_URL}/*`,
    title: "Impairments",
    page: ImpairmentIndexPage,
    layout: DefaultLayout,
  }),
  addRoute({ path: "*", title: "Not Found", page: NotFound, layout: DefaultLayout }),
]

interface ITheRouter {
  basename?: string
  addonRoutes?: RouteObject[]
}

/**
 * Initializes and returns a RemixRouter instance based on provided properties.
 *
 * @param {ITheRouter} props - An object containing configuration properties for the router.
 * @param {string} props.basename - The base URL for all locations.
 * @param {Array} [props.addonRoutes] - Additional routes to be merged with the default routes.
 *
 * @returns {RemixRouter} - The configured RemixRouter instance.
 */
export const theRouter = (props: ITheRouter): RemixRouter => {
  const { basename, addonRoutes } = props
  if (addonRoutes !== undefined) {
    return createBrowserRouter(routesMerge(routes, addonRoutes), { basename })
  }
  return createBrowserRouter(routes, { basename })
}
