import React, { useCallback, useEffect, useState } from "react"
import { type IFile, isImage } from "../../models/service/IFile"
import useAzureStorage from "../../hooks/useAzureStorage"
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  Tab,
  Typography,
} from "@mui/material"
import DialogControls from "../DialogControls"
import FileDropZone from "./FileDropZone"
import HtmlToolTip from "../HtmlToolTip"
import EditIcon from "@mui/icons-material/Edit"
import { RestRepository } from "../../repositories/RestRepository"
import SelectFilteredMultiple, { useSelectFilteredMultiple } from "../forms/SelectFilteredMultiple"
import { type IListItem } from "../../models/components/IListItem"
import { FILE_TAG_ENDPOINT } from "../../models/service/IFileTag"
import UploadIcon from "@mui/icons-material/Upload"
import CheckIcon from "@mui/icons-material/Check"
import DrawerRight from "../containers/DrawerRight"
import { useForm } from "react-hook-form"
import FormBox from "../forms/FormBox"
import FhMuiTextField from "../forms/FhMuiTextField"
import { requiredRule } from "../../utilities/form_utility"
import MuiFile from "../forms/MuiFile"
import { type IFileUpload } from "../../models/components/IFileUpload"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import TruncateText from "../TruncateText"
import prettyBytes from "pretty-bytes"
import { type IPaging } from "../../models/components/IPaging"
import TabPanel, { useTabPanel } from "../tabs/TabPanel"
import TabsList from "../tabs/TabsList"
import { useTranslation } from "react-i18next"

const fileTagRepository = new RestRepository<IListItem>(FILE_TAG_ENDPOINT)

interface IFileNew {
  id?: number
  name: string
  ext: string
}

interface IProps {
  parentId: string | number | undefined
  onChange: () => void
  repository: RestRepository<any>
  useDropzone?: boolean
  useEdit?: boolean
  selectedFile?: IFile | null
  fieldName: string
  openCloseWithUrl?: boolean
}

/**
 * This is a generic component for the adding and editing of files.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the add / edit file component.
 */
const FileAddEdit: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const {
    useDropzone = false,
    openCloseWithUrl = false,
    useEdit = false,
    fieldName,
    selectedFile = null,
    parentId,
    onChange,
    repository,
  } = props

  const [openDialog, setOpenDialog] = useState(false)
  const form = useForm()
  const { t } = useTranslation()
  const { tab, handleTabChange } = useTabPanel({ useSearchParams: false })

  const [fileTags, setFileTags] = useSelectFilteredMultiple("file_tags", form)
  const [fileUploads, setFileUploads] = useState<IFileUpload[] | null>(null)
  const [fileUploadingIndex, setFileUploadingIndex] = useState<number>(0)

  const [saving, setSaving] = useState(false)
  const { uploading, progress, uploadFile } = useAzureStorage()

  const handleSaveFile = useCallback(
    async (file1: IFile | IFileNew, isEdit: boolean = false) => {
      if (parentId !== undefined) {
        const file: IFile | IFileNew = { ...file1, [fieldName]: parentId }
        if (isEdit && file.id !== undefined) {
          return await repository.edit(file, file.id)
        } else {
          return await repository.add(file)
        }
      }
    },
    [parentId, fieldName],
  )

  const handleClose = useCallback(() => {
    form.reset()
    setOpenDialog(false)
    setFileUploads(null)
  }, [])

  const handleEdit = useCallback(() => {
    if (selectedFile !== undefined && selectedFile !== null) {
      form.setValue("name", selectedFile.name)
      form.setValue("ext", selectedFile.ext)
      form.setValue("caption", selectedFile.caption)
      setFileTags(selectedFile.file_tags as IListItem[])
      setOpenDialog(true)
    }
  }, [selectedFile])

  const handleSave = useCallback(async () => {
    const file = form.getValues() as IFile
    setSaving(true)
    if (selectedFile !== null) {
      const fileSave: IFile = { ...file, id: selectedFile.id }
      await handleSaveFile(fileSave, true)
    }
    onChange()
    setSaving(false)
    handleClose()
  }, [selectedFile, fileUploads, handleSaveFile])

  const handleFilesChange = useCallback(
    async (files: IFileUpload[]) => {
      setFileUploads(files)
      setSaving(true)
      let index = 0
      for (const fileUpload of files) {
        setFileUploadingIndex(index)
        const saveFile: IFileNew = { name: fileUpload.name, ext: fileUpload.ext }
        const fileSaved: IFile | undefined = await handleSaveFile(saveFile)
        if (fileSaved?.file !== undefined) {
          await uploadFile(fileSaved.file, fileUpload.file)
          const paging: IPaging = { filters: [{ field: "force", value: "true" }] }
          await repository.action(fileSaved.id, "refresh", paging)
        }
        index++
      }
      setFileUploadingIndex(0)
      setFileUploads(null)
      onChange()
      setSaving(false)
    },
    [handleSaveFile, onChange],
  )

  const handleFileUpdate = useCallback(
    async (files: IFileUpload[]) => {
      if (selectedFile?.file !== undefined) {
        setFileUploads([files[0]])
        setSaving(true)

        const fileUpload = files[0]
        await uploadFile(selectedFile.file, fileUpload.file)
        const paging: IPaging = { filters: [{ field: "force", value: "true" }] }
        await repository.action(selectedFile.id, "refresh", paging)

        setFileUploads(null)
        onChange()
        setSaving(false)
      }
    },
    [handleSaveFile, onChange, selectedFile],
  )

  const handleRefresh = useCallback(async () => {
    if (selectedFile !== null) {
      const paging: IPaging = { filters: [{ field: "force", value: "true" }] }
      await repository.action(selectedFile.id, "refresh", paging)
    }
  }, [selectedFile])

  useEffect(() => {
    if (progress === 100) {
      onChange()
    }
  }, [progress])

  return (
    <>
      {useEdit && (
        <HtmlToolTip title="Edit file.">
          <IconButton color="primary" onClick={handleEdit} size="small">
            <EditIcon />
          </IconButton>
        </HtmlToolTip>
      )}
      {useDropzone && (
        <>
          <DrawerRight
            secondDrawer
            title="File Upload"
            icon={<UploadIcon />}
            stayOpen={saving}
            openCloseWithUrl={openCloseWithUrl}
            openUrlPrefix="file_upload">
            <Grid container spacing={1} sx={{ mt: 1 }}>
              <Grid item xs={12}>
                <FileDropZone onChange={handleFilesChange} loading={saving} progress={progress} />
              </Grid>
              <Grid item xs={12}>
                {!saving && <MuiFile onChange={handleFilesChange} clear={!saving} />}
              </Grid>
              <Grid item xs={12}>
                {fileUploads?.length !== undefined && fileUploads?.length > 0 && (
                  <Box sx={{ mt: 3 }}>
                    <Typography variant="h5">Uploading Files</Typography>
                    <List>
                      {fileUploads?.map((fileUpload, index) => {
                        return (
                          <ListItem
                            key={index}
                            secondaryAction={
                              <>
                                {index < fileUploadingIndex && <CheckIcon />}
                                {index === fileUploadingIndex && progress !== null && (
                                  <CircularProgress size={20} variant="determinate" value={progress} />
                                )}
                                {index > fileUploadingIndex && <CircularProgress size={20} />}
                              </>
                            }>
                            <ListItemText
                              primary={<TruncateText>{fileUpload.fullName}</TruncateText>}
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    sx={{ display: "inline", pr: 2 }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary">
                                    File Size
                                  </Typography>
                                  {prettyBytes(fileUpload.file.size)}
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                        )
                      })}
                    </List>
                  </Box>
                )}
              </Grid>
            </Grid>
          </DrawerRight>
        </>
      )}
      <Dialog onClose={handleClose} open={openDialog} fullWidth={true} maxWidth="sm" sx={{ zIndex: 1500 }}>
        <DialogTitle>Edit File</DialogTitle>
        <DialogContent>
          <FormBox form={form} onSubmit={handleSave} showActionPanel={false}>
            <TabsList value={tab} onChange={handleTabChange}>
              <Tab label={t("Details")} value={0} />
              <Tab label={t("Thumbnail")} value={1} />
              <Tab label={t("Upload")} value={2} />
            </TabsList>
            <TabPanel value={tab} index={0}>
              <Grid container spacing={2} alignItems="center" sx={{ mt: 1 }}>
                <Grid item xs={12} md={8}>
                  <FhMuiTextField control={form.control} label="Name" name="name" rules={requiredRule()} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FhMuiTextField control={form.control} label="Ext" name="ext" rules={requiredRule()} />
                </Grid>
                <Grid item xs={12}>
                  <FhMuiTextField control={form.control} label="Caption" name="caption" />
                </Grid>
                <Grid item xs>
                  <SelectFilteredMultiple
                    name="file_tags"
                    label="File Tags"
                    defaultValue={fileTags}
                    repository={fileTagRepository}
                    onChange={setFileTags}
                  />
                </Grid>
                <Grid item>
                  <Button onClick={handleRefresh}>Refresh</Button>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <Grid container spacing={2} sx={{textAlign: "center"}}>
                <Grid item xs={12}>
                  {selectedFile !== null && isImage(selectedFile) ? (
                    <img src={selectedFile?.file_thumbnail?.read_url} alt={selectedFile.caption} />
                  ) : (
                    <>Thumbnail only available for images.</>
                  )}
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tab} index={2}>
              <Grid container spacing={2} alignItems="center" sx={{ mt: 1 }}>
                <Grid item xs={12}>
                  <FileDropZone single onChange={handleFileUpdate} loading={saving} progress={progress} />
                </Grid>
                <Grid item xs={12}>
                  {!saving && <MuiFile single onChange={handleFileUpdate} clear={!saving} />}
                </Grid>
              </Grid>
            </TabPanel>
          </FormBox>
        </DialogContent>
        <DialogActions>
          <DialogControls
            disabled={Boolean(uploading) || saving || (fileUploads === null && selectedFile === null)}
            loading={saving}
            onSave={handleSave}
            onCancel={handleClose}
            buttonLabel="Update"
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default FileAddEdit
