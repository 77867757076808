import React, { useCallback } from "react"
import { Box, Grid, type Theme, Typography } from "@mui/material"
import { useDropzone } from "react-dropzone"
import ViewLoading from "../ViewLoading"
import { useFileUploads } from "../forms/MuiFile"
import { type IFileUpload } from "../../models/components/IFileUpload"

interface IProps {
  onChange: (files: IFileUpload[]) => void
  loading: boolean
  children?: React.ReactNode
  progress?: number | null | undefined
  single?: boolean
}

/**
 * Reusable file drop zone.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the file drop zone.
 */
const FileDropZone: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { onChange, loading, children, progress, single = false } = props

  const theFileUploads = useFileUploads()

  const handleDrop = useCallback((files: File[]) => {
    const fileUploads = theFileUploads[1](files)
    onChange(fileUploads)
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    noClick: true,
    disabled: loading,
    maxFiles: single ? 1 : undefined,
  })

  const isDragActive2: boolean = isDragActive

  return (
    <Box
      {...getRootProps()}
      sx={
        loading
          ? undefined
          : (theme: Theme) => ({
              border: isDragActive2 ? "2px dashed #888" : "2px dashed #ccc",
              backgroundColor: isDragActive2 ? theme.palette.grey.A400 : theme.palette.background.paper,
              p: 1,
              height: 300,
            })
      }>
      <input {...getInputProps()} />
      {loading ? (
        <ViewLoading loading={loading} inline progress={progress} message="Uploading..." />
      ) : (
        <Grid
          container
          alignItems="center"
          alignContent="center"
          sx={{ height: "100%", width: "100%", textAlign: "center" }}>
          <Grid item xs>
            <Typography>Drop file{!single && "s"} here...</Typography>
          </Grid>
          <Grid item>{children}</Grid>
        </Grid>
      )}
    </Box>
  )
}

export default FileDropZone
