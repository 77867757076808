import React from "react"
import { Grid, Tab } from "@mui/material"

import { useApiRead } from "../../../../shared/hooks/useApiRead"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import PageHeader from "../../../../shared/components/pages/PageHeader"
import { type IPolicy, POLICY_ENDPOINT } from "../../../../shared/models/service/IPolicy"
import ViewProperty from "../../../../shared/components/ViewProperty"
import FormatDate from "../../../../shared/components/format/FormatDate"
import { POLICY_EDIT_URL } from "../../config/urls"
import PageViewBody from "../../../../shared/components/pages/PageViewBody"
import TabPanel, { useTabPanel } from "../../../../shared/components/tabs/TabPanel"
import { useOnDemandPaged } from "../../../../shared/hooks/useApiPagedLocal"
import { useParams } from "react-router-dom"
import { type IFile, POLICY_FILE_ENDPOINT } from "../../../../shared/models/service/IFile"
import FilesIndex from "../../../../shared/components/files/FilesIndex"
import { type IMainModel } from "../../../../shared/models/service/IMainModel"
import PaperLocal from "../../../../shared/components/containers/PaperLocal"
import ItemViewerDrawer from "../../../../shared/components/item_viewer/ItemViewerDrawer"
import ContactInfo from "../contacts/components/ContactInfo"
import ServiceUserInfo from "../service_users/components/ServiceUserInfo"
import ViewRichText from "../../../../shared/components/display/ViewRichText"
import { ItemPrefixes } from "../../../../config/config"
import { useTranslation } from "react-i18next"
import LocationInfo from "../locations/components/LocationInfo"
import { ACCOUNT_ENDPOINT, type IAccount } from "../../../../shared/models/service/IAccount"
import AccountIndex from "../accounts/components/AccountIndex"
import TabsList from "../../../../shared/components/tabs/TabsList"

const repository = new RestRepository<IPolicy>(POLICY_ENDPOINT)
const filesRepository = new RestRepository<IFile | IMainModel>(POLICY_FILE_ENDPOINT)

/**
 * Renders a page to view locations impairment details.
 *
 * @returns {React.ReactElement} The rendered component.
 */
const ViewPage: React.FC = (): React.ReactElement => {
  const { data, loading } = useApiRead<IPolicy>({ apiFunction: repository.read })
  const { tab, handleTabChange } = useTabPanel()
  const { id } = useParams()
  const { t } = useTranslation()

  const theFilter = [{ field: "policies", value: id, title: "Policy", display: "This Policy", canBeDelete: false }]
  const filesPagingResults = useOnDemandPaged<IFile>(POLICY_FILE_ENDPOINT, theFilter, "FILES")
  const accountPagingResults = useOnDemandPaged<IAccount>(ACCOUNT_ENDPOINT, theFilter, "ACCOUNTS")

  return (
    <PageViewBody loading={loading}>
      {data !== undefined && (
        <>
          <PageHeader
            title={t("Policy")}
            titlePlural={data.name}
            toEdit={`${POLICY_EDIT_URL}/${data.id}`}
          />
          <Grid item xs={12}>
            <TabsList value={tab} onChange={handleTabChange}>
              <Tab label={t("Overview")} value={0} />
              <Tab label={t("Accounts")} value={1} />
              <Tab label={t("Files")} value={2} />
            </TabsList>
          </Grid>
          <Grid item xs={12}>
            <TabPanel value={tab} index={0}>
              <ItemViewerDrawer prefix={ItemPrefixes.location} infoView={LocationInfo} />
              <ItemViewerDrawer title={t("Contact")} prefix={ItemPrefixes.contact} infoView={ContactInfo} />
              <ItemViewerDrawer
                title={t("Service User")}
                prefix={ItemPrefixes.serviceUser}
                infoView={ServiceUserInfo}
              />
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <PaperLocal>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <ViewProperty label={t("Start Date")}>
                              <FormatDate value={data.start_date} />
                            </ViewProperty>
                          </Grid>
                          <Grid item xs={12}>
                            <ViewProperty label={t("End Date")}>
                              <FormatDate value={data.end_date} />
                            </ViewProperty>
                          </Grid>
                          <Grid item xs={12}>
                            <ViewProperty label={t("Created")}>
                              <FormatDate value={data.created} />
                            </ViewProperty>
                          </Grid>
                        </Grid>
                      </PaperLocal>
                    </Grid>
                    <Grid item xs={12}>
                      <PaperLocal>
                        <ViewProperty label={t("Description")} vertical>
                          <ViewRichText>{data.description}</ViewRichText>
                        </ViewProperty>
                      </PaperLocal>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tab} index={1} onChange={accountPagingResults.call}>
              <AccountIndex
                pagingResults={accountPagingResults}
                showHeader={false}
                gotoFilters={[{ field: "policies", value: data.id }]}
              />
            </TabPanel>
            <TabPanel value={tab} index={2} onChange={filesPagingResults.call}>
              <FilesIndex
                parentId={id}
                parentFieldName="policy"
                repository={filesRepository}
                pagingResults={filesPagingResults}
              />
            </TabPanel>
          </Grid>
        </>
      )}
    </PageViewBody>
  )
}

export default ViewPage
