import React from "react"
import Top5ListWidget from "../../../../shared/components/widgets/prebuilt/Top5ListWidget"
import type { IPanariskWidget } from "../../../../shared/components/widgets/models/IPanariskWidgetProps"
import ChartBuilderWidget from "../../../../shared/components/widgets/prebuilt/ChartBuilderWidget"
import DashboardPage from "../../../../shared/pages/dashboard/DashboardPage"
import SummaryRiskWriterContentWidget
  from "../../../../shared/components/widgets/prebuilt/SummaryRiskWriterContentWidget"

const WIDGETS: IPanariskWidget[] = [Top5ListWidget, ChartBuilderWidget, SummaryRiskWriterContentWidget]

/**
 * Renders the index page with statistics and top impairments/recommendations.
 *
 * @returns {React.ReactElement} The rendered index page.
 */
const IndexPage: React.FC = (): React.ReactElement => {
  return <DashboardPage siteWidgets={WIDGETS} />
}

export default IndexPage
