import React from "react"
import { Grid } from "@mui/material"
import LocationsMap from "./LocationsMap"


/**
 * Renders the index page for the locations.
 *
 * @returns {React.ReactElement} The rendered index page.
 */
const MapPage: React.FC = (): React.ReactElement => {

  return (
    <Grid container spacing={2}>
      <LocationsMap />
    </Grid>
  )
}

export default MapPage
