import { Controller, type FieldValues, type RegisterOptions } from "react-hook-form"
import React from "react"
import { type ChangeEvent } from "react"
import { type Control } from "react-hook-form/dist/types/form"
import { Box, Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material"
import { nameToLabel } from "../../utilities/form_utility"
import { useTranslation } from "react-i18next"
import HtmlToolTip from "../HtmlToolTip"
import InfoIcon from "@mui/icons-material/Info"

interface IProps {
  control: Control
  defaultValue?: string | number | boolean
  label?: string | null
  showLabel?: boolean
  name: string
  rules?:
    | Omit<RegisterOptions<FieldValues, string>, "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled">
    | undefined
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  helperText?: string
  disabled?: boolean
}

/**
 * A forms hook MUI checkbox field.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the checkbox field.
 */
const FhMuiCheckboxField: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const {
    control,
    defaultValue = false,
    onChange,
    name,
    label,
    showLabel = true,
    rules = {},
    disabled = false,
    helperText = null,
  } = props
  const { t } = useTranslation()

  const theLabel = nameToLabel(name, label)

  return (
    <Grid container>
      <Grid item xs>
        <FormGroup>
          <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
            render={({ field }) =>
              showLabel ? (
                <FormControlLabel
                  label={t(theLabel)}
                  control={
                    <Checkbox
                      inputProps={{ "aria-label": t(theLabel) }}
                      onBlur={field.onBlur}
                      onChange={e => {
                        field.onChange(e)
                        onChange?.(e)
                      }}
                      disabled={disabled}
                      checked={field.value}
                      inputRef={field.ref}
                    />
                  }
                />
              ) : (
                <Checkbox
                  inputProps={{ "aria-label": t(theLabel) }}
                  onBlur={field.onBlur}
                  onChange={e => {
                    field.onChange(e)
                    onChange?.(e)
                  }}
                  checked={field.value}
                  inputRef={field.ref}
                />
              )
            }
          />
        </FormGroup>
      </Grid>
      {helperText !== null && (
        <Grid item>
          <Box sx={{ position: "relative", marginLeft: -3, marginTop: 0, cursor: "pointer" }}>
            <HtmlToolTip title={<Box dangerouslySetInnerHTML={{ __html: helperText }} />} placement="left">
              <InfoIcon color="secondary" />
            </HtmlToolTip>
          </Box>
        </Grid>
      )}
    </Grid>
  )
}

export default FhMuiCheckboxField
