import { type IListItem } from "../components/IListItem"
import { type IContact } from "./IContact"
import { type IGroup } from "../users/IGroup"
import { type IVendor } from "./IVendor"

export const SERVICE_USER_ENDPOINT = "service_users"
export const SERVICE_USER_EXT_ENDPOINT = "service_user_file_ext"

export interface IServiceUser extends IListItem {
  id: number
  first_name: string
  last_name: string
  job_title: string

  user_groups: IGroup[]

  phone: string
  phone_ext: string

  phone_2: string
  phone_2_ext: string

  email: string
  email_2: string

  address: string
  address_2: string
  city: string
  state_region: string
  postal_code: string
  country: string

  longitude: number | string
  latitude: number | string
  zoom: number | string

  notes: string

  contact: IContact | null
  vendor_leads: IVendor[] | null
  vendor_members: IVendor[] | null

  last_login: string

  has_enabled_user_account: boolean
  has_2fa_enabled: boolean

  created: string
  updated: string
}
