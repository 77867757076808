import React, { useCallback, useState } from "react"
import HelpIcon from "@mui/icons-material/Help"
import DrawerRight from "../containers/DrawerRight"
import { Box } from "@mui/material"
import { useTranslation } from "react-i18next"
import useContentHeight from "../../hooks/useContentHeight"
import ViewLoading from "../ViewLoading"
import useAuth from "../../hooks/useAuth"
import { BASE_URL } from "../../utilities/request_utility"

interface IProps {
  pageId: number
}

const domain = new URL(BASE_URL).hostname
const docsUrl = domain === "localhost" ? domain : "https://www.panarisk.com/wp-json/custom-auth/v1/login"

/**
 * Renders the HelpDocsDrawer component displaying help documentation.
 *
 * @param {IProps} props - The properties passed to the component.
 * @param {number} props.pageId - The ID of the page to display documentation for.
 * @returns {React.ReactElement | boolean} The rendered HelpDocsDrawer component.
 */
const HelpDocsDrawer: React.FC<IProps> = (props: IProps): React.ReactElement | boolean => {
  const { pageId } = props
  const { t } = useTranslation()
  const height = useContentHeight(-27)
  const [loading, setLoading] = useState(false)

  const { currentUser } = useAuth()

  const handleIframeLoad = useCallback(() => {
    setLoading(false)
  }, [])

  const handleOpen = useCallback(async () => {
    setLoading(true)
  }, [])

  return currentUser?.token?.access !== undefined && (
    <DrawerRight title={t("Help")} icon={<HelpIcon />} hasMargin={false} onDrawerOpen={handleOpen}>
      <Box sx={{ height }}>
        <ViewLoading loading={loading} inline/>
        <iframe
          src={`${docsUrl}?pageid=${pageId}&token=${currentUser.token.access}&domain=${domain}`}
          title={t("Help Documentation")}
          width="100%"
          height={height}
          style={{ border: "none" }}
          onLoad={handleIframeLoad}></iframe>
      </Box>
    </DrawerRight>
  )
}

export default HelpDocsDrawer
