import React from "react"
import { Grid } from "@mui/material"

import { useApiRead } from "../../../../../shared/hooks/useApiRead"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import ViewProperty from "../../../../../shared/components/ViewProperty"
import ViewRichText from "../../../../../shared/components/display/ViewRichText"
import { type IInfoViewProps } from "../../../../../shared/components/item_viewer/ItemViewerDrawer"
import ItemViewerTitle from "../../../../../shared/components/item_viewer/ItemViewerTitle"
import { EMAIL_TEMPLATE_VIEW_URL } from "../../../config/urls"
import { EMAIL_TEMPLATE_ENDPOINT, type IEmailTemplate } from "../../../../../shared/models/service/IEmailTemplate"
import { useTranslation } from "react-i18next"

const repository = new RestRepository<IEmailTemplate>(EMAIL_TEMPLATE_ENDPOINT)

/**
 * Renders a page to view email template details.
 *
 * @param {IInfoViewProps} props See props for details
 * @returns {React.ReactElement} The rendered component.
 */
const EmailTemplateInfo: React.FC<IInfoViewProps> = (props: IInfoViewProps): React.ReactElement => {
  const { infoId } = props
  const { data } = useApiRead<IEmailTemplate>({ apiFunction: repository.read, itemId: infoId })
  const { t } = useTranslation()

  return (
    <>
      {data !== undefined && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ItemViewerTitle to={`${EMAIL_TEMPLATE_VIEW_URL}/${data.id}`}>{data.name}</ItemViewerTitle>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label={t("Subject")}>
              <ViewRichText>{data.subject}</ViewRichText>
            </ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label={t("Content")}>
              <ViewRichText>{data.content}</ViewRichText>
            </ViewProperty>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default EmailTemplateInfo
