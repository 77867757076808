import { type IListItem } from "../components/IListItem"
import { type IReportFormat, type IReportWriterFormat } from "./IReportFormat"
import { type IRiskWriterData } from "../../components/risk_writer/models/IRiskWriterData"
import { type IRiskWriteModel } from "../components/IRiskWriteModel"
import { type TRiskWriterFormData } from "../../components/risk_writer/models/TRiskWriterFormData"
import { type IPaging } from "../components/IPaging"

export const SUMMARY_ENDPOINT = "summaries"
export const SUMMARY_FILE_EXT_ENDPOINT = "summary_file_ext"

export interface ISummary extends IRiskWriteModel, IListItem {
  id: number
  name: string
  report_format: IReportFormat | null
  report_writer_format: IReportWriterFormat | null
  report_format_summary_name: string
  filters: IPaging | null
  risk_writer_data: IRiskWriterData | null
  risk_writer_brief_data: TRiskWriterFormData | null
  notes: string
  created: string
  updated: string
}
