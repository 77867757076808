import { type IContact } from "./IContact"
import { type IServiceUser } from "./IServiceUser"
import { type IOccupancy } from "./IOccupancy"
import { type IInspectionType } from "./IInspectionType"

export const VENDOR_ENDPOINT = "vendors"

export interface IVendor {
  id: number
  name: string
  lead: IServiceUser | null
  contacts: IContact[]
  occupancies: IOccupancy[]
  inspection_types: IInspectionType[]
  notes: string
  created: string
  updated: string
}
