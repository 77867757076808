import React from "react"
import { RestRepository } from "../../../repositories/RestRepository"
import { FILTER_BOOKMARK_VIEW_URL } from "../../../../apps/admin/config/urls"
import { TableBody, TableHead, TableRow } from "@mui/material"
import TableData from "../../../components/tables/TableData"
import TableCellDate from "../../../components/tables/TableCellDate"
import ListData from "../../../components/lists/ListData"
import ListDataItem from "../../../components/lists/ListDataItem"
import ListProperty from "../../../components/lists/ListProperty"
import TableCellData from "../../../components/tables/TableCellData"
import { type IListItem } from "../../../models/components/IListItem"
import FiltersData from "../../../components/filters/FiltersData"
import { type IUseApiPagedResultsResponse } from "../../../hooks/useApiPagedLocal"
import TableCellTruncate from "../../../components/tables/TableCellTruncate"
import TableCellCenter from "../../../components/tables/TableCellCenter"
import FilterSearch from "../../../components/filters/FilterSearch"
import { useTranslation } from "react-i18next"
import { FILTER_BOOKMARK_ENDPOINT, type IFilterBookmark } from "../../../models/service/IFilterBookmark"
import ItemViewerDrawer from "../../../components/item_viewer/ItemViewerDrawer"
import { ItemPrefixes } from "../../../../config/config"
import FilterBookmarkInfo from "./FilterBookmarkInfo"
import { nameToLabel } from "../../../utilities/form_utility"
import TableCellAction from "../../../components/tables/TableCellAction"
import ItemDelete from "../../../components/item_delete/ItemDelete"
import { type IMainModel } from "../../../models/service/IMainModel"

const gotoRepo = new RestRepository<IListItem>(FILTER_BOOKMARK_ENDPOINT)

interface IProps {
  pagingResults: IUseApiPagedResultsResponse<IFilterBookmark>
}

const repository = new RestRepository<IMainModel>(FILTER_BOOKMARK_ENDPOINT)

/**
 * Renders the Impairment Index component.
 *
 * @param {IProps} props - The component props.
 * @returns {React.ReactElement} - The rendered component.
 */
const FilterBookmarkIndex: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { pagingResults } = props
  const { data } = pagingResults
  const { t } = useTranslation()

  return (
    <>
      {pagingResults !== undefined && (
        <>
          <ItemViewerDrawer
            title={t("Filter Bookmark")}
            prefix={ItemPrefixes.filterBookmark}
            infoView={FilterBookmarkInfo}
          />
          <TableData
            toInfo
            pagingResults={pagingResults}
            to={FILTER_BOOKMARK_VIEW_URL}
            repository={gotoRepo}
            toInfoPrefix={ItemPrefixes.filterBookmark}
            storageSuffix="filter_bookmarks">
            <FiltersData pagingResults={pagingResults}>
              <FilterSearch />
            </FiltersData>
            <TableHead>
              <TableCellData field="id">{t("ID")}</TableCellData>
              <TableCellData field="name" pagingResults={pagingResults}>
                {t("Name")}
              </TableCellData>
              <TableCellData field="model_name" pagingResults={pagingResults}>
                {t("Source")}
              </TableCellData>
              <TableCellData field="notes">{t("Notes")}</TableCellData>
              <TableCellCenter field="created" pagingResults={pagingResults}>
                {t("Created")}
              </TableCellCenter>
              <TableCellCenter field="updated" pagingResults={pagingResults}>
                {t("Updated")}
              </TableCellCenter>
              <TableCellAction field="action">{t("Action")}</TableCellAction>
            </TableHead>
            <TableBody>
              {data?.results.map((item: IFilterBookmark) => (
                <TableRow key={item.id}>
                  <TableCellData field="id">{item.id}</TableCellData>
                  <TableCellTruncate field="name">{item.name}</TableCellTruncate>
                  <TableCellTruncate field="model_name">{nameToLabel(item.model_name)}</TableCellTruncate>
                  <TableCellTruncate field="notes">{item.notes}</TableCellTruncate>
                  <TableCellDate field="created">{item.created}</TableCellDate>
                  <TableCellDate field="updated">{item.updated}</TableCellDate>
                  <TableCellAction field="action">
                    <ItemDelete repository={repository} item={item} onChange={pagingResults.call} />
                  </TableCellAction>
                </TableRow>
              ))}
            </TableBody>
          </TableData>

          <ListData pagingResults={pagingResults}>
            {data?.results.map((item: IFilterBookmark) => (
              <ListDataItem key={item.id} title={item.name}>
                <ListProperty label={t("Source")}>{nameToLabel(item.model_name)}</ListProperty>
              </ListDataItem>
            ))}
          </ListData>
        </>
      )}
    </>
  )
}

export default FilterBookmarkIndex
