export type TMetadataData = Record<string, string | number | boolean | null>

export type TModelNames = "account"| "location" | "inspection"

export enum MetadataInputType {
  RICH_TEXT = "rich_text",
  TEXT = "text",
  INTEGER = "integer",
  FLOAT = "float",
  CURRENCY = "currency",
  AREA = "area",
  PERCENT = "percent",
  YEAR = "year",
  CHECKBOX = "checkbox",
  DATE = "date",
}

export interface IMetadataField {
  name: string
  title: string
  input_type: MetadataInputType
  cols: number | null
}

export interface IMetadataConfig {
  fields: IMetadataField[]
}
