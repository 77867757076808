import React, { useCallback, useMemo, useState } from "react"
import { type UseFormReturn } from "react-hook-form/dist/types"
import { Grid } from "@mui/material"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import FhMuiHiddenField from "../../../../../shared/components/forms/FhMuiHiddenField"
import FhMuiTextField from "../../../../../shared/components/forms/FhMuiTextField"
import FhMuiRichTextField from "../../../../../shared/components/forms/FhMuiRichTextField"
import { type IListItem } from "../../../../../shared/models/components/IListItem"
import { requiredRule } from "../../../../../shared/utilities/form_utility"
import SelectFilteredSingle, {
  useSelectFilteredSingle,
} from "../../../../../shared/components/forms/SelectFilteredSingle"
import useLoadFormData from "../../../../../shared/hooks/useLoadFormData"
import PaperLocal from "../../../../../shared/components/containers/PaperLocal"
import ItemViewerDrawer from "../../../../../shared/components/item_viewer/ItemViewerDrawer"
import { ItemPrefixes } from "../../../../../config/config"
import {
  type IReportFormat,
  type IReportWriterFormat,
  REPORT_FORMAT_ENDPOINT, REPORT_WRITER_FORMAT_ENDPOINT
} from "../../../../../shared/models/service/IReportFormat"
import { type ISummary } from "../../../../../shared/models/service/ISummary"
import ReportWriterFormatInfo from "../../../../../shared/pages/report_format/components/ReportWriterFormatInfo"
import { useAxiosRequest } from "../../../../../shared/hooks/useAxiosRequest"
import type { ISelectItem } from "../../../../../shared/models/components/ISelectItem"
import FhMuiSelectField from "../../../../../shared/components/forms/FhMuiSelectField"
import { reportWriterFormatAsListItem } from "../../../../../shared/models/service/IInspection"

const reportFormatRepository = new RestRepository<IReportFormat | IListItem>(REPORT_FORMAT_ENDPOINT)
const reportWriterFormatRepository = new RestRepository<IReportWriterFormat | IListItem>(REPORT_WRITER_FORMAT_ENDPOINT)

interface IProps {
  form: UseFormReturn
  summary?: ISummary | undefined | null
  isEdit?: boolean
}

/**
 * Use this forms to add or edit a summary.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} returns the forms.
 */
const SummaryForm: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { summary, form, isEdit = false } = props

  const axiosRequest = useAxiosRequest()

  const [summaryFormats, setSummaryFormats] = useState<ISelectItem[]>([])
  const [reportFormat, setReportFormat] = useSelectFilteredSingle("report_format", form)
  const [reportFormat1, setReportFormat1] = useState<IReportFormat | null>(null)
  const [reportWriterFormat, setReportWriterFormat] = useSelectFilteredSingle("report_writer_format", form)

  const hasReportWriterFormat = useMemo(() => {
    return reportFormat1?.use_report_writer === true
  }, [reportFormat1, reportWriterFormat])

  useLoadFormData<ISummary>(
    (data: ISummary) => {
      form.setValue("id", data.id)
      form.setValue("name", data.name)
      form.setValue("report_format_summary_name", data.report_format_summary_name)
      form.setValue("notes", data.notes)
      setReportFormat(data.report_format)
      setReportFormat1(data.report_format)
      const reportWriterFormat2: IListItem | null = reportWriterFormatAsListItem(data.report_writer_format)
      setReportWriterFormat(reportWriterFormat2)
    },
    summary,
    isEdit,
    form.setValue,
  )

  const updateSummaryFormats = useCallback((reportWriterFormat1: IReportWriterFormat | null | undefined) => {
    const summaryFormats1 = reportWriterFormat1?.data?.summary_formats
    if (summaryFormats1 !== undefined && summaryFormats1 !== null && summaryFormats1.length > 0) {
      setSummaryFormats(
        summaryFormats1.map(sf => {
          const selectList: ISelectItem = { key: sf.name, value: sf.title }
          return selectList
        }),
      )
    }
  }, [])

  const handleChangeReportFormat = useCallback(async (reportFormat2: IListItem | null) => {
    setSummaryFormats([])
    setReportFormat(reportFormat2)
    if (reportFormat2 !== null) {
      await axiosRequest.callRequest(async () => {
        const response = await reportFormatRepository.read(reportFormat2.id)
        setReportFormat(response)
        setReportFormat1(response as IReportFormat)

        const reportWriterFormat1 = (response as IReportFormat)?.report_writer_format
        const reportWriterFormat2: IListItem | null = reportWriterFormatAsListItem(reportWriterFormat1)
        setReportWriterFormat(reportWriterFormat2)
        updateSummaryFormats(reportWriterFormat1)
      })
    }
  }, [])

  const handleReportWriterFormat = useCallback(async (reportWriterFormat2: IListItem | null) => {
    setReportWriterFormat(null)
    if (reportWriterFormat2 !== null) {
      await axiosRequest.callRequest(async () => {
        const response = await reportWriterFormatRepository.read(reportWriterFormat2.id)

        const reportWriterFormat3: IListItem | null = reportWriterFormatAsListItem(response as IReportWriterFormat)
        setReportWriterFormat(reportWriterFormat3)
        updateSummaryFormats(response as IReportWriterFormat)
      })
    }
  }, [])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PaperLocal>
              <ItemViewerDrawer
                title={"Report Format"}
                prefix={ItemPrefixes.reportFormat}
                infoView={ReportWriterFormatInfo}
              />
              {isEdit && <FhMuiHiddenField control={form.control} />}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FhMuiTextField control={form.control} name="name" rules={requiredRule()} />
                </Grid>
                <Grid item xs={12}>
                  <SelectFilteredSingle
                    name="report_format"
                    defaultValue={reportFormat}
                    repository={reportFormatRepository}
                    filters={[
                      { field: "use_report_writer", value: "true" },
                      { field: "has_summary_reports", value: "true" },
                    ]}
                    onChange={handleChangeReportFormat}
                    infoViewPrefix={ItemPrefixes.reportFormat}
                  />
                </Grid>
                {hasReportWriterFormat && (
                  <Grid item xs={12}>
                    <SelectFilteredSingle
                      name="report_writer_format"
                      defaultValue={reportWriterFormat}
                      filters={[{ field: "report_format", value: reportFormat?.id }]}
                      repository={reportWriterFormatRepository}
                      onChange={handleReportWriterFormat}
                    />
                  </Grid>
                )}
                {hasReportWriterFormat && reportWriterFormat !== null && summaryFormats.length > 0 && (
                  <Grid item xs={12}>
                    <FhMuiSelectField items={summaryFormats} control={form.control} name="report_format_summary_name" />
                  </Grid>
                )}
              </Grid>
            </PaperLocal>
          </Grid>
          <Grid item xs={12}>
            <PaperLocal>
              <FhMuiRichTextField control={form.control} name="notes" />
            </PaperLocal>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SummaryForm
