import React from "react"
import { TableCell } from "@mui/material"
import FormatNumber from "../format/FormatNumber"

interface IProps {
  children: number | null | undefined
  units: string | undefined
  twoDecimalPlaces?: boolean
  field?: string
}

/**
 * Renders a TableCell with formatted area value.
 *
 * @param {IProps} props - The component props.
 * @returns {React.ReactElement} - The rendered TableCell with formatted area value.
 */
const TableCellArea: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children, twoDecimalPlaces = false } = props

  return (
    <TableCell sx={{ textAlign: "center" }}>
      <FormatNumber
        value={children}
        prefixUnits={false}
        twoDecimalPlaces={twoDecimalPlaces}
      />
    </TableCell>
  )
}

export default TableCellArea
