import React from "react"
import LoginLayout from "../../../shared/layouts/LoginLayout"
import LoginPage from "../../../shared/pages/auth/LoginPage"
import PasswordResetPage from "../../../shared/pages/auth/PasswordResetPage"
import {
  ACCOUNT_ADD_URL,
  ACCOUNT_EDIT_URL,
  ACCOUNT_INDEX_URL,
  ACCOUNT_VIEW_URL,
  BUILDING_ADD_URL,
  BUILDING_EDIT_URL,
  BUILDING_VIEW_URL,
  CONTACT_ADD_URL,
  CONTACT_EDIT_URL,
  CONTACT_INDEX_URL,
  CONTACT_VIEW_URL,
  DASHBOARD_URL,
  FILE_TAG_ADD_URL,
  FILE_TAG_EDIT_URL,
  FILE_TAG_INDEX_URL,
  FILE_TAG_VIEW_URL,
  INSPECTION_ADD_URL,
  INSPECTION_BUILDING_EDIT_URL,
  INSPECTION_BUILDING_VIEW_URL,
  INSPECTION_EDIT_URL,
  INSPECTION_INDEX_URL,
  INSPECTION_RECOMMENDATION_ADD_URL,
  INSPECTION_RECOMMENDATION_EDIT_URL,
  INSPECTION_RECOMMENDATION_INDEX_URL,
  INSPECTION_RECOMMENDATION_VIEW_URL,
  INSPECTION_VIEW_URL,
  LOCATION_ADD_URL,
  LOCATION_EDIT_URL,
  LOCATION_IMPAIRMENT_ADD_URL,
  LOCATION_IMPAIRMENT_EDIT_URL,
  LOCATION_IMPAIRMENT_INDEX_URL,
  LOCATION_IMPAIRMENT_VIEW_URL,
  LOCATION_INDEX_URL,
  MAP_URL,
  LOCATION_VIEW_URL,
  LOGIN_URL,
  OCCUPANCY_ADD_URL,
  OCCUPANCY_EDIT_URL,
  OCCUPANCY_INDEX_URL,
  OCCUPANCY_VIEW_URL,
  PASSWORD_RESET_URL,
  POLICY_ADD_URL,
  POLICY_EDIT_URL,
  POLICY_INDEX_URL,
  POLICY_VIEW_URL,
  PROFILE_EDIT_URL,
  PROFILE_URL,
  RECOMMENDATION_ADD_URL,
  RECOMMENDATION_EDIT_URL,
  RECOMMENDATION_INDEX_URL,
  RECOMMENDATION_VIEW_URL,
  REPORT_FORMAT_INDEX_URL,
  REPORT_FORMAT_VIEW_URL,
  REST_API_KEY_ADD_URL,
  REST_API_KEY_EDIT_URL,
  REST_API_KEY_INDEX_URL,
  REST_API_KEY_VIEW_URL,
  SERVICE_USER_ADD_URL,
  SERVICE_USER_EDIT_URL,
  SERVICE_USER_INDEX_URL,
  SERVICE_USER_VIEW_URL,
  SETTING_EDIT_URL,
  SETTING_VIEW_URL,
  SUMMARY_ADD_URL,
  SUMMARY_EDIT_URL,
  SUMMARY_INDEX_URL,
  SUMMARY_VIEW_URL,
  VENDOR_ADD_URL,
  VENDOR_EDIT_URL,
  VENDOR_INDEX_URL,
  VENDOR_VIEW_URL,
  EMAIL_TEMPLATE_INDEX_URL,
  EMAIL_TEMPLATE_ADD_URL,
  EMAIL_TEMPLATE_VIEW_URL,
  EMAIL_TEMPLATE_EDIT_URL,
  IMPORT_URL
} from "./urls"
import DashboardIndexPage from "../pages/dashboard/IndexPage"
import ImportIndexPage from "../pages/import/IndexPage"
import SettingIndexPage from "../pages/settings/IndexPage"
import SettingEditPage from "../pages/settings/EditPage"
import ProfileIndexPage from "../../../shared/pages/profile/IndexPage"
import AboutIndexPage from "../../../shared/pages/about/IndexPage"
import ProfileEditPage from "../../../shared/pages/profile/EditPage"
import AccountIndexPage from "../pages/accounts/IndexPage"
import AccountAddPage from "../pages/accounts/AddPage"
import AccountViewPage from "../pages/accounts/ViewPage"
import AccountEditPage from "../pages/accounts/EditPage"
import SummaryIndexPage from "../pages/summaries/IndexPage"
import SummaryAddPage from "../pages/summaries/AddPage"
import SummaryEditPage from "../pages/summaries/EditPage"
import SummaryViewPage from "../pages/summaries/ViewPage"
import BuildingAddPage from "../pages/buildings/AddPage"
import BuildingViewPage from "../pages/buildings/ViewPage"
import BuildingEditPage from "../pages/buildings/EditPage"
import PolicyIndexPage from "../pages/policies/IndexPage"
import PolicyViewPage from "../pages/policies/ViewPage"
import PolicyAddPage from "../pages/policies/AddPage"
import PolicyEditPage from "../pages/policies/EditPage"
import ContactIndexPage from "../pages/contacts/IndexPage"
import ContactAddPage from "../pages/contacts/AddPage"
import ContactViewPage from "../pages/contacts/ViewPage"
import ContactEditPage from "../pages/contacts/EditPage"
import FileTagIndexPage from "../pages/file_tags/IndexPage"
import FileTagAddPage from "../pages/file_tags/AddPage"
import FileTagViewPage from "../pages/file_tags/ViewPage"
import FileTagEditPage from "../pages/file_tags/EditPage"
import EmailTemplateIndexPage from "../pages/email_templates/IndexPage"
import EmailTemplateAddPage from "../pages/email_templates/AddPage"
import EmailTemplateViewPage from "../pages/email_templates/ViewPage"
import EmailTemplateEditPage from "../pages/email_templates/EditPage"
import OccupancyIndexPage from "../pages/occupancies/IndexPage"
import OccupancyAddPage from "../pages/occupancies/AddPage"
import OccupancyViewPage from "../pages/occupancies/ViewPage"
import OccupancyEditPage from "../pages/occupancies/EditPage"
import RecommendationLibraryIndexPage from "../pages/recommendations/IndexPage"
import RecommendationLibraryAddPage from "../pages/recommendations/AddPage"
import RecommendationLibraryViewPage from "../pages/recommendations/ViewPage"
import RecommendationLibraryEditPage from "../pages/recommendations/EditPage"
import ServiceUserIndexPage from "../pages/service_users/IndexPage"
import ServiceUserAddPage from "../pages/service_users/AddPage"
import ServiceUserViewPage from "../pages/service_users/ViewPage"
import ServiceUserEditPage from "../pages/service_users/EditPage"
import VendorIndexPage from "../pages/vendors/IndexPage"
import VendorAddPage from "../pages/vendors/AddPage"
import VendorViewPage from "../pages/vendors/ViewPage"
import VendorEditPage from "../pages/vendors/EditPage"
import LocationIndexPage from "../pages/locations/IndexPage"
import LocationMapPage from "../pages/map/MapPage"
import LocationViewPage from "../pages/locations/ViewPage"
import LocationEditPage from "../pages/locations/EditPage"
import LocationAddPage from "../pages/locations/AddPage"
import InspectionIndexPage from "../pages/inspections/IndexPage"
import InspectionViewPage from "../pages/inspections/ViewPage"
import InspectionEditPage from "../pages/inspections/EditPage"
import InspectionAddPage from "../pages/inspections/AddPage"
import ImpairmentIndexPage from "../../../shared/pages/impairments/IndexPage"
import ImpairmentViewPage from "../../../shared/pages/impairments/ViewPage"
import ImpairmentEditPage from "../../../shared/pages/impairments/EditPage"
import ImpairmentAddPage from "../../../shared/pages/impairments/AddPage"
import ReportFormatIndexPage from "../pages/report_formats/IndexPage"
import ReportFormatViewPage from "../pages/report_formats/ViewPage"
import RestApiKeyIndexPage from "../pages/rest_api_keys/IndexPage"
import RestApiKeyAddPage from "../pages/rest_api_keys/AddPage"
import RestApiKeyViewPage from "../pages/rest_api_keys/ViewPage"
import RestApiKeyEditPage from "../pages/rest_api_keys/EditPage"
import InspectionRecommendationIndexPage from "../pages/inspection_recommendations/IndexPage"
import InspectionRecommendationAddPage from "../pages/inspection_recommendations/AddPage"
import InspectionRecommendationViewPage from "../pages/inspection_recommendations/ViewPage"
import InspectionRecommendationEditPage from "../pages/inspection_recommendations/EditPage"
import InspectionBuildingViewPage from "../../../shared/components/inspection_buildings/ViewPage"
import InspectionBuildingEditPage from "../../../shared/components/inspection_buildings/EditPage"
import NotFound from "../../../shared/pages/misc/404"
import { addRoute, routesMerge } from "../../../shared/utilities/routing_utility"
import { createBrowserRouter, type RouteObject } from "react-router-dom"
import type { Router as RemixRouter } from "@remix-run/router/dist/router"
import DefaultLayout from "../../../shared/layouts/DefaultLayout"
import type { ISideNav } from "../../../shared/components/SideNavMenu"
import { ABOUT_URL } from "../../recs/config/urls"

/**
 * Generates route configuration objects for the application based on the provided side menu structure.
 *
 * @param {Array<ISideNav | null | boolean>} sideMenu - The side menu configuration which determines the routes to be included.
 * @returns {RouteObject[]} An array of route objects configured for the application.
 */
const routes: RouteObject[] = [
  {
    path: "/",
    element: <LoginLayout title="Login" component={LoginPage} />,
  },
  {
    path: LOGIN_URL,
    element: <LoginLayout title="Login" component={LoginPage} />,
  },
  {
    path: `${PASSWORD_RESET_URL}/*`,
    element: <LoginLayout title="Password Reset" component={PasswordResetPage} />,
  },
  addRoute({ path: DASHBOARD_URL, title: "Dashboard", page: DashboardIndexPage, layout: DefaultLayout }),
  addRoute({ path: `${IMPORT_URL}/*`, title: "Import", page: ImportIndexPage, layout: DefaultLayout }),
  addRoute({
    path: `${SETTING_EDIT_URL}/:id/*`,
    title: "Settings Edit",
    page: SettingEditPage,
    layout: DefaultLayout,
  }),
  addRoute({ path: `${SETTING_VIEW_URL}/*`, title: "Settings", page: SettingIndexPage, layout: DefaultLayout }),
  addRoute({ path: `${PROFILE_URL}/*`, title: "Profile", page: ProfileIndexPage, layout: DefaultLayout }),
  addRoute({ path: `${ABOUT_URL}/*`, title: "About", page: AboutIndexPage, layout: DefaultLayout }),
  addRoute({
    path: `${PROFILE_EDIT_URL}/:id`,
    title: "Profile Edit",
    page: ProfileEditPage,
    layout: DefaultLayout,
  }),
  addRoute({ path: ACCOUNT_INDEX_URL, title: "Accounts", page: AccountIndexPage, layout: DefaultLayout }),
  addRoute({ path: ACCOUNT_ADD_URL, title: "Account Add", page: AccountAddPage, layout: DefaultLayout }),
  addRoute({
    path: `${ACCOUNT_VIEW_URL}/:id/*`,
    title: "Account",
    page: AccountViewPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${ACCOUNT_EDIT_URL}/:id/*`,
    title: "Account Edit",
    page: AccountEditPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: SUMMARY_INDEX_URL,
    title: "Summaries",
    page: SummaryIndexPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${SUMMARY_VIEW_URL}/:id/*`,
    title: "Summary",
    page: SummaryViewPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${SUMMARY_ADD_URL}/*`,
    title: "Summary Add",
    page: SummaryAddPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${SUMMARY_EDIT_URL}/:id/*`,
    title: "Summary Edit",
    page: SummaryEditPage,
    layout: DefaultLayout,
  }),
  addRoute({ path: BUILDING_ADD_URL, title: "Building Add", page: BuildingAddPage, layout: DefaultLayout }),
  addRoute({
    path: `${BUILDING_VIEW_URL}/:id/*`,
    title: "Building",
    page: BuildingViewPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${BUILDING_EDIT_URL}/:id`,
    title: "Building Edit",
    page: BuildingEditPage,
    layout: DefaultLayout,
  }),
  addRoute({ path: POLICY_INDEX_URL, title: "Policies", page: PolicyIndexPage, layout: DefaultLayout }),
  addRoute({
    path: `${POLICY_VIEW_URL}/:id/*`,
    title: "Policy",
    page: PolicyViewPage,
    layout: DefaultLayout,
  }),
  addRoute({ path: POLICY_ADD_URL, title: "Policy Add", page: PolicyAddPage, layout: DefaultLayout }),
  addRoute({
    path: `${POLICY_EDIT_URL}/:id`,
    title: "Policy Edit",
    page: PolicyEditPage,
    layout: DefaultLayout,
  }),
  addRoute({ path: CONTACT_INDEX_URL, title: "Contacts", page: ContactIndexPage, layout: DefaultLayout }),
  addRoute({ path: CONTACT_ADD_URL, title: "Contact Add", page: ContactAddPage, layout: DefaultLayout }),
  addRoute({
    path: `${CONTACT_VIEW_URL}/:id/*`,
    title: "Contact",
    page: ContactViewPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${CONTACT_EDIT_URL}/:id`,
    title: "Contact Edit",
    page: ContactEditPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: FILE_TAG_INDEX_URL,
    title: "File Tags",
    page: FileTagIndexPage,
    layout: DefaultLayout,
  }),
  addRoute({ path: FILE_TAG_ADD_URL, title: "File Tag Add", page: FileTagAddPage, layout: DefaultLayout }),
  addRoute({
    path: `${FILE_TAG_VIEW_URL}/:id`,
    title: "File Tag",
    page: FileTagViewPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${FILE_TAG_EDIT_URL}/:id`,
    title: "File Tag Edit",
    page: FileTagEditPage,
    layout: DefaultLayout,
  }),

  addRoute({
    path: EMAIL_TEMPLATE_INDEX_URL,
    title: "Email Templates",
    page: EmailTemplateIndexPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: EMAIL_TEMPLATE_ADD_URL,
    title: "Email Template Add",
    page: EmailTemplateAddPage,
    layout: DefaultLayout,
  }),

  addRoute({
    path: `${EMAIL_TEMPLATE_VIEW_URL}/:id/*`,
    title: "Email Template",
    page: EmailTemplateViewPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${EMAIL_TEMPLATE_EDIT_URL}/:id`,
    title: "Email Template Edit",
    page: EmailTemplateEditPage,
    layout: DefaultLayout,
  }),

  addRoute({
    path: OCCUPANCY_INDEX_URL,
    title: "Occupancies",
    page: OccupancyIndexPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: OCCUPANCY_ADD_URL,
    title: "Occupancy Add",
    page: OccupancyAddPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${OCCUPANCY_VIEW_URL}/:id`,
    title: "Occupancy",
    page: OccupancyViewPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${OCCUPANCY_EDIT_URL}/:id`,
    title: "Occupancy Edit",
    page: OccupancyEditPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: RECOMMENDATION_INDEX_URL,
    title: "Recommendations",
    page: RecommendationLibraryIndexPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: RECOMMENDATION_ADD_URL,
    title: "Recommendation Add",
    page: RecommendationLibraryAddPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${RECOMMENDATION_VIEW_URL}/:id/*`,
    title: "Recommendation",
    page: RecommendationLibraryViewPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${RECOMMENDATION_EDIT_URL}/:id`,
    title: "Recommendation Edit",
    page: RecommendationLibraryEditPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: SERVICE_USER_INDEX_URL,
    title: "Service Users",
    page: ServiceUserIndexPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: SERVICE_USER_ADD_URL,
    title: "Service User Add",
    page: ServiceUserAddPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${SERVICE_USER_VIEW_URL}/:id/*`,
    title: "Service User",
    page: ServiceUserViewPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${SERVICE_USER_EDIT_URL}/:id`,
    title: "Service User Edit",
    page: ServiceUserEditPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: VENDOR_INDEX_URL,
    title: "Vendors",
    page: VendorIndexPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: VENDOR_ADD_URL,
    title: "Vendor Add",
    page: VendorAddPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${VENDOR_VIEW_URL}/:id/*`,
    title: "Vendor",
    page: VendorViewPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${VENDOR_EDIT_URL}/:id`,
    title: "Vendor Edit",
    page: VendorEditPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: LOCATION_INDEX_URL,
    title: "Locations",
    page: LocationIndexPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${MAP_URL}/*`,
    title: "Map",
    page: LocationMapPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${LOCATION_VIEW_URL}/:id/*`,
    title: "Location",
    page: LocationViewPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${LOCATION_EDIT_URL}/:id/*`,
    title: "Location Edit",
    page: LocationEditPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: LOCATION_ADD_URL,
    title: "Location Add",
    page: LocationAddPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${INSPECTION_VIEW_URL}/:id/*`,
    title: "Inspection",
    page: InspectionViewPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${INSPECTION_EDIT_URL}/:id/*`,
    title: "Inspection Edit",
    page: InspectionEditPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: INSPECTION_ADD_URL,
    title: "Inspection Add",
    page: InspectionAddPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${INSPECTION_INDEX_URL}/*`,
    title: "Inspections",
    page: InspectionIndexPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${LOCATION_IMPAIRMENT_VIEW_URL}/:id/*`,
    title: "Impairment",
    page: ImpairmentViewPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${LOCATION_IMPAIRMENT_EDIT_URL}/:id`,
    title: "Impairment Edit",
    page: ImpairmentEditPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: LOCATION_IMPAIRMENT_ADD_URL,
    title: "Impairment Add",
    page: ImpairmentAddPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${LOCATION_IMPAIRMENT_INDEX_URL}/*`,
    title: "Impairments",
    page: ImpairmentIndexPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: INSPECTION_RECOMMENDATION_ADD_URL,
    title: "Recommendation Add",
    page: InspectionRecommendationAddPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${INSPECTION_RECOMMENDATION_VIEW_URL}/:id/*`,
    title: "Recommendation",
    page: InspectionRecommendationViewPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${INSPECTION_RECOMMENDATION_EDIT_URL}/:id/*`,
    title: "Recommendation",
    page: InspectionRecommendationEditPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${INSPECTION_RECOMMENDATION_INDEX_URL}/*`,
    title: "Recommendations",
    page: InspectionRecommendationIndexPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${INSPECTION_BUILDING_VIEW_URL}/:id/*`,
    title: "Inspection Building",
    page: InspectionBuildingViewPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${INSPECTION_BUILDING_EDIT_URL}/:id/*`,
    title: "Inspection Building Edit",
    page: InspectionBuildingEditPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: REPORT_FORMAT_INDEX_URL,
    title: "Report Formats",
    page: ReportFormatIndexPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${REPORT_FORMAT_VIEW_URL}/:id/*`,
    title: "Report Format",
    page: ReportFormatViewPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: REST_API_KEY_INDEX_URL,
    title: "API Keys",
    page: RestApiKeyIndexPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: REST_API_KEY_ADD_URL,
    title: "API Key Add",
    page: RestApiKeyAddPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${REST_API_KEY_VIEW_URL}/:id`,
    title: "API Key",
    page: RestApiKeyViewPage,
    layout: DefaultLayout,
  }),
  addRoute({
    path: `${REST_API_KEY_EDIT_URL}/:id`,
    title: "API Key Edit",
    page: RestApiKeyEditPage,
    layout: DefaultLayout,
  }),
  addRoute({ path: "*", title: "Not Found", page: NotFound, layout: DefaultLayout }),
]

interface ITheRouter {
  basename?: string
  addonRoutes?: RouteObject[]
}

/**
 * Initializes and returns a RemixRouter instance based on provided properties.
 *
 * @param {ITheRouter} props - An object containing configuration properties for the router.
 * @param {string} props.basename - The base URL for all locations.
 * @param {Array} [props.addonRoutes] - Additional routes to be merged with the default routes.
 *
 * @returns {RemixRouter} - The configured RemixRouter instance.
 */
export const theRouter = (props: ITheRouter): RemixRouter => {
  const { basename, addonRoutes } = props
  if (addonRoutes !== undefined) {
    return createBrowserRouter(routesMerge(routes, addonRoutes), { basename })
  }
  return createBrowserRouter(routes, { basename })
}
