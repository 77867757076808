import React, { type ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Box, FormControlLabel, Grid, MenuItem, TextField, useTheme } from "@mui/material"
import { type IRiskWriterTable } from "../../../models/IRiskWriterTable"
import { nameToLabel } from "../../../../../utilities/form_utility"
import {
  findDisplayAggregateByString,
  findDisplayTypeByString,
  getDisplayTypeByValue,
  type IRiskWriterSectionData,
  type ISectionDataTable,
  SectionDataTableDisplayAggregate,
  SectionDataTableDisplayType,
  SectionType,
} from "../../../models/IRiskWriterSectionData"
import type { IComboItem } from "../../../../../models/components/IComboItem"
import Checkbox from "@mui/material/Checkbox"
import { RiskWriterContext } from "../../../context/RiskWriterProvider"
import RiskWriterTableFilters from "../../RiskWriterTableFilters"
import type { ITableFilter } from "../../../utilities/table_utilities"

interface IProps {
  content: IRiskWriterSectionData
  tableFormat: IRiskWriterTable
  displayType: SectionDataTableDisplayType
  sectionDataTable: ISectionDataTable
  onChange: (sectionDataTable: ISectionDataTable) => void
}

/**
 * ChartEditor Component
 *
 * A React functional component that renders a chart and a section for table options.
 * ResponsiveContainer and BarChart from Recharts for rendering a bar chart, and Typography for
 * displaying text.
 *
 * @param {IProps} props - The properties passed to the component
 * @returns {React.ReactElement} The rendered ChartEditor component
 */
const ChartEditor: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { content, displayType, tableFormat, sectionDataTable, onChange } = props

  const theme = useTheme()

  const { onChangeContent } = useContext(RiskWriterContext)
  const [limit, setLimit] = useState<number>(20)
  const [title, setTitle] = useState<string>("")
  const [color, setColor] = useState<string>(theme.palette.info.main)
  const [icon, setIcon] = useState<string>("")

  const displayAggregateComboItems: IComboItem[] = useMemo(() => {
    return Object.entries(SectionDataTableDisplayAggregate).map(([key, type]) => {
      return { value: key, label: type }
    })
  }, [])

  const handleSortChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      onChange({ ...sectionDataTable, sort_column: event.target.value })
    },
    [sectionDataTable],
  )

  const handleLabelChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      onChange({ ...sectionDataTable, label_column: event.target.value })
    },
    [sectionDataTable],
  )

  const handleDataChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      onChange({ ...sectionDataTable, data_column: event.target.value })
    },
    [sectionDataTable],
  )

  const handleAggregateChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      const aggregate = findDisplayAggregateByString(event.target.value)
      onChange({ ...sectionDataTable, aggregate })
    },
    [sectionDataTable],
  )

  const handleTitleChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    setTitle(event.target.value)
  }, [])

  const handleTitleBlur = useCallback((): void => {
    const sectionContent: ISectionDataTable = { ...sectionDataTable, title }
    onChangeContent?.(content.name, sectionContent, false)
  }, [sectionDataTable, title])

  const handleColorChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    setColor(event.target.value)
  }, [])

  const handleColorBlur = useCallback((): void => {
    const sectionContent: ISectionDataTable = { ...sectionDataTable, color }
    onChangeContent?.(content.name, sectionContent, false)
  }, [sectionDataTable, color])

  const handleIconChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    setIcon(event.target.value)
  }, [])

  const handleIconBlur = useCallback((): void => {
    const sectionContent: ISectionDataTable = { ...sectionDataTable, icon }
    onChangeContent?.(content.name, sectionContent, false)
  }, [sectionDataTable, icon])

  const handleCheckboxChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      onChange({ ...sectionDataTable, [event.target.name]: event.target.checked })
    },
    [sectionDataTable],
  )

  const handleLimitChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const limit1 = Number(event.target.value)
      setLimit(limit1)
      const sectionContent: ISectionDataTable = { ...sectionDataTable, limit: limit1 }
      onChangeContent?.(content.name, sectionContent, false)
    },
    [content],
  )

  const tableFilters: ITableFilter[] = useMemo(() => {
    if (sectionDataTable !== undefined) {
      const currentTableFilters = sectionDataTable?.table_filters
      if (currentTableFilters !== undefined && currentTableFilters !== null) {
        return currentTableFilters
      }
    }
    return []
  }, [content?.data])

  const handleTableFilterChange = useCallback(
    (updatedTableFilters: ITableFilter[]) => {
      const sectionContent: ISectionDataTable = {
        ...sectionDataTable,
        table_filters: updatedTableFilters,
      }
      onChangeContent?.(content.name, sectionContent, false)
    },
    [content],
  )

  const handleDisplayTypeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const displayType = findDisplayTypeByString(event.target.value)
      if (displayType !== undefined) {
        const sectionContent: ISectionDataTable = {
          ...sectionDataTable,
          display_type: displayType,
        }
        onChangeContent?.(content.name, sectionContent, false)
      }
    },
    [content],
  )

  const displayTypeComboItems: IComboItem[] = useMemo(() => {
    return Object.entries(SectionDataTableDisplayType).map(([key, type]) => {
      return { value: key, label: type }
    })
  }, [])

  useEffect(() => {
    if (content.section_type === SectionType.TABLE_EDITOR) {
      setLimit(sectionDataTable?.limit ?? 20)
      setTitle(sectionDataTable?.title ?? "")
      setColor(sectionDataTable?.color ?? theme.palette.info.main)
      setIcon(sectionDataTable?.icon ?? "")
    }
  }, [content])

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} lg={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="show_title"
                    checked={sectionDataTable?.show_title ?? true}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Show Title"
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <RiskWriterTableFilters
                columns={tableFormat?.columns}
                currentFilters={tableFilters}
                onChange={handleTableFilterChange}
              />
            </Grid>
            {sectionDataTable?.show_title === true && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Title"
                  value={title}
                  onChange={handleTitleChange}
                  onBlur={handleTitleBlur}
                />
              </Grid>
            )}
            <Grid item xs={12} lg={6}>
              <TextField
                fullWidth
                variant="outlined"
                label="Display"
                select
                value={getDisplayTypeByValue(displayType)}
                onChange={handleDisplayTypeChange}>
                {displayTypeComboItems.map(item => {
                  return (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  )
                })}
              </TextField>
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField fullWidth label="Limit" value={limit} onChange={handleLimitChange} />
            </Grid>
            {displayType === SectionDataTableDisplayType.SingleValue && (
              <>
                <Grid item xs={12} lg={6}>
                  <TextField
                    fullWidth
                    type="color"
                    variant="outlined"
                    label="Color"
                    value={color}
                    onChange={handleColorChange}
                    onBlur={handleColorBlur}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    fullWidth
                    type="icon"
                    variant="outlined"
                    label="Icon"
                    value={icon}
                    onChange={handleIconChange}
                    onBlur={handleIconBlur}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={2} alignItems="center">
            {displayType === SectionDataTableDisplayType.Table && (
              <>
                <Grid item xs={12} lg={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="sort_asc"
                        checked={sectionDataTable?.sort_asc ?? true}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label="Sort Ascending"
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    label="Sort Column"
                    value={sectionDataTable?.sort_column ?? ""}
                    onChange={handleSortChange}>
                    {tableFormat.columns.map(column => {
                      return (
                        <MenuItem key={column.name} value={column.name}>
                          {nameToLabel(column.name, column.title)}
                        </MenuItem>
                      )
                    })}
                  </TextField>
                </Grid>
              </>
            )}

            {displayType !== SectionDataTableDisplayType.Table && (
              <>
                <Grid item xs={12} lg={6}>
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    label="Label Column"
                    value={sectionDataTable?.label_column ?? ""}
                    onChange={handleLabelChange}>
                    {tableFormat.columns.map(column => {
                      return (
                        <MenuItem key={column.name} value={column.name}>
                          {nameToLabel(column.name, column.title)}
                        </MenuItem>
                      )
                    })}
                  </TextField>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    select
                    fullWidth
                    label="Data Column"
                    variant="outlined"
                    value={sectionDataTable?.data_column ?? ""}
                    onChange={handleDataChange}>
                    {tableFormat.columns.map(column => {
                      return (
                        <MenuItem key={column.name} value={column.name}>
                          {nameToLabel(column.name, column.title)}
                        </MenuItem>
                      )
                    })}
                  </TextField>
                </Grid>
              </>
            )}

            {displayType === SectionDataTableDisplayType.SingleValue && (
              <Grid item xs={12} lg={6}>
                <TextField
                  select
                  fullWidth
                  label="Aggregate"
                  variant="outlined"
                  value={sectionDataTable?.aggregate}
                  onChange={handleAggregateChange}>
                  {displayAggregateComboItems.map(item => {
                    return (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ChartEditor
