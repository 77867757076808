import React, { useCallback, useState } from "react"
import { type UseFormReturn } from "react-hook-form/dist/types"
import { Grid } from "@mui/material"
import FhMuiHiddenField from "../../../../../shared/components/forms/FhMuiHiddenField"
import FhMuiTextField from "../../../../../shared/components/forms/FhMuiTextField"
import FhMuiRichTextField from "../../../../../shared/components/forms/FhMuiRichTextField"
import { type IServiceUser } from "../../../../../shared/models/service/IServiceUser"
import { requiredRule } from "../../../../../shared/utilities/form_utility"
import useLoadFormData from "../../../../../shared/hooks/useLoadFormData"
import PaperLocal from "../../../../../shared/components/containers/PaperLocal"
import FhMuiPhoneField from "../../../../../shared/components/forms/FhMuiPhoneField"
import SelectFilteredSingle, {
  useSelectFilteredSingle,
} from "../../../../../shared/components/forms/SelectFilteredSingle"
import {
  type ILocation,
  LOCATION_CITY_ENDPOINT,
  LOCATION_COUNTRY_ENDPOINT,
  LOCATION_STATE_REGION_ENDPOINT,
} from "../../../../../shared/models/service/ILocation"
import AddressSearch from "../../locations/components/AddressSearch"
import FhMap from "../../../../../shared/components/forms/FhMap"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import type { IListItem } from "../../../../../shared/models/components/IListItem"

interface IProps {
  form: UseFormReturn
  serviceUser?: IServiceUser | undefined | null
  isEdit?: boolean
}

const cityRepository = new RestRepository<IListItem>(LOCATION_CITY_ENDPOINT)
const stateRepository = new RestRepository<IListItem>(LOCATION_STATE_REGION_ENDPOINT)
const countryRepository = new RestRepository<IListItem>(LOCATION_COUNTRY_ENDPOINT)

/**
 * Use this forms to add or edit an serviceUser.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} returns the forms.
 */
const ServiceUserForm: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { serviceUser, form, isEdit = false } = props

  const [addressSearch, setAddressSearch] = useState<string>("")
  const [redraw, setRedraw] = useState<boolean>(false)
  const [city, setCity] = useSelectFilteredSingle("city", form)
  const [stateRegion, setStateRegion] = useSelectFilteredSingle("state_region", form)
  const [country, setCountry] = useSelectFilteredSingle("country", form)

  useLoadFormData<IServiceUser>(
    (data: IServiceUser) => {
      form.setValue("id", data.id)
      form.setValue("first_name", data.first_name)
      form.setValue("last_name", data.last_name)
      form.setValue("job_title", data.job_title)
      form.setValue("phone", data.phone)
      form.setValue("phone_ext", data.phone_ext)
      form.setValue("phone_2", data.phone_2)
      form.setValue("phone_2_ext", data.phone_2_ext)
      form.setValue("email", data.email)
      form.setValue("email_2", data.email_2)

      form.setValue("address", data.address ?? "")
      form.setValue("address_2", data.address_2 ?? "")
      form.setValue("postal_code", data.postal_code ?? "")
      form.setValue("longitude", data.longitude ?? "")
      form.setValue("latitude", data.latitude ?? "")
      form.setValue("zoom", data.zoom ?? 17)

      setCity(data.city !== null ? { id: data.city, name: data.city } : null)
      setStateRegion(data.state_region !== null ? { id: data.state_region, name: data.state_region } : null)
      setCountry(data.country !== null ? { id: data.country, name: data.country } : null)
      setAddressSearch(data.address)
      setRedraw(redraw => !redraw)

      form.setValue("notes", data.notes)
    },
    serviceUser,
    isEdit,
    form.setValue,
  )

  const handleAddressSearch = useCallback(() => {
    setAddressSearch(form.getValues("address") as string)
  }, [form])

  const handleFoundAddress = useCallback(
    (data: ILocation) => {
      form.setValue("address", data.address)
      form.setValue("address_2", data.address_2)
      form.setValue("postal_code", data.postal_code)
      form.setValue("longitude", data.longitude)
      form.setValue("latitude", data.latitude)
      form.setValue("metadata_data", data.metadata_data)
      setCity({ id: data.city, name: data.city })
      setStateRegion({ id: data.state_region, name: data.state_region })
      setCountry({ id: data.country, name: data.country })
      setRedraw(redraw => !redraw)
    },
    [form, redraw],
  )

  const handleRedraw = useCallback(async () => {
    setRedraw(redraw => !redraw)
  }, [redraw])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <PaperLocal>
          {isEdit && <FhMuiHiddenField control={form.control} />}
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <FhMuiTextField control={form.control} name="first_name" rules={requiredRule()} />
            </Grid>
            <Grid item xs={12} lg={6}>
              <FhMuiTextField control={form.control} name="last_name" />
            </Grid>
            <Grid item xs={12}>
              <FhMuiTextField control={form.control} name="job_title" />
            </Grid>
            <Grid item xs={12}>
              <FhMuiTextField control={form.control} name="email" rules={requiredRule()} />
            </Grid>
            <Grid item xs={12}>
              <FhMuiTextField control={form.control} name="email_2" />
            </Grid>
            <Grid item xs={12} lg>
              <FhMuiPhoneField control={form.control} name="phone" />
            </Grid>
            <Grid item>
              <FhMuiTextField control={form.control} name="phone_ext" />
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12} lg>
              <FhMuiPhoneField control={form.control} name="phone_2" />
            </Grid>
            <Grid item>
              <FhMuiTextField control={form.control} name="phone_2_ext" />
            </Grid>
          </Grid>
        </PaperLocal>
        <PaperLocal sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FhMuiRichTextField control={form.control} name="notes" />
            </Grid>
          </Grid>
        </PaperLocal>
      </Grid>
      <Grid item xs={12} lg={6}>
        <PaperLocal>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <FhMuiTextField control={form.control} name="address" onChange={handleAddressSearch} />
            </Grid>
            <Grid item>
              <AddressSearch address={addressSearch} onFound={handleFoundAddress} />
            </Grid>
            <Grid item xs={12}>
              <FhMuiTextField control={form.control} name="address_2" />
            </Grid>
            <Grid item xs={12} lg={8}>
              <SelectFilteredSingle
                freeSolo
                showId={false}
                name="city"
                defaultValue={city}
                repository={cityRepository}
                onChange={setCity}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FhMuiTextField control={form.control} name="postal_code" />
            </Grid>
            <Grid item xs={12} lg={6}>
              <SelectFilteredSingle
                freeSolo
                showId={false}
                name="state_region"
                defaultValue={stateRegion}
                repository={stateRepository}
                onChange={setStateRegion}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <SelectFilteredSingle
                freeSolo
                showId={false}
                name="country"
                defaultValue={country}
                repository={countryRepository}
                onChange={setCountry}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FhMuiTextField control={form.control} name="zoom" />
            </Grid>
            <Grid item xs={12} md={4}>
              <FhMuiTextField control={form.control} name="latitude" onChange={handleRedraw} />
            </Grid>
            <Grid item xs={12} md={4}>
              <FhMuiTextField control={form.control} name="longitude" onChange={handleRedraw} />
            </Grid>
            <Grid item xs={12}>
              <FhMap form={form} redraw={redraw} />
            </Grid>
          </Grid>
        </PaperLocal>
      </Grid>
    </Grid>
  )
}

export default ServiceUserForm
