import React from 'react'
import { Grid, List, ListItem, ListItemText, Typography } from "@mui/material"
import ViewProperty from "../ViewProperty"
import { nameToLabel } from "../../utilities/form_utility"
import { type IFilterBookmark } from "../../models/service/IFilterBookmark"


interface IProps {
  bookmark: IFilterBookmark
}


/**
 * A React component that displays bookmark details including notes, sorting, limit, and filters.
 *
 * @param {object} props - The properties object.
 * @param {IProps} props.bookmark - The bookmark data to be displayed, containing notes, paging, and filters.
 * @returns {React.ReactElement} The rendered React component displaying the bookmark details.
 */
const ViewFilterBookmark: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { bookmark } = props

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography component="p">{bookmark.notes}</Typography>
      </Grid>
      <Grid item xs={12} lg={6}>
        <ViewProperty label={"Sort"}>
          {bookmark.paging.ordering !== undefined
            ? `${nameToLabel(bookmark.paging.ordering.field)} - ${bookmark.paging.ordering.direction}`
            : "Default"}
        </ViewProperty>
      </Grid>
      <Grid item xs={12} lg={6}>
        <ViewProperty label={"Limit"}>{bookmark.paging.limit ?? "Default"}</ViewProperty>
      </Grid>
      <Grid item xs={12}>
        <ViewProperty label={"Filters"}>
          <List component="div" disablePadding dense>
            {bookmark.paging.filters?.map((filter1, fIndex) => {
              return (
                <ListItem sx={{ mt: 0, pt: 0 }} key={fIndex}>
                  <ListItemText
                    sx={{ pl: 4 }}
                    primary={`${filter1.title} ${filter1.display}`}
                  />
                </ListItem>
              )
            })}
          </List>
        </ViewProperty>
      </Grid>
    </Grid>
  )
}


export default ViewFilterBookmark