import React, { useCallback, useContext, useState } from "react"
import { type IInspection } from "../../../../../shared/models/service/IInspection"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material"
import DialogControls from "../../../../../shared/components/DialogControls"
import { useTranslation } from "react-i18next"
import FormBox from "../../../../../shared/components/forms/FormBox"
import { useForm } from "react-hook-form"
import {
  type IInspectionMessage,
  INSPECTION_MESSAGE_ENDPOINT
} from "../../../../../shared/models/service/IInspectionMessage"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import { CONTACT_ENDPOINT, type IContact } from "../../../../../shared/models/service/IContact"
import type { IListItem } from "../../../../../shared/models/components/IListItem"
import SelectFilteredMultiple, {
  useSelectFilteredMultiple,
} from "../../../../../shared/components/forms/SelectFilteredMultiple"
import { ItemPrefixes } from "../../../../../config/config"
import FhMuiRichTextField from "../../../../../shared/components/forms/FhMuiRichTextField"
import FhMuiTextField from "../../../../../shared/components/forms/FhMuiTextField"
import { type ISetting, SETTING_ENDPOINT } from "../../../../../shared/models/service/ISetting"
import { useAxiosRequest } from "../../../../../shared/hooks/useAxiosRequest"
import type { IPanariskAppContext } from "../../../../../shared/models/app/IPanariskAppContext"
import { PanariskAppContext } from "../../../../../app/PanariskApp"
import { EMAIL_TEMPLATE_ENDPOINT, type IEmailTemplate } from "../../../../../shared/models/service/IEmailTemplate"
import DrawerRight from "../../../../../shared/components/containers/DrawerRight"
import SelectFilteredSingle from "../../../../../shared/components/forms/SelectFilteredSingle"
import EmailTemplateInfo from "../../email_templates/components/EmailTemplateInfo"
import useApiAdd, { type IUseApiAddProps } from "../../../../../shared/hooks/useApiAdd"

const repository = new RestRepository<IInspectionMessage>(INSPECTION_MESSAGE_ENDPOINT)
const contactRepository = new RestRepository<IContact | IListItem>(CONTACT_ENDPOINT)
const settingsRepository = new RestRepository<ISetting>(SETTING_ENDPOINT)
const emailTemplateRepository = new RestRepository<IEmailTemplate | IListItem>(EMAIL_TEMPLATE_ENDPOINT)

interface IProps {
  inspection: IInspection
}

/**
 * A component that provides functionality to share an inspection by email.
 *
 * @param {IProps} props - The properties object containing data related to the inspection.
 * @returns {React.ReactElement} A React element consisting of a button to open a dialog and the dialog interface for
 * sending inspection-related emails.
 */
const InspectionShare: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { inspection } = props
  const form = useForm()

  const propsApiAdd: IUseApiAddProps<IInspectionMessage> = { apiFunction: repository.add, setError: form.setError, redirect: false }
  const apiAdd = useApiAdd<IInspectionMessage>(propsApiAdd)

  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [emailTemplateListItem, setEmailTemplateListItem] = useState<IListItem | null>(null)
  const [contacts, setContacts] = useSelectFilteredMultiple("to_contacts", form)
  const { appSettings } = useContext<IPanariskAppContext>(PanariskAppContext)
  const settingId = appSettings.serverInfo?.setting_id

  const axiosRequest = useAxiosRequest()

  const handleOpen = useCallback(async () => {
    setOpen(true)
    if (settingId !== undefined) {
      await axiosRequest.callRequest(async () => {
        const setting = await settingsRepository.read(settingId)
        const templateId = setting.email_template_inspection_share?.id
        if (templateId !== undefined) {
          const emailTemplate = await emailTemplateRepository.read(templateId) as IEmailTemplate
          form.setValue("subject", emailTemplate.subject)
          form.setValue("content", emailTemplate.content)
        }
      })
    }
  }, [settingId])

  const handleUseEmailTemplate = useCallback(async () => {
    if (emailTemplateListItem !== null) {
      await axiosRequest.callRequest(async () => {
        const emailTemplate = (await emailTemplateRepository.read(emailTemplateListItem.id)) as IEmailTemplate
        form.setValue("subject", emailTemplate.subject)
        form.setValue("content", emailTemplate.content)
      })
    }
  }, [emailTemplateListItem])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleSendShareEmail = useCallback(async () => {
    const formValue = form.getValues() as IInspectionMessage
    const inspectionMessage = { ...formValue, inspection: inspection.id } satisfies IInspectionMessage
    setOpen(false)
    const newInspectionMessage = await apiAdd.handleAdd(inspectionMessage)
    if (newInspectionMessage !== undefined) {
      await axiosRequest.callRequest(async () => {
        await repository.action(newInspectionMessage.id, "send")
      })
    }
  }, [form, inspection])

  const handleFormSubmit = useCallback(async (_data: IInspectionMessage) => {
    await handleSendShareEmail()
  }, [handleSendShareEmail])

  return (
    <>
      <Button onClick={handleOpen}>Share Inspection</Button>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle>{t("Share Inspection")}</DialogTitle>
        <DialogContent>
          <FormBox form={form} onSubmit={handleFormSubmit} showActionPanel={false}>
            <Grid container spacing={2}>
              <Grid item xs />
              <Grid item>
                <DrawerRight title={t("Email Templates")}>
                  <Box>
                    <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
                      <Grid item xs={12}>
                        <SelectFilteredSingle
                          name="email_template"
                          defaultValue={emailTemplateListItem}
                          repository={emailTemplateRepository}
                          onChange={setEmailTemplateListItem}
                        />
                      </Grid>
                      <Grid item xs />
                      <Grid item>
                        <Button onClick={handleUseEmailTemplate} disabled={emailTemplateListItem === null}>
                          Use Email Template
                        </Button>
                      </Grid>
                    </Grid>
                    {emailTemplateListItem?.id !== undefined && <EmailTemplateInfo infoId={emailTemplateListItem.id} />}
                  </Box>
                </DrawerRight>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SelectFilteredMultiple
                  name="to"
                  defaultValue={contacts}
                  repository={contactRepository}
                  onChange={setContacts}
                  itemViewPrefix={ItemPrefixes.contact}
                />
              </Grid>
              <Grid item xs={12}>
                <FhMuiTextField control={form.control} name="subject" />
              </Grid>
              <Grid item xs={12}>
                <FhMuiRichTextField control={form.control} name="content" />
              </Grid>
            </Grid>
          </FormBox>
        </DialogContent>
        <DialogActions>
          <DialogControls onSave={handleSendShareEmail} onCancel={handleClose} buttonLabel={t("Send")} />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default InspectionShare
