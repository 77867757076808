import React, { useCallback, useEffect, useState } from "react"
import CompanyLogo from "../CompanyLogo"
import { Box } from "@mui/material"

interface IProps {
  src?: string
  fallbackSrc?: string | null
  alt?: string
  width?: number | string
  height?: number | string
  logoBlue?: boolean
}

/**
 * This method returns a React element that displays an image. If the image fails to load and no fallback source is
 * provided, a company logo is displayed instead.
 *
 * @param {IProps} props - The properties object.
 * @param {string} props.src - The source URL of the image to display.
 * @param {string} [props.fallbackSrc] - An optional fallback source URL for the image if the primary source fails to load.
 * @param {string} props.alt - The alternative text description of the image.
 *
 * @returns {React.ReactElement} A React element that renders the specified image or a fallback logo.
 */
const ViewImage: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { src, fallbackSrc = null, alt, width = 150, height, logoBlue = true } = props
  const [showLogo, setShowLogo] = useState(false)

  const handleError = useCallback(() => {
    setShowLogo(true)
  }, [])

  useEffect(() => {
    setShowLogo(false)
  }, [src])

  return showLogo && fallbackSrc === null ? (
    <CompanyLogo width={width} height={height} blue={logoBlue} />
  ) : (
    <Box component="img" src={src} alt={alt} onError={handleError} sx={{ maxWidth: width, maxHeight: height }} />
  )
}

export default ViewImage
