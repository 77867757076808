import React from "react"

import "../../support/i18n"
import { isBaseApp } from "../shared/utilities/routing_utility"
import PanariskApp from "./PanariskApp"
import { theRouter as adminRouter } from "../apps/admin/config/routes"
import { navMenu as adminNavMenu } from "../apps/admin/config/menus"
import { theRouter as inspectRouter } from "../apps/inspect/config/routes"
import { navMenu as inspectNavMenu } from "../apps/inspect/config/menus"
import { theRouter as recsRouter } from "../apps/recs/config/routes"
import { navMenu as recsNavMenu } from "../apps/recs/config/menus"
import { createBrowserRouter } from "react-router-dom"
import LoginLayout from "../shared/layouts/LoginLayout"
import LandingPage from "../shared/pages/misc/LandingPage"
import { type IVersions } from "../shared/models/app/IVersions"

const routeLanding = createBrowserRouter([
  {
    path: "*",
    element: <LoginLayout title="Panarisk Sites" component={LandingPage} />,
  },
])

interface IProps {
  versions: IVersions
}

/**
 * PanariskSuite is a functional React component that renders different configurations
 * of the PanariskApp based on the current base application context.
 *
 * @param {IProps} props - Component properties containing versions data.
 * @returns {React.ReactElement} A rendered PanariskApp component with appropriate routes, sideMenu, and siteName.
 */
const PanariskSuite: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { versions } = props
  if (isBaseApp("admin")) {
    const routes = adminRouter({ basename: "/admin" })
    return <PanariskApp versions={versions} router={routes} sideMenu={adminNavMenu()} siteName="Admin" />
  }
  if (isBaseApp("inspect")) {
    const routes = inspectRouter({ basename: "/inspect" })
    return <PanariskApp versions={versions} router={routes} sideMenu={inspectNavMenu()} siteName="Inspect" />
  }
  if (isBaseApp("recs")) {
    const routes = recsRouter({ basename: "/recs" })
    return <PanariskApp versions={versions} router={routes} sideMenu={recsNavMenu()} siteName="Recs" />
  }
  return <PanariskApp versions={versions} router={routeLanding} sideMenu={[]} siteName="Sites" />
}

export default PanariskSuite
