import React from "react"
import { ApplicationInsights } from "@microsoft/applicationinsights-web"
import { ReactPlugin } from "@microsoft/applicationinsights-react-js"

if (process.env.REACT_APP_INSTRUMENTATION_KEY !== undefined) {
  const reactPlugin = new ReactPlugin()
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: process.env.REACT_APP_INSTRUMENTATION_KEY,
      extensions: [reactPlugin],
    },
  })
  appInsights.loadAppInsights()
  appInsights.trackMetric({ name: 'ProcessingTime', average: 75 })
}

interface IProps {
  children: React.ReactNode
}

/**
 * A component that integrates Application Insights with React.
 *
 * @param {IProps} props - The component properties.
 * @returns {React.ReactElement} The rendered child elements wrapped with Application Insights configuration.
 */
const AppWithInsights = ({ children }: IProps): React.ReactElement => {
  return <>{children}</>
}

export default AppWithInsights
