import React from "react"
import { Box, type Theme } from "@mui/material"

interface IProps {
  children: string | null | undefined
}

/**
 * React Functional Component to display rich text.
 *
 * @param {IProps} props - The component props.
 * @returns {React.ReactElement} - The rendered rich text component.
 */
const ViewRichText: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children: richText } = props

  if (
    richText === undefined ||
    richText === null ||
    richText === "" ||
    richText === "<p></p>" ||
    richText === "<p><br></p>" ||
    richText === "<p><br /></p>"
  ) {
    return <>Not Set</>
  }

  return (
    <Box
      sx={(theme: Theme) => ({ "& a": { color: theme.palette.primary.main } })}
      dangerouslySetInnerHTML={{ __html: richText }}
    />
  )
}

export default ViewRichText
