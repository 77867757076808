import { useContext } from "react"
import { type TModelNames } from "../../models/service/IMetadataConfig"
import { PanariskAppContext } from "../../../app/PanariskApp"


/**
 * Custom hook to check if metadata exists for the given model name.
 *
 * @param {TModelNames} modelName - The name of the model for which metadata existence is being checked.
 * @returns {boolean} A boolean indicating whether metadata exists (true) or not (false) for the specified model name.
 *
 * This hook retrieves the application settings via the PanariskAppContext
 * and checks if there is metadata configured for the provided model name.
 */
export const useMetadataExists = (modelName: TModelNames): boolean => {
  const { appSettings } = useContext(PanariskAppContext)
  const metadata = appSettings?.serverInfo?.[`${modelName}_metadata_config`]
  return metadata !== null && metadata !== undefined
}
