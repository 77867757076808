import { useCallback } from "react"
import type { IRiskWriterFormat } from "../models/IRiskWriterFormat"
import type { ISelectItem } from "../../../models/components/ISelectItem"

interface IUseGetOptionValueProps {
  format: IRiskWriterFormat | null | undefined
}

interface IUseGetOptionValueResponse {
  getOptionValue: (optionsKey: string, value: string | boolean | number | null | undefined) => string | number | boolean
  getSelectOptions: (optionsKey: string) => ISelectItem[]
}

/**
 * Retrieves the value from the format options based on the given optionsKey and value.
 *
 * @param {IUseGetOptionValueProps} props - The props containing the format options.
 * @returns {IUseGetOptionValueResponse} - The retrieved value from the format options.
 */
export const useRiskWriterOptions = (props: IUseGetOptionValueProps): IUseGetOptionValueResponse => {
  const { format } = props

  const getOptionValue = useCallback(
    (optionsKey: string, value: string | number | boolean | null | undefined): string | number | boolean => {
      if (format?.options === undefined || format.options === null) {
        return `${value}`
      }
      if (optionsKey in format.options) {
        const title = format.options[optionsKey].find(option => option[0] === value)
        if (title?.[1] !== undefined && title[1] !== null) {
          return title[1]
        }
      }
      if (value === undefined || value === null) {
        return "Not Set"
      }
      return `${value}`
    },
    [format],
  )

  const getSelectOptions = useCallback(
    (optionsKey: string): ISelectItem[] => {
      if (format?.options === undefined || format.options === null) {
        return []
      }
      if (optionsKey in format.options) {
        return format.options[optionsKey].map(entry => ({ key: entry[0], value: `${entry[1]}` }) satisfies ISelectItem)
      }
      return []
    },
    [format],
  )

  return {
    getOptionValue,
    getSelectOptions,
  }
}
