import React, { useCallback, useState } from "react"
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material"
import { useTranslation } from "react-i18next"
import DialogControls from "../../../components/DialogControls"
import type IProfile from "../../../models/users/IProfile"
import { PROFILES_ENDPOINT } from "../../../models/users/IProfile"
import { RestRepository } from "../../../repositories/RestRepository"
import useApiAction from "../../../hooks/useApiAction"
import { type IPaging } from "../../../models/components/IPaging"
import ErrorMessage from "../../../components/ErrorMessage"

const repository = new RestRepository<IProfile>(PROFILES_ENDPOINT)

interface IProps {
  profile: IProfile
  onChange: () => Promise<void>
}

interface IBase64Image {
  qr_code_base64: string
}

/**
 * A React component that manages enabling and disabling two-factor authentication (2FA) for a user profile.
 *
 * @param {IProps} props - The props object.
 * @param {IProfile} props.profile - The user profile object, containing details like whether 2FA is enabled.
 * @returns {React.ReactElement} The Manage2FA component renders a button to enable/disable 2FA
 * and displays a dialog with controls for managing the 2FA process.
 */
const Manage2FA: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { profile, onChange } = props
  const [open, setOpen] = useState<boolean>(false)
  const [start2FA, setStart2FA] = useState<boolean>(false)
  const { t } = useTranslation()
  const apiAction = useApiAction({ repository, itemId: profile.id })
  const [qrCode, setQrCode] = useState<string>("")
  const [otpCode, setOtpCode] = useState<string>("")

  const handleOpen = useCallback(() => {
    setOpen(true)
    setStart2FA(false)
  }, [])

  const handleClose = useCallback(async () => {
    setOpen(false)
    apiAction.clearError()
    setQrCode("")
    setOtpCode("")
    setStart2FA(false)
  }, [])

  const handleOtpCode = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setOtpCode(event.target.value)
  }, [])

  const handleStart2FA = useCallback(async () => {
    setStart2FA(true)
    const image = await apiAction.callAction<IBase64Image>("generate_2fa")
    if (image !== undefined) {
      setQrCode(image.qr_code_base64)
    }
  }, [])

  const handleVerify = useCallback(async () => {
    const paging: IPaging = { filters: [{ field: "otp_code", value: otpCode }] }
    await apiAction.callAction("verify_otp_code", paging)
    await onChange()
  }, [otpCode])

  const handleDisable2FA = useCallback(async () => {
    await apiAction.callAction("disable_2fa")
    await onChange()
    apiAction.clearError()
    setQrCode("")
    setOtpCode("")
    setStart2FA(false)
  }, [])

  return (
    <>
      <Button onClick={handleOpen}>{t("Manage")}</Button>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{t("Manage 2-Factor Authentication")}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              {apiAction.connectionError !== undefined && <ErrorMessage error={apiAction.connectionError} />}
            </Grid>
          </Grid>
          {profile.otp_enabled === null ? (
            <Grid container spacing={2} alignItems="center">
              {start2FA ? (
                <>
                  <Grid item xs />
                  <Grid item>
                    {qrCode !== "" && <Box component="img" src={`data:image/png;base64,${qrCode}`} alt="QR Code" />}
                  </Grid>
                  <Grid item xs />
                  <Grid item xs={12} />
                  <Grid item xs />
                  <Grid item xs={6}>
                    <TextField label={t("Enter Code")} value={otpCode} onChange={handleOtpCode} fullWidth />
                  </Grid>
                  <Grid item>
                    <Button onClick={handleVerify}>{t("Verify")}</Button>
                  </Grid>
                  <Grid item xs />
                </>
              ) : (
                <>
                  <Grid item xs>
                    <Alert severity="warning">{t("2-Factor Authentication is currently disabled.")}</Alert>
                  </Grid>
                  <Grid item>
                    <Button color="primary" onClick={handleStart2FA}>
                      {t("Enable")}
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          ) : (
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <Alert severity="success">{t("2-Factor Authentication is currently enabled.")}</Alert>
              </Grid>
              <Grid item>
                <Button color="error" onClick={handleDisable2FA}>{t("Disable")}</Button>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <DialogControls onSave={handleClose} buttonLabel={t("Close")} />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Manage2FA
