import React, { useContext, useState } from "react"
import { Container, Grid } from "@mui/material"
import { useTranslation } from "react-i18next"
import PageHeader from "../../components/pages/PageHeader"
import PaperLocal from "../../components/containers/PaperLocal"
import ViewProperty from "../../components/ViewProperty"
import { PanariskAppContext } from "../../../app/PanariskApp"

interface IDateTimeZone {
  date: string
  time: string
  timezone: string
}

/**
 * Retrieves the current browser's date, time, and timezone information.
 * @returns {IDateTimeZone} An object containing the current date, time, and timezone.
 */
const getBrowserDateTimeZone = (): IDateTimeZone => {
  const now = new Date()
  const date = now.toDateString()
  const time = now.toLocaleTimeString()
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  return { date, time, timezone }
}

/**
 * Renders the index page with about.
 *
 * @returns {React.ReactElement} The rendered index page.
 */
const IndexPage: React.FC = (): React.ReactElement => {
  const [browserDateTimeZone] = useState<IDateTimeZone>(getBrowserDateTimeZone())

  const { appSettings, versions } = useContext(PanariskAppContext)
  const { t } = useTranslation()

  return (
    <Container fixed>
      <Grid container spacing={2}>
        <PageHeader title={t("About")} />
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <PaperLocal>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ViewProperty label={t("Client Frontend Version")}>
                      {versions?.pr_client_frontend_version}
                    </ViewProperty>
                  </Grid>
                  <Grid item xs={12}>
                    <ViewProperty label={t("Panarisk Frontend Core Version")}>
                      {versions?.pr_core_frontend_version}
                    </ViewProperty>
                  </Grid>
                  <Grid item xs={12}>
                    <ViewProperty label={t("Client Backend Version")}>
                      {appSettings.serverInfo?.pr_client_backend_version}
                    </ViewProperty>
                  </Grid>
                  <Grid item xs={12}>
                    <ViewProperty label={t("Panarisk Backend Core Version")}>
                      {appSettings.serverInfo?.pr_core_version}
                    </ViewProperty>
                  </Grid>
                  <Grid item xs={12}>
                    <ViewProperty label={t("Server Time Zone")}>{appSettings.serverInfo?.timezone}</ViewProperty>
                  </Grid>
                  <Grid item xs={12}>
                    <ViewProperty label={t("Server Time")}>{appSettings.serverInfo?.time}</ViewProperty>
                  </Grid>
                  <Grid item xs={12}>
                    <ViewProperty label={t("Browser Time Zone")}>{browserDateTimeZone.timezone}</ViewProperty>
                  </Grid>
                  <Grid item xs={12}>
                    <ViewProperty label={t("Browser Date")}>{browserDateTimeZone.date}</ViewProperty>
                  </Grid>
                  <Grid item xs={12}>
                    <ViewProperty label={t("Browser Time")}>{browserDateTimeZone.time}</ViewProperty>
                  </Grid>
                </Grid>
              </PaperLocal>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default IndexPage
