import { type ILocation } from "./ILocation"
import { type IListRecItem } from "../components/IListRecItem"
import { type IFile } from "./IFile"
import { type IMainModel } from "./IMainModel"
import { type IServiceUser } from "./IServiceUser"
import { type ILocationImpairmentSystemType } from "./ILocationImpairmentSystemType"
import { type ILocationImpairmentTag } from "./ILocationImpairmentTag"
import { type ILocationImpairmentStatus } from "./ILocationImpairmentStatus"
import { type ILocationImpairmentType } from "./ILocationImpairmentType"

export const LOCATION_IMPAIRMENT_ENDPOINT = "location_impairments"
export const LOCATION_IMPAIRMENT_FILE_EXT_ENDPOINT = "location_impairment_file_ext"

export interface ILocationImpairment extends IListRecItem, IMainModel {
  id: number
  identifier: string

  location: ILocation
  location_files: IFile[]

  tags: ILocationImpairmentTag[]

  status: ILocationImpairmentStatus | null
  impairment_type: ILocationImpairmentType | null

  system_type: ILocationImpairmentSystemType | null
  system_name: string

  title: string
  description: string

  start_date: string
  end_date: string

  completed: string | null
  completed_by: IServiceUser | null

  created: string
  updated: string
}
