import { CONNECTION_ERROR, type IConnectionError } from "../models/components/IConnectionError"
import { useCallback, useState } from "react"
import { type RestRepository } from "../repositories/RestRepository"
import { type IPaging } from "../models/components/IPaging"
import { type AxiosError } from "axios"
import { type IMainModel } from "../models/service/IMainModel"

interface IUseApiActionResponse {
  callAction: <S>(action: string, paging?: IPaging | null) => Promise<S | undefined>
  saving: boolean
  connectionError: IConnectionError | undefined
  clearError: () => void
}

export interface IUseApiActionProps<T extends IMainModel> {
  repository: RestRepository<T>
  itemId?: number | string | undefined
}

/**
 * Hook for sending an action to REST objects.
 *
 * @param {IUseApiActionResponse} props See IProps for details.
 * @returns {IUseApiActionProps} See IUseApiActionProps for details.
 */
const useApiAction = <T extends IMainModel>(props: IUseApiActionProps<T>): IUseApiActionResponse => {
  const { repository, itemId } = props
  const [saving, setSaving] = useState<boolean>(false)
  const [connectionError, setConnectionError] = useState<IConnectionError | undefined>()

  const callAction = useCallback(
    async <S>(action: string, paging?: IPaging | null): Promise<S | undefined> => {
      setSaving(true)
      setConnectionError(undefined)
      try {
        if (itemId !== undefined) {
          const item = await repository.action<S>(itemId, action, paging)
          setSaving(false)
          return item
        }
        const item = await repository.actionNoDetail<S>(action, paging)
        setSaving(false)
        return item
      } catch (reason) {
        setSaving(false)
        if ((reason as AxiosError)?.response !== undefined) {
          setConnectionError((reason as AxiosError).response as IConnectionError)
        } else {
          setConnectionError(CONNECTION_ERROR)
        }
      }
    },
    [repository, itemId],
  )

  const clearError = useCallback(() => {
    setConnectionError(undefined)
  }, [])

  return {
    callAction,
    saving,
    connectionError,
    clearError
  }
}

export default useApiAction

/**
 * Builds an API action for the given repository and item ID.
 *
 * @template T - extends IMainModel
 *
 * @param {RestRepository<T>} repository - The repository for the API action.
 * @param {number} itemId - The ID of the item for the API action.
 * @returns {IUseApiActionResponse<T>} - The API action response.
 */
export const buildApiAction = <T extends IMainModel>(
  repository: RestRepository<T>,
  itemId?: number | string,
): IUseApiActionResponse => {
  const actionProps: IUseApiActionProps<T> = { repository, itemId }
  return useApiAction<T>(actionProps)
}
