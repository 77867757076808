import React from "react"
import { type SxProps, TableCell, type Theme } from "@mui/material"
import TruncateText from "../TruncateText"
import { stripHtmlTags } from "../../utilities/format_utility"

interface IProps {
  children: string | undefined | null | number | boolean
  isRichText?: boolean
  field?: string
  sx?: SxProps<Theme>
}

/**
 * Renders a TableCell with truncated text.
 *
 * @param {IProps} props - The component props.
 * @returns {React.ReactElement} - The rendered TableCell with truncated text.
 */
const TableCellTruncate: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children, sx, isRichText = false } = props

  return (
    <TableCell sx={sx}>
      {isRichText && children !== undefined && children !== null ? (
        <TruncateText altText={`${children}`}>{stripHtmlTags(`${children}`)}</TruncateText>
      ) : (
        <TruncateText>{`${children}`}</TruncateText>
      )}
    </TableCell>
  )
}

export default TableCellTruncate
