import { type ITableFilter } from "../utilities/table_utilities"

export interface ISectionDataRichText {
  /**
   * The HTML content of the section.
   */
  html: string
}

export interface ISectionDataImage {
  /**
   * A unique identifier for the image.
   */
  image_id: number
}

export enum SectionDataTableDisplayType {
  Table = "Table",
  BarChart = "Bar Chart",
  PieChart = "Pie Chart",
  SingleValue = "Single Value",
}

export enum SectionDataTableDisplayAggregate {
  Sum = "Sum",
  Average = "Average",
}

/**
 * Finds the corresponding display aggregate from a given string.
 *
 * This function takes a string value and tries to find a matching key
 * in the SectionDataTableDisplayAggregate object. If a match is found,
 * it returns the corresponding display aggregate. If the input value
 * is undefined or there is no matching key, it returns undefined.
 *
 * @param {string|undefined} value - The string value to match against the display aggregates.
 * @returns {SectionDataTableDisplayAggregate|undefined} The corresponding display aggregate or undefined if no match is found.
 */
export const findDisplayAggregateByString = (
  value: string | undefined,
): SectionDataTableDisplayAggregate | undefined => {
  if (value === undefined) {
    return undefined
  }
  if (Object.keys(SectionDataTableDisplayAggregate).includes(value)) {
    return SectionDataTableDisplayAggregate[value as keyof typeof SectionDataTableDisplayAggregate]
  } else {
    return undefined
  }
}

/**
 * Retrieves the display aggregate key from a given value.
 *
 * Given a string value, this function iterates through the keys of the
 * SectionDataTableDisplayAggregate object and returns the corresponding
 * key if the value matches any of its properties. If the value is not found,
 * it returns undefined.
 *
 * @param {string} value - The value to search for in the SectionDataTableDisplayAggregate object.
 * @returns {string | undefined} - The matching key if found; otherwise, undefined.
 */
export const getDisplayAggregateByValue = (value: string): string | undefined => {
  return (Object.keys(SectionDataTableDisplayAggregate) as Array<keyof typeof SectionDataTableDisplayAggregate>).find(
    key => SectionDataTableDisplayAggregate[key] === value,
  )
}

/**
 * Finds a table display type by its corresponding string value.
 *
 * @param {string | undefined} value - The string representation of a display type.
 * @returns {(SectionDataTableDisplayType | undefined)} The matched operation if found, otherwise undefined.
 */
export const findDisplayTypeByString = (value: string | undefined): SectionDataTableDisplayType | undefined => {
  if (value === undefined) {
    return undefined
  }
  if (Object.keys(SectionDataTableDisplayType).includes(value)) {
    return SectionDataTableDisplayType[value as keyof typeof SectionDataTableDisplayType]
  } else {
    return undefined
  }
}

/**
 * Returns the key from SectionDataTableDisplayType that corresponds to the given value.
 *
 * This function searches through the keys of the SectionDataTableDisplayType object to find
 * a key whose value matches the provided string. If such a key is found, it is returned.
 * If no matching key is found, it returns undefined.
 *
 * @param {string} value - The value to match against the values in SectionDataTableDisplayType.
 * @returns {string | undefined} - The key that matches the provided value, or undefined if no match is found.
 */
export const getDisplayTypeByValue = (value: string): string | undefined => {
  return (Object.keys(SectionDataTableDisplayType) as Array<keyof typeof SectionDataTableDisplayType>).find(
    key => SectionDataTableDisplayType[key] === value,
  )
}

/**
 * Replaces <span> elements with a specific class and id in the given HTML string with a template format.
 *
 * Replaces all <span id="some_key_name" class="pill">...</span> with the format ${some_key_name}.
 *
 * @param {string} html - The HTML string containing <span> elements to be replaced.
 * @returns {string} - The modified HTML string with <span> elements replaced by template format.
 */
export const replaceSpansWithTemplate = (html: string): string => {
  // Regular expression to match <span id="some_key_name" class="pill">...</span>
  const spanRegex = /<span id="([^"]+)" class="pill">(.*?)<\/span>/g
  // Replace matches with the desired format
  return html.replace(spanRegex, (_match, keyName, _innerText) => {
    return `\${${keyName}}`
  })
}

/**
 * Replaces template placeholders in the format ${{id}} within a given HTML string
 * with <span> elements containing the specified text.
 *
 * @param {string} html - The input HTML string containing template placeholders.
 * @param {string} id - The identifier used within the template placeholder (e.g., 'some_key_name').
 * @param {string} spanText - The text content to be inserted within the <span> element.
 *
 * @returns {string} - The modified HTML string with template placeholders replaced by <span> elements.
 */
export const replaceTemplateWithSpans = (html: string, id: string, spanText: string): string => {
  // Regular expression to match the ${{some_key_name}} format
  const templateRegex = new RegExp(`\\$\\{${id}\\}`, "g")
  // Replace matches with the desired <span> format
  return html.replace(templateRegex, `<span id="${id}" class="pill">${spanText}</span>`)
}

export interface ISectionDataTable {
  /**
   * A string representing the name of the table.
   */
  table_name: string
  title?: string | null
  show_title?: boolean
  show_table?: boolean
  color?: string
  icon?: string
  limit?: number
  sort_column?: string
  sort_asc?: boolean
  table_filters?: ITableFilter[]
  display_type?: SectionDataTableDisplayType
  label_column?: string
  data_column?: string
  aggregate?: SectionDataTableDisplayAggregate
}

export enum SectionType {
  RICH_TEXT_EDITOR = 1,
  IMAGE_EDITOR = 2,
  TABLE_EDITOR = 3,
}

export interface IRiskWriterSectionData {
  name: string
  section_name: string
  data: ISectionDataRichText | ISectionDataImage | ISectionDataTable | null
  section_type: SectionType
}
