import React, { useCallback, useState } from "react"
import { type RestRepository } from "../../repositories/RestRepository"
import AlertDialog from "../AlertDialog"
import DeleteIcon from "@mui/icons-material/Delete"
import ViewLoading from "../ViewLoading"
import TruncateText from "../TruncateText"
import { type IMainModel } from "../../models/service/IMainModel"

interface IProps {
  repository: RestRepository<IMainModel>
  item: IMainModel
  onChange: () => void
}

/**
 * Handles the deleting of an item.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the delete component.
 */
const ItemDelete: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { repository, item, onChange } = props
  const [loading, setLoading] = useState<boolean>(false)

  const handleDelete = useCallback(
    (item1: IMainModel) => async () => {
      if (item1.id !== undefined) {
        setLoading(true)
        try {
          await repository.delete(item1.id)
          onChange()
        } catch (e) {}
        setLoading(false)
      }
    },
    [onChange],
  )

  return (
    <>
      <ViewLoading
        loading={loading}
        message={
          <>
            Deleting:{" "}
            <em>
              <TruncateText>{item.name}</TruncateText>
            </em>
          </>
        }
      />
      <AlertDialog
        buttonText="Delete item."
        buttonIcon={<DeleteIcon />}
        title="Confirm Item Delete"
        message={
          <>
            Are you sure you want to delete:{" "}
            <p>
              <em>{item.name}</em>
            </p>
          </>
        }
        onYes={handleDelete(item)}
      />
    </>
  )
}

export default ItemDelete
