import React, { useCallback, useState } from "react"
import { Alert, Box, Button, Grid } from "@mui/material"
import DrawerRight from "../containers/DrawerRight"
import EditIcon from "@mui/icons-material/Edit"
import { RestRepository } from "../../repositories/RestRepository"
import { type IListItem } from "../../models/components/IListItem"
import SelectFilteredSingle from "../forms/SelectFilteredSingle"
import { type IInspection, INSPECTION_ENDPOINT, reportWriterFormatAsListItem } from "../../models/service/IInspection"
import { type IMainModel } from "../../models/service/IMainModel"
import { useAxiosRequest } from "../../hooks/useAxiosRequest"
import ViewLoading from "../ViewLoading"
import ErrorMessage from "../ErrorMessage"
import { type IReportWriterFormat, REPORT_WRITER_FORMAT_ENDPOINT } from "../../models/service/IReportFormat"
import { useTranslation } from "react-i18next"

interface IPatch extends IMainModel {
  report_writer_format: number | null
}

const inspectionStatusRepository = new RestRepository<IReportWriterFormat | IListItem>(REPORT_WRITER_FORMAT_ENDPOINT)
const inspectRepository = new RestRepository<IPatch>(INSPECTION_ENDPOINT)

interface IProps {
  inspection: IInspection
  onChange: () => void
}

/**
 * EditReportWriterVersion is a React Functional Component that renders a form for editing
 * the inspection status.
 *
 * @param {IProps} props - The properties object containing the inspection status information.
 * @returns {React.ReactElement} The rendered component.
 */
const EditReportWriterVersion: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { inspection, onChange } = props
  const axiosRequest = useAxiosRequest()
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)

  const [reportWriterFormat, setReportWriterFormat] = useState<IReportWriterFormat | IListItem | null>(null)

  const handleAssign = useCallback(async () => {
    await axiosRequest.callRequest(async () => {
      const reportWriterFormatId = reportWriterFormat?.id !== undefined ? Number(reportWriterFormat.id) : null
      const patch: IPatch = { id: inspection.id, name: inspection.name, report_writer_format: reportWriterFormatId }
      await inspectRepository.patch(patch, inspection.id)
      onChange()
      setOpen(open => !open)
      setReportWriterFormat(null)
    })
  }, [inspection, reportWriterFormat])

  const handleDrawerOpen = useCallback(async () => {
    const reportWriterFormat: IListItem | null = reportWriterFormatAsListItem(inspection.report_writer_format)
    setReportWriterFormat(reportWriterFormat)
  }, [inspection])

  return (
    <DrawerRight
      icon={<EditIcon />}
      title={t("Edit Report Writer Format")}
      showIconButton
      closeDrawer={open}
      onDrawerOpen={handleDrawerOpen}>
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ErrorMessage error={axiosRequest.errorMessage} />
          </Grid>
          <Grid item xs={12}>
            <SelectFilteredSingle
              name="report_writer_format"
              defaultValue={reportWriterFormat}
              repository={inspectionStatusRepository}
              filters={[{ field: "report_format", value: inspection.report_format?.id }]}
              onChange={setReportWriterFormat}
            />
          </Grid>
          <Grid item xs={12}>
            <Alert severity="warning">
              {t(
                "Warning: This will overwrite the current report writer format for this inspection. By changing the\n" +
                  "version, current information in your report might be lost.",
              )}
            </Alert>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "right" }}>
            <Button onClick={handleAssign} disabled={axiosRequest.loading || reportWriterFormat === null}>
              {t("Update")}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <ViewLoading loading={axiosRequest.loading} inline />
          </Grid>
        </Grid>
      </Box>
    </DrawerRight>
  )
}

export default EditReportWriterVersion
