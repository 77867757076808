import React, { useCallback, useState } from "react"
import { Grid, Switch, Typography } from "@mui/material"
import PaperLocal from "../../../../../shared/components/containers/PaperLocal"
import useEffectInit from "../../../../../shared/hooks/useEffectInit"
import { axiosInstance, BASE_URL } from "../../../../../shared/utilities/request_utility"
import { useAxiosRequest } from "../../../../../shared/hooks/useAxiosRequest"
import { type IGroup } from "../../../../../shared/models/users/IGroup"
import ViewProperty from "../../../../../shared/components/ViewProperty"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import { type IServiceUser, SERVICE_USER_ENDPOINT } from "../../../../../shared/models/service/IServiceUser"
import useApiAction from "../../../../../shared/hooks/useApiAction"

const repository = new RestRepository<IServiceUser>(SERVICE_USER_ENDPOINT)

interface IPermissions {
  access: IGroup[]
  roles: IGroup[]
  features: IGroup[]
}

interface IProps {
  serviceUser: IServiceUser
  onChange: () => Promise<void>
}

/**
 * ServiceUserPermissions is a React functional component that manages
 * and displays the permissions for a service user. The component allows
 * toggling user roles and site access permissions.
 *
 * @param {IProps} props - The properties that define the service user's permissions.
 * @returns {React.ReactElement} A React component that renders user roles and site access permissions,
 *                                and allows toggling these permissions.
 */
const ServiceUserPermissions: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { serviceUser, onChange } = props
  const [permissions, setPermissions] = useState<IPermissions | null>(null)
  const axiosRequest = useAxiosRequest()
  const apiAction = useApiAction<IServiceUser>({ repository, itemId: serviceUser.id })

  const hasGroup = useCallback(
    (group: IGroup) => {
      return serviceUser.user_groups.some(g => g.id === group.id) ?? false
    },
    [serviceUser.user_groups],
  )

  const handleToggleGroup = useCallback((group: IGroup) => async () => {
    await apiAction.callAction("toggle_permission", {filters: [{field: "group_id", value: group.id}]})
    await onChange()
  }, [])

  useEffectInit(async () => {
    const url = `${BASE_URL}/lookups/available_groups/`
    await axiosRequest.callRequest(async () => {
      const { data } = await axiosInstance.get<IPermissions>(url)
      setPermissions(data)
    })
  }, [])

  return (
    <Grid container spacing={2}>
      <Grid item lg={6} xs={12}>
        <PaperLocal>
          <>
            <Typography variant="h5" color="textSecondary">
              User Roles
            </Typography>
            {permissions?.roles?.map(group => {
              return (
                <ViewProperty key={group.id} label={group.name}>
                  <Switch checked={hasGroup(group)} onClick={handleToggleGroup(group)} />
                </ViewProperty>
              )
            })}
          </>
        </PaperLocal>
      </Grid>
      <Grid item lg={6} xs={12}>
        <PaperLocal>
          <>
            <Typography variant="h5" color="textSecondary">
              Site Access
            </Typography>
            {permissions?.access?.map(group => {
              return (
                <ViewProperty key={group.id} label={group.name}>
                  <Switch checked={hasGroup(group)} onClick={handleToggleGroup(group)} />
                </ViewProperty>
              )
            })}
          </>
        </PaperLocal>
      </Grid>
      <Grid item lg={6} xs={12}>
        <PaperLocal>
          <>
            <Typography variant="h5" color="textSecondary">
              Feature Access
            </Typography>
            {permissions?.features?.map(group => {
              return (
                <ViewProperty key={group.id} label={group.name}>
                  <Switch checked={hasGroup(group)} onClick={handleToggleGroup(group)} />
                </ViewProperty>
              )
            })}
          </>
        </PaperLocal>
      </Grid>
    </Grid>
  )
}

export default ServiceUserPermissions
