import { type IMainModel } from "./IMainModel"

export const EMAIL_TEMPLATE_ENDPOINT = "email_templates"

export interface IEmailTemplate extends IMainModel {
  id: number
  name: string
  subject: string
  content: string
  created: string
  updated: string
}
