import React from "react"
import { Grid } from "@mui/material"
import { FILTER_BOOKMARK_ENDPOINT, type IFilterBookmark } from "../../../models/service/IFilterBookmark"
import { RestRepository } from "../../../repositories/RestRepository"
import { type IInfoViewProps } from "../../../components/item_viewer/ItemViewerDrawer"
import { useApiRead } from "../../../hooks/useApiRead"
import ItemViewerTitle from "../../../components/item_viewer/ItemViewerTitle"
import ViewProperty from "../../../components/ViewProperty"
import { nameToLabel } from "../../../utilities/form_utility"
import ViewFilterBookmark from "../../../components/display/ViewFilterBookmark"

const repository = new RestRepository<IFilterBookmark>(FILTER_BOOKMARK_ENDPOINT)

/**
 * Renders a page to view filter bookmark details.
 *
 * @param {IInfoViewProps} props See props for details
 * @returns {React.ReactElement} The rendered component.
 */
const FilterBookmarkInfo: React.FC<IInfoViewProps> = (props: IInfoViewProps): React.ReactElement => {
  const { infoId } = props
  const { data } = useApiRead<IFilterBookmark>({ apiFunction: repository.read, itemId: infoId })

  return (
    <>
      {data !== undefined && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ItemViewerTitle>{data.name}</ItemViewerTitle>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label="Model Name">{nameToLabel(data.name)}</ViewProperty>
            </Grid>
          </Grid>
          <ViewFilterBookmark bookmark={data} />
        </>
      )}
    </>
  )
}

export default FilterBookmarkInfo
