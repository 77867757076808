import { type IContact } from "./IContact"
import { type IServiceUser } from "./IServiceUser"
import { type IListItem } from "../components/IListItem"
import { type TMetadataData } from "./IMetadataConfig"

export const ACCOUNT_ENDPOINT = "accounts"
export const ACCOUNT_FILE_EXT_ENDPOINT = "account_file_ext"

export interface IAccount extends IListItem {
  id: number
  identifier: string
  name: string
  location_count: number
  notes: string
  contact: IContact | null
  contacts: IContact[]
  account_managers: IServiceUser[]
  brokers: IContact[]
  metadata_data: TMetadataData | null
  created: string
  updated: string
}
