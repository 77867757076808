import React from "react"
import { type UseFormReturn } from "react-hook-form/dist/types"
import { Grid } from "@mui/material"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import FhMuiHiddenField from "../../../../../shared/components/forms/FhMuiHiddenField"
import FhMuiTextField from "../../../../../shared/components/forms/FhMuiTextField"
import FhMuiRichTextField from "../../../../../shared/components/forms/FhMuiRichTextField"
import { CONTACT_ENDPOINT, type IContact } from "../../../../../shared/models/service/IContact"
import { type IListItem } from "../../../../../shared/models/components/IListItem"
import { type IAccount } from "../../../../../shared/models/service/IAccount"
import { requiredRule } from "../../../../../shared/utilities/form_utility"
import SelectFilteredMultiple, {
  useSelectFilteredMultiple,
} from "../../../../../shared/components/forms/SelectFilteredMultiple"
import SelectFilteredSingle, {
  useSelectFilteredSingle,
} from "../../../../../shared/components/forms/SelectFilteredSingle"
import { type IServiceUser, SERVICE_USER_ENDPOINT } from "../../../../../shared/models/service/IServiceUser"
import useLoadFormData from "../../../../../shared/hooks/useLoadFormData"
import PaperLocal from "../../../../../shared/components/containers/PaperLocal"
import ItemViewerDrawer from "../../../../../shared/components/item_viewer/ItemViewerDrawer"
import { ItemPrefixes } from "../../../../../config/config"
import ContactInfo from "../../contacts/components/ContactInfo"
import ServiceUserInfo from "../../service_users/components/ServiceUserInfo"
import MetadataForm from "../../../../../shared/components/metadata/MetadataForm"
import { useMetadataExists } from "../../../../../shared/components/metadata/UseMetadataExists"

const contactRepository = new RestRepository<IContact | IListItem>(CONTACT_ENDPOINT)
const serviceUsersRepository = new RestRepository<IServiceUser | IListItem>(SERVICE_USER_ENDPOINT)

interface IProps {
  form: UseFormReturn
  account?: IAccount | undefined | null
  isEdit?: boolean
}

/**
 * Use this forms to add or edit an account.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} returns the forms.
 */
const AccountForm: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { account, form, isEdit = false } = props

  const metadataExists = useMetadataExists("account")
  const [contact, setContact] = useSelectFilteredSingle("contact", form)
  const [contacts, setContacts] = useSelectFilteredMultiple("contacts", form)
  const [accountManagers, setAccountManagers] = useSelectFilteredMultiple("account_managers", form)

  const [brokers, setBrokers] = useSelectFilteredMultiple("brokers", form)

  useLoadFormData<IAccount>(
    (data: IAccount) => {
      form.setValue("id", data.id)
      form.setValue("name", data.name)
      form.setValue("notes", data.notes)
      form.setValue("metadata_data", data.metadata_data)
      setContact(data.contact)
      setContacts(data.contacts)
      setAccountManagers(data.account_managers)
      setBrokers(data.brokers)
    },
    account,
    isEdit,
    form.setValue,
  )

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PaperLocal>
              <ItemViewerDrawer title={"Contact"} prefix={ItemPrefixes.contact} infoView={ContactInfo} />
              <ItemViewerDrawer title={"Service User"} prefix={ItemPrefixes.serviceUser} infoView={ServiceUserInfo} />
              {isEdit && <FhMuiHiddenField control={form.control} />}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FhMuiTextField control={form.control} name="name" rules={requiredRule()} />
                </Grid>
              </Grid>
            </PaperLocal>
          </Grid>
          <Grid item xs={12}>
            <PaperLocal>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FhMuiRichTextField control={form.control} name="notes" />
                </Grid>
              </Grid>
            </PaperLocal>
          </Grid>
          {metadataExists && (
            <Grid item xs={12}>
              <PaperLocal>
                <MetadataForm modelName="account" form={form} />
              </PaperLocal>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} lg={6}>
        <PaperLocal>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SelectFilteredMultiple
                name="account_managers"
                defaultValue={accountManagers}
                repository={serviceUsersRepository}
                onChange={setAccountManagers}
                itemViewPrefix={ItemPrefixes.serviceUser}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectFilteredMultiple
                name="brokers"
                defaultValue={brokers}
                repository={contactRepository}
                onChange={setBrokers}
                itemViewPrefix={ItemPrefixes.contact}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectFilteredSingle
                name="contact"
                label="Primary Contact"
                defaultValue={contact}
                repository={contactRepository}
                onChange={setContact}
                infoViewPrefix={ItemPrefixes.contact}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectFilteredMultiple
                name="contacts"
                defaultValue={contacts}
                repository={contactRepository}
                onChange={setContacts}
                itemViewPrefix={ItemPrefixes.contact}
              />
            </Grid>
          </Grid>
        </PaperLocal>
      </Grid>
    </Grid>
  )
}

export default AccountForm
