import React, { useContext } from "react"
import { Container, Grid, Tab } from "@mui/material"
import { type ILocation, LOCATION_ENDPOINT } from "../../../../shared/models/service/ILocation"
import {
  type IInspectionRecommendation,
  INSPECTION_RECOMMENDATION_ENDPOINT,
} from "../../../../shared/models/service/IInspectionRecommendation"
import {
  type ILocationImpairment,
  LOCATION_IMPAIRMENT_ENDPOINT,
} from "../../../../shared/models/service/ILocationImpairment"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import { useApiRead } from "../../../../shared/hooks/useApiRead"
import TabPanel, { useTabPanel } from "../../../../shared/components/tabs/TabPanel"
import { useParams } from "react-router-dom"
import { useOnDemandPaged } from "../../../../shared/hooks/useApiPagedLocal"
import PageHeader from "../../../../shared/components/pages/PageHeader"
import { LOCATION_EDIT_URL } from "../../config/urls"
import FilesIndex from "../../../../shared/components/files/FilesIndex"
import { type IFile, LOCATION_FILE_ENDPOINT } from "../../../../shared/models/service/IFile"
import InspectionRecommendationIndex from "../inspection_recommendations/components/InspectionRecommendationIndex"
import ImpairmentIndex from "../../../../shared/pages/impairments/components/ImpairmentIndex"
import InspectionIndex from "../inspections/components/InspectionIndex"
import { type IInspection, INSPECTION_ENDPOINT } from "../../../../shared/models/service/IInspection"
import TabsList from "../../../../shared/components/tabs/TabsList"
import { type IFilter } from "../../../../shared/models/components/IFilter"
import { type IMainModel } from "../../../../shared/models/service/IMainModel"
import PaperLocal from "../../../../shared/components/containers/PaperLocal"
import ViewProperty from "../../../../shared/components/ViewProperty"
import ViewMap from "../../../../shared/components/display/ViewMap"
import ViewArea from "../../../../shared/components/display/ViewArea"
import { BUILDING_ENDPOINT, type IBuilding } from "../../../../shared/models/service/IBuilding"
import ItemViewerDrawer from "../../../../shared/components/item_viewer/ItemViewerDrawer"
import { ItemPrefixes } from "../../../../config/config"
import BuildingIndex from "../buildings/components/BuildingIndex"
import ViewListItems from "../../../../shared/components/display/ViewListItems"
import ViewRichText from "../../../../shared/components/display/ViewRichText"
import InspectionRecommendationInfo from "../inspection_recommendations/components/InspectionRecommendationInfo"
import ServiceUserInfo from "../service_users/components/ServiceUserInfo"
import type { IPanariskAppContext } from "../../../../shared/models/app/IPanariskAppContext"
import { PanariskAppContext } from "../../../../app/PanariskApp"
import MetadataDisplay from "../../../../shared/components/metadata/MetadataDisplay"
import { useMetadataExists } from "../../../../shared/components/metadata/UseMetadataExists"

const repository = new RestRepository<ILocation>(LOCATION_ENDPOINT)
const filesRepository = new RestRepository<IFile | IMainModel>(LOCATION_FILE_ENDPOINT)

/**
 * Render the view page for a locations.
 *
 * @returns {React.ReactElement} element containing the view page.
 */
const ViewPage: React.FC = (): React.ReactElement => {
  const { data, call } = useApiRead<ILocation>({ apiFunction: repository.read })
  const { tab, handleTabChange } = useTabPanel()
  const { id } = useParams()

  const metadataExists = useMetadataExists("location")
  const { appSettings } = useContext<IPanariskAppContext>(PanariskAppContext)
  const units = appSettings?.serverInfo?.units

  const theFilter: IFilter = {
    canBeDelete: false,
    title: "Location",
    display: "This location",
    field: "location",
    value: id,
  }

  const recFilter: IFilter[] = [{ ...theFilter, field: "inspection__location" }]
  const recsPagingResults = useOnDemandPaged<IInspectionRecommendation>(
    INSPECTION_RECOMMENDATION_ENDPOINT,
    recFilter,
    "RECOMMENDATIONS",
  )
  const buildingPagingResults = useOnDemandPaged<IBuilding>(BUILDING_ENDPOINT, [theFilter], "BUILDINGS")
  const impairmentsPagingResults = useOnDemandPaged<ILocationImpairment>(
    LOCATION_IMPAIRMENT_ENDPOINT,
    [theFilter],
    "IMPAIRMENTS",
  )
  const inspectionPagingResults = useOnDemandPaged<IInspection>(INSPECTION_ENDPOINT, [theFilter], "INSPECTIONS")
  const filesPagingResults = useOnDemandPaged<IFile>(LOCATION_FILE_ENDPOINT, [theFilter], "FILES")

  return (
    <Container>
      {data !== undefined && (
        <>
          <Grid container alignItems="end" spacing={2}>
            <PageHeader
              title="Location"
              titlePlural={`${data.name} ${data.identifier}`}
              toEdit={`${LOCATION_EDIT_URL}/${data.id}`}
              onRefresh={call}
            />
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TabsList value={tab} onChange={handleTabChange}>
                <Tab label="Overview" value={0} />
                <Tab label="Buildings" value={1} />
                <Tab label="Inspections" value={4} />
                <Tab label="Recommendations" value={2} />
                <Tab label="Impairments" value={3} />
                <Tab label="Files" value={5} />
              </TabsList>
              <TabPanel value={tab} index={0}>
                <ItemViewerDrawer title={"Service User"} prefix={ItemPrefixes.serviceUser} infoView={ServiceUserInfo} />
                <ItemViewerDrawer
                  title={"Recommendations"}
                  prefix={ItemPrefixes.inspectionRec}
                  infoView={InspectionRecommendationInfo}
                />
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <PaperLocal>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <ViewProperty label="Primary Staff">
                            <ViewListItems prefix={ItemPrefixes.serviceUser}>{data.primary_staff}</ViewListItems>
                          </ViewProperty>
                        </Grid>
                        <Grid item xs={12}>
                          <ViewProperty label="Support Staff">
                            <ViewListItems prefix={ItemPrefixes.serviceUser}>{data.support_staff}</ViewListItems>
                          </ViewProperty>
                        </Grid>
                      </Grid>
                    </PaperLocal>
                    <PaperLocal sx={{ mt: 2 }}>
                      <Grid item xs={12}>
                        <ViewProperty label="Buildings">{data.buildings.length}</ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label="Sum of Building Area">
                          <ViewArea units={units}>{data.area}</ViewArea>
                        </ViewProperty>
                      </Grid>
                    </PaperLocal>
                    {metadataExists && (
                      <PaperLocal sx={{ mt: 2 }}>
                        <MetadataDisplay modelName="location" data={data.metadata_data} />
                      </PaperLocal>
                    )}
                    <PaperLocal sx={{ mt: 2 }}>
                      <ViewProperty label="Notes" vertical>
                        <ViewRichText>{data.notes}</ViewRichText>
                      </ViewProperty>
                    </PaperLocal>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <PaperLocal>
                      <ViewMap place={data} />
                    </PaperLocal>
                    <PaperLocal sx={{ mt: 2 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <ViewProperty label="Address">{data.address}</ViewProperty>
                        </Grid>
                        <Grid item xs={12}>
                          <ViewProperty label="City">{data.city}</ViewProperty>
                        </Grid>
                        <Grid item xs={12}>
                          <ViewProperty label="State/Region">{data.state_region}</ViewProperty>
                        </Grid>
                        <Grid item xs={12}>
                          <ViewProperty label="Postal Code">{data.postal_code}</ViewProperty>
                        </Grid>
                        <Grid item xs={12}>
                          <ViewProperty label="Country">{data.country}</ViewProperty>
                        </Grid>
                      </Grid>
                    </PaperLocal>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tab} index={1} onChange={buildingPagingResults.call}>
                <BuildingIndex
                  useInfoView
                  pagingResults={buildingPagingResults}
                  location={data}
                  gotoFilters={[{ field: "location", value: data.id }]}
                />
              </TabPanel>
              <TabPanel value={tab} index={2} onChange={recsPagingResults.call}>
                <InspectionRecommendationIndex
                  useInfoView
                  pagingResults={recsPagingResults}
                  fullPage={false}
                  gotoFilters={[{ field: "inspection__location", value: data.id }]}
                />
              </TabPanel>
              <TabPanel value={tab} index={3} onChange={impairmentsPagingResults.call}>
                <ImpairmentIndex
                  useInfoView
                  pagingResults={impairmentsPagingResults}
                  showHeader={false}
                  gotoFilters={[{ field: "location", value: data.id }]}
                />
              </TabPanel>
              <TabPanel value={tab} index={4} onChange={inspectionPagingResults.call}>
                <InspectionIndex
                  useInfoView
                  pagingResults={inspectionPagingResults}
                  showHeader={false}
                  gotoFilters={[{ field: "location", value: data.id }]}
                />
              </TabPanel>
              <TabPanel value={tab} index={5} onChange={filesPagingResults.call}>
                <FilesIndex
                  parentId={id}
                  parentFieldName="location"
                  repository={filesRepository}
                  pagingResults={filesPagingResults}
                />
              </TabPanel>
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  )
}

export default ViewPage
