import React, { useCallback, useState } from "react"
import { Button, Grid } from "@mui/material"
import { useFilterDataAvailable } from "./FiltersData"
import { RestRepository } from "../../repositories/RestRepository"
import { type IListItem } from "../../models/components/IListItem"
import SelectFilteredSingle from "../forms/SelectFilteredSingle"
import AddIcon from "@mui/icons-material/Add"
import { type IFilter } from "../../models/components/IFilter"
import { PRIORITY_ENDPOINT } from "../../models/service/IPriority"
import { type PriorityTypes } from "../../../config/config"

interface IProps {
  field: string
  priorityType: PriorityTypes
  children?: React.JSX.Element[]
}

const repository = new RestRepository<IListItem>(PRIORITY_ENDPOINT)

/**
 * Filters a priority based on the provided props.
 *
 * @param {IProps} props - The props object containing the field to be filtered.
 * @returns {React.ReactElement} - The filtered priority component.
 */
const FilterPriority = (props: IProps): React.ReactElement => {
  const { field, priorityType } = props

  const [item, setItem] = useState<IListItem | null>(null)
  const filtersDataContext = useFilterDataAvailable(field, "Priority")

  const handleAddFilter = useCallback(() => {
    const filter: IFilter = { title: "Priority", field, value: item?.id, display: item?.name }
    filtersDataContext?.addFilter(filter)
  }, [item, field, filtersDataContext?.addFilter])

  return (
    <>
      {filtersDataContext?.selectedFilter?.value === field && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <SelectFilteredSingle
              name="priority"
              label="Priority"
              defaultValue={item}
              repository={repository}
              filters={[{ field: "priority_type", value: priorityType }]}
              onChange={setItem}
            />
          </Grid>
          <Grid item xs />
          <Grid item>
            <Button startIcon={<AddIcon />} onClick={handleAddFilter}>
              Add Filter
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default FilterPriority
