import {
  Alert,
  alpha,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  type Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import React, { type ChangeEvent, type FormEvent, useCallback, useContext, useState } from "react"
import useWindowResize from "beautiful-react-hooks/useWindowResize"
import useAuth from "../../hooks/useAuth"
import { APP_VERSION, COMPANY_NAME } from "../../../config/config"
import { PanariskAppContext } from "../../../app/PanariskApp"
import useEffectInit from "../../hooks/useEffectInit"
import { Link, useSearchParams } from "react-router-dom"
import { LOGIN_URL } from "../../../apps/admin/config/urls"
import ErrorMessage from "../../components/ErrorMessage"
import ViewLoading from "../../components/ViewLoading"
import { useAxiosRequest } from "../../hooks/useAxiosRequest"
import PasswordStrengthBar from "react-password-strength-bar"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import ViewImage from "../../components/display/ViewImage"
// MSAL imports

const REACT_APP_DEV = process.env.REACT_APP_DEV === "true"

/**
 * Represents the password reset page of the application.
 *
 * @returns {React.ReactElement} The rendered password reset page.
 */
const PasswordResetPage: React.FC = (): React.ReactElement => {
  const [passwordResetSuccessful, setPasswordResetSuccessful] = useState(false)
  const [password, setPassword] = useState("")

  const [showPassword, setShowPassword] = useState(false)

  const { clearUser } = useAuth()
  const { appSettings, siteName } = useContext(PanariskAppContext)

  const [searchParams] = useSearchParams()

  const isSmall = useMediaQuery(useTheme().breakpoints.down("md"))
  const [windowHeight, setWindowHeight] = useState(window !== undefined ? window.innerHeight : 600)

  const { changePasswordWithToken } = useAuth()

  const { callRequest, loading, errorMessage, error } = useAxiosRequest()

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault()
      await callRequest(async () => {
        const token = searchParams.get("token")
        if (changePasswordWithToken !== null && token !== null) {
          await changePasswordWithToken(password, token)
          setPasswordResetSuccessful(true)
        }
      })
    },
    [changePasswordWithToken, searchParams, password],
  )

  const handlePasswordChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }, [])

  const handleClickShowPassword = useCallback(() => {
    setShowPassword(show => !show)
  }, [])

  const handleMouseDownPassword = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }, [])

  useWindowResize()(() => {
    setWindowHeight(window.innerHeight)
  })

  useEffectInit(async () => {
    clearUser?.()
  }, [])

  return (
    <Box sx={isSmall ? {} : { height: windowHeight }}>
      <Box sx={{ position: "absolute", p: 2, top: 0 }}>
        <ViewImage src={appSettings.serverInfo?.logo_large.read_url} />
      </Box>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs md={8}>
          <Grid
            container
            sx={(theme: Theme) =>
              isSmall
                ? { mt: 10 }
                : {
                    width: "100hw",
                    height: "100vh",
                    backgroundColor: alpha(theme.palette.text.secondary, 0.05),
                  }
            }
            alignItems="center"
            justifyContent="center"
            direction="column">
            <Grid item xs={isSmall ? 12 : undefined}>
              <Typography variant="h1" component="h1" sx={{ color: "primary.main", fontWeight: 500, lineHeight: 0.75 }}>
                {appSettings?.serverInfo?.company_name}
              </Typography>
              <Typography variant="h5" component="h3" sx={{ color: "secondary.main", ml: 0.75 }}>
                {siteName}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs md={4}>
          <Paper elevation={0} variant="elevation" sx={{ p: 2, mr: 4, ml: 4 }}>
            <Typography variant="h5" component="h3" gutterBottom sx={{ color: "primary.main", fontWeight: 600 }}>
              Reset your password for {siteName}
              {REACT_APP_DEV && (
                <sup>
                  <small>DEV</small>
                </sup>
              )}
            </Typography>
            <Box sx={{ mt: 4 }}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Alert severity="info">Enter your new password.</Alert>
                  </Grid>
                  <Grid item xs={12}>
                    <ViewLoading inline loading={loading} />
                  </Grid>
                  {error && (
                    <Grid item xs={12}>
                      <ErrorMessage error={errorMessage} />
                    </Grid>
                  )}
                  {passwordResetSuccessful ? (
                    <>
                      <Grid item xs={12}>
                        <Alert severity="success">
                          Your password has been reset. You can login with your new password.
                        </Alert>
                      </Grid>
                      <Grid item xs />
                      <Grid item>
                        <Button component={Link} to={LOGIN_URL}>
                          Login
                        </Button>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-password"
                            label="New Password"
                            type={showPassword ? "text" : "password"}
                            name="new_password"
                            value={password}
                            fullWidth
                            onChange={handlePasswordChange}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end">
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                        <PasswordStrengthBar password={password} />
                      </Grid>
                      <Grid item xs>
                        <Button component={Link} to={LOGIN_URL}>
                          Login
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button variant="contained" color="secondary" type="submit">
                          Reset Password
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>
              </form>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Box sx={{ position: "absolute", bottom: 0, p: 1 }}>
        <Typography>
          © {COMPANY_NAME} {new Date().getFullYear()}
          <Box component="small" sx={{ ml: 1 }}>
            v{APP_VERSION}
          </Box>
        </Typography>
      </Box>
    </Box>
  )
}

export default PasswordResetPage
