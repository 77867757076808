import React from "react"
import { TableCell } from "@mui/material"
import FormatNumber from "../format/FormatNumber"

interface IProps {
  children: number | null | undefined
  field?: string
}

/**
 * Renders a TableCell with formatted percent value.
 *
 * @param {IProps} props - The component props.
 * @returns {React.ReactElement} - The rendered TableCell with formatted percent value.
 */
const TableCellPercent: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children } = props

  return (
    <TableCell sx={{ textAlign: "center" }}>
      {children !== undefined && children !== null && (
        <FormatNumber value={children} prefixUnits={false} suffixUnits="%" />
      )}
    </TableCell>
  )
}

export default TableCellPercent
