import * as React from "react"
import { type IRiskWriterSection } from "../models/IRiskWriterSection"
import { type IRiskWriterSectionData, SectionType } from "../models/IRiskWriterSectionData"
import ContentTextEditor from "./content_editors/ContentTextEditor"
import ContentImageEditor from "./content_editors/ContentImageEditor"
import ContentTableEditor from "./content_editors/ContentTableEditor"

interface IProps {
  section: IRiskWriterSection
  content: IRiskWriterSectionData
  viewerOnly?: boolean
  small?: boolean
}

/**
 * Represents a content editor component.
 *
 * @param {IProps} props - The props object containing the input data.
 * @returns {React.ReactElement} The rendered content editor component.
 */
const RiskWriterContentEditor: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { section, content, viewerOnly = false, small = false } = props

  return (
    <>
      {content.section_type === SectionType.RICH_TEXT_EDITOR && (
        <ContentTextEditor content={content} section={section} viewerOnly={viewerOnly} />
      )}
      {content.section_type === SectionType.IMAGE_EDITOR && (
        <ContentImageEditor content={content} section={section} viewerOnly={viewerOnly} />
      )}
      {content.section_type === SectionType.TABLE_EDITOR && (
        <ContentTableEditor content={content} section={section} viewerOnly={viewerOnly} small={small} />
      )}
    </>
  )
}

export default RiskWriterContentEditor
