import React, { useCallback, useContext, useMemo } from "react"
import RiskWriterProvider from "../risk_writer/context/RiskWriterProvider"
import RiskWriter, {
  RiskWriterBrief,
  RiskWriterBuilder,
  RiskWriterBuildings,
  RiskWriterRecommendations,
} from "../risk_writer/RiskWriter"
import BuildReportDialog from "../risk_writer_support/BuildReportDialog"
import InspectionBuildingIndex from "../inspection_buildings/components/InspectionBuildingIndex"
import AddBuilding from "../inspection_buildings/components/AddBuilding"
import InspectionRecommendationIndex from "../../../apps/admin/pages/inspection_recommendations/components/InspectionRecommendationIndex"
import RiskWriterFormView from "../risk_writer/components/RiskWriterFormView"
import { type IInspection, INSPECTION_ENDPOINT } from "../../models/service/IInspection"
import { type IRiskWriterSection } from "../risk_writer/models/IRiskWriterSection"
import { type IPaging } from "../../models/components/IPaging"
import ViewLoading from "../ViewLoading"
import { buildApiAction } from "../../hooks/useApiAction"
import { RestRepository } from "../../repositories/RestRepository"
import { type IMainModel } from "../../models/service/IMainModel"
import { type IRiskWriterData } from "../risk_writer/models/IRiskWriterData"
import { useAxiosRequest } from "../../hooks/useAxiosRequest"
import { ImageChooserContext } from "../files/ImageChooser"
import { type IFile, INSPECTION_FILE_ENDPOINT } from "../../models/service/IFile"
import { useItemEditDialogUrl } from "../item_viewer/ItemViewerDrawer"
import { ItemPrefixes } from "../../../config/config"
import { type IUseApiPagedResultsResponse } from "../../hooks/useApiPagedLocal"
import { type IInspectionBuilding } from "../../models/service/IInspectionBuilding"
import { type IInspectionRecommendation } from "../../models/service/IInspectionRecommendation"
import { type IRiskWriteModel } from "../../models/components/IRiskWriteModel"
import { type IRiskWriterFormat } from "../risk_writer/models/IRiskWriterFormat"
import type { IPanariskAppContext } from "../../models/app/IPanariskAppContext"
import { PanariskAppContext } from "../../../app/PanariskApp"
import useAuth, { type IProfilePatch } from "../../hooks/useAuth"
import type { IFilter } from "../../models/components/IFilter"
import { Alert, Box } from "@mui/material"
import { checkRiskWriterHasBrief, riskWriterBriefTitle } from "../../models/service/IReportFormat"
import { useTranslation } from "react-i18next"

const inspectionRepository = new RestRepository<IFile>(INSPECTION_ENDPOINT)
const inspectionFileRepository = new RestRepository<IFile>(INSPECTION_FILE_ENDPOINT)

interface IReportDataPath extends IMainModel {
  risk_writer_data: IRiskWriterData | null
}

export interface IGeneratedContent extends IMainModel {
  content: string
}

interface IProps {
  data: IRiskWriteModel | IInspection
  reportWriterFormat: IRiskWriterFormat | null | undefined
  title: string
  onReloadReport: () => Promise<void>
  onRefreshTableData?: () => Promise<void>
  enableAddBuilding?: boolean
  buildingPagingResults?: IUseApiPagedResultsResponse<IInspectionBuilding>
  recPagingResults?: IUseApiPagedResultsResponse<IInspectionRecommendation>
  repository: RestRepository<IMainModel>
  genContentRepository: RestRepository<IGeneratedContent>
  filesRepository: RestRepository<IFile | IMainModel>
}

/**
 * Component for viewing and editing risk writer data.
 *
 * @param {IProps} props - The props of the component.
 * @returns {React.ReactElement} The rendered component.
 */
const ViewRiskWriterInspection: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const {
    data,
    reportWriterFormat,
    title,
    buildingPagingResults,
    enableAddBuilding = true,
    recPagingResults,
    onReloadReport,
    onRefreshTableData,
    repository,
    genContentRepository,
    filesRepository,
  } = props

  const { appSettings } = useContext<IPanariskAppContext>(PanariskAppContext)
  const units = appSettings?.serverInfo?.units
  const { currentUser, updateProfile, loading: authLoading } = useAuth()

  const { setDrawerOpen, fileSelected } = useContext(ImageChooserContext)
  const axiosRequest = useAxiosRequest()
  const genContentApiAction = buildApiAction<IGeneratedContent>(genContentRepository, data.id)

  const editBriefUrl = useItemEditDialogUrl(ItemPrefixes.inspectionBrief, data.id)

  const hasRiskWriterBrief = useMemo(() => checkRiskWriterHasBrief(data as IInspection), [data])
  const briefTitle = useMemo(() => riskWriterBriefTitle(data as IInspection), [data])
  const { t } = useTranslation()

  const handleFileRequest = useCallback(() => {
    setDrawerOpen?.(true)
  }, [])

  const handleFileCaptionChange = useCallback(async (file: IFile) => {
    await axiosRequest.callRequest(async () => {
      await (filesRepository as RestRepository<IFile>).patch(file, file.id)
    })
  }, [])

  const handleGetFile = useCallback(async (fileId: number) => {
    let file = null
    await axiosRequest.callRequest(async () => {
      file = await (filesRepository as RestRepository<IFile>).read(fileId)
    })
    return file
  }, [])

  const handleSave = useCallback(
    async (reportWriterData: IRiskWriterData) => {
      if (data?.id !== undefined) {
        await axiosRequest.callRequest(async () => {
          const reportData: IReportDataPath = {
            id: data.id,
            name: data.name,
            risk_writer_data: reportWriterData,
          }
          await repository.patch(reportData, data.id)
        })
      }
    },
    [data],
  )

  const handleGenerateContent = useCallback(
    async (section: IRiskWriterSection): Promise<string | null> => {
      if (data?.id !== undefined) {
        let content = null
        const paging: IPaging = { filters: [{ field: "content", value: section.name }] }
        const response = await genContentApiAction.callAction<IGeneratedContent>("generate_content", paging)
        if (response?.content !== undefined) {
          content = response.content
        }
        return content
      }
      return null
    },
    [data?.id],
  )

  const handleReportWriterOpen = useCallback(async () => {
    await buildingPagingResults?.call()
    await recPagingResults?.call()
  }, [])

  const handleAutoSave = useCallback(async () => {
    if (currentUser?.user !== undefined) {
      const profile: IProfilePatch = {
        id: currentUser.user.profile.id,
        name: "",
        auto_save_risk_writer: !currentUser.user.profile.auto_save_risk_writer,
      }
      await updateProfile?.(profile)
    }
  }, [currentUser?.user?.profile.auto_save_risk_writer])

  return (
    <>
      <ViewLoading loading={genContentApiAction.saving || authLoading} />
      <RiskWriterProvider
        units={units}
        autoSave={currentUser?.user?.profile.auto_save_risk_writer}
        onToggleAutoSave={handleAutoSave}
        format={reportWriterFormat}
        data={data.risk_writer_data}
        onGenerateContent={handleGenerateContent}
        onSave={handleSave}
        onFileCaptionChange={handleFileCaptionChange}
        onFileRequest={handleFileRequest}
        onReloadReport={onReloadReport}
        fileSelected={fileSelected}
        getFile={handleGetFile}>
        <RiskWriter
          onOpen={handleReportWriterOpen}
          editBriefUrl={editBriefUrl}
          title={title}
          onClose={onReloadReport}
          onRefreshTableData={onRefreshTableData}>
          <RiskWriterBuilder>
            <BuildReportDialog
              itemId={data.id}
              modelName="inspection"
              repository={inspectionRepository}
              fileRepository={inspectionFileRepository}
            />
          </RiskWriterBuilder>
          {buildingPagingResults !== undefined ? (
            <RiskWriterBuildings>
              <InspectionBuildingIndex
                useInfoView
                itemDelete
                format={reportWriterFormat}
                pagingResults={buildingPagingResults}
                gotoFilters={[{ field: "inspection", value: data.id }] as IFilter[]}
                tableActions={
                  enableAddBuilding ? (
                    <AddBuilding inspection={data as IInspection} onComplete={buildingPagingResults.call} />
                  ) : undefined
                }
              />
            </RiskWriterBuildings>
          ) : (
            <></>
          )}
          {recPagingResults !== undefined ? (
            <RiskWriterRecommendations>
              <InspectionRecommendationIndex
                useInfoView
                currentInspection={data as IInspection}
                pagingResults={recPagingResults}
                gotoFilters={[{ field: "inspection", value: data.id }] as IFilter[]}
                fullPage={false}
                itemDelete
              />
            </RiskWriterRecommendations>
          ) : (
            <></>
          )}

          {hasRiskWriterBrief ? (
            <RiskWriterBrief>
              {data.risk_writer_brief_data !== null ? (
                <Box>
                  <RiskWriterFormView
                    formType="brief"
                    units={units}
                    format={reportWriterFormat}
                    fileEndpoint={INSPECTION_FILE_ENDPOINT}>
                    {data.risk_writer_brief_data}
                  </RiskWriterFormView>
                </Box>
              ) : (
                <Alert severity="warning">{t(`${t(briefTitle)} not started.`)}</Alert>
              )}
            </RiskWriterBrief>
          ) : (
            <></>
          )}
        </RiskWriter>
      </RiskWriterProvider>
    </>
  )
}

export default ViewRiskWriterInspection
