import React from "react"
import ReactDOM from "react-dom/client"
// import reportWebVitals from "./reportWebVitals"
import PanariskSuite from "./pr_core_frontend/src/panarisk/app/PanariskSuite"
import AppWithInsights from "./pr_core_frontend/src/panarisk/shared/components/AppWithInsights"
import versionsJson from "./versions.json"
import { type IVersions } from "./pr_core_frontend/src/panarisk/shared/models/app/IVersions"

const rootElement = document.getElementById("root")
if (rootElement !== null) {
  const root = ReactDOM.createRoot(rootElement)

  root.render(
    <React.StrictMode>
      <AppWithInsights>
        <PanariskSuite versions={versionsJson as IVersions} />
      </AppWithInsights>
    </React.StrictMode>,
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
