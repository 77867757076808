import { Controller, type FieldValues, type RegisterOptions } from "react-hook-form"
import React from "react"
import { type ReactElement } from "react"
import { type Control } from "react-hook-form/dist/types/form"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import moment from "moment"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { nameToLabel } from "../../utilities/form_utility"
import { Box, Grid, type SxProps } from "@mui/material"
import HtmlToolTip from "../HtmlToolTip"
import InfoIcon from "@mui/icons-material/Info"

interface IProps {
  control: Control
  defaultValue?: moment.Moment | null
  label?: string | null
  name: string
  rules?:
    | Omit<RegisterOptions<FieldValues, string>, "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled">
    | undefined
  sx?: SxProps
  helperText?: string
  disabled?: boolean
}

/**
 * A forms hook MUI date field.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the date field.
 */
const FhMuiDateField: React.FC<IProps> = (props: IProps): ReactElement => {
  const { control, defaultValue = null, name, label, rules = {}, sx, helperText = null, disabled = false } = props

  const theLabel = nameToLabel(name, label)

  return (
    <Grid container>
      <Grid item xs>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
            render={({ field }) => {
              let value: string | moment.Moment = field.value
              if (typeof field.value === "string" || field.value instanceof String) {
                value = moment(field.value as string)
              }
              if (value !== null) {
                value = (value as moment.Moment).startOf("day")
              }
              return (
                <DatePicker
                  sx={sx}
                  label={theLabel}
                  disabled={disabled}
                  onChange={field.onChange}
                  value={value}
                  inputRef={field.ref}
                  slotProps={{
                    textField: { fullWidth: true },
                    popper: { sx: { zIndex: 1500 } },
                    desktopPaper: { sx: { p: 0 } },
                  }}
                />
              )
            }}
          />
        </LocalizationProvider>
      </Grid>
      {helperText !== null && (
        <Grid item>
          <Box sx={{ position: "relative", marginLeft: -3, marginTop: 0, cursor: "pointer" }}>
            <HtmlToolTip title={<Box dangerouslySetInnerHTML={{ __html: helperText }} />} placement="left">
              <InfoIcon color="secondary" />
            </HtmlToolTip>
          </Box>
        </Grid>
      )}
    </Grid>
  )
}

export default FhMuiDateField
