import React, { useCallback, useContext, useRef, useState } from "react"
import {
  Alert,
  AppBar,
  Badge,
  Box,
  Button,
  Container, type CSSObject,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  type SxProps,
  type Theme,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material"
import RiskWriterToc from "./components/RiskWriterToc"
import MenuIcon from "@mui/icons-material/Menu"
import MoreIcon from "@mui/icons-material/MoreVert"
import TruncateText from "../TruncateText"
import { RiskWriterContext } from "./context/RiskWriterProvider"
import AutoModeIcon from "@mui/icons-material/AutoMode"
import type { IRiskWriterSection } from "./models/IRiskWriterSection"
import RiskWriterTableEditor from "./components/RiskWriterTableEditor"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import useLocalStorage from "beautiful-react-hooks/useLocalStorage"
import RiskWriterSection from "./components/RiskWriterSection"
import { getBaseApp } from "../../utilities/request_utility"
import EditIcon from "@mui/icons-material/Edit"
import ListItemIcon from "@mui/material/ListItemIcon"
import CheckIcon from "@mui/icons-material/Check"
import UploadIcon from "@mui/icons-material/Upload"
import { Link } from "react-router-dom"
import { useDrawerWithUrl } from "../containers/DrawerRight"
import { useTranslation } from "react-i18next"
import { nameToLabel } from "../../utilities/form_utility"

/**
 * Generates a style object for a table of contents container.
 *
 * @param {Theme} theme - The theme object used for styling.
 * @returns {CSSObject} The style object containing CSS properties.
 */
const tableOfContentsSx = (theme: Theme): CSSObject => ({
  overflow: "auto",
  pt: 4,
  height: "calc(100vh - 68px)",
  scrollBehavior: "smooth",
  backgroundColor: theme.palette.background.paper,
})

/**
 * Generates the SxProps object for rendering a report.
 *
 * @param {boolean} isSmall - A flag indicating if the report should render in a small size.
 * @returns {SxProps} - The generated SxProps object.
 */
const reportRenderSx = (isSmall: boolean): SxProps => ({
  overflow: "auto",
  height: "calc(100vh - 80px)",
  pl: isSmall ? 1 : 2,
  pr: isSmall ? 1 : 2,
  pt: 1,
  scrollBehavior: "smooth",
})

interface IComponentProps {
  children: React.JSX.Element
}

/**
 * RiskWriterBuilder is a React component that allows building of the report.
 *
 * @param {IComponentProps} props - The component props.
 * @returns {React.ReactElement} The rendered React element.
 */
export function RiskWriterBuilder(props: IComponentProps): React.ReactElement {
  return <>{props.children}</>
}

/**
 * Renders a report writer for buildings.
 *
 * @param {IComponentProps} props - The properties for the component.
 * @returns {React.ReactElement} The rendered React element.
 */
export function RiskWriterBuildings(props: IComponentProps): React.ReactElement {
  return <>{props.children}</>
}

/**
 * Renders the ReportWriterRecommendations component.
 *
 * @param {IComponentProps} props - The component props.
 * @returns {React.ReactElement} The rendered component.
 */
export function RiskWriterRecommendations(props: IComponentProps): React.ReactElement {
  return <>{props.children}</>
}

/**
 * Renders a brief of the report writer.
 *
 * @param {IComponentProps} props - The component props.
 * @returns {React.ReactElement} The rendered brief.
 */
export function RiskWriterBrief(props: IComponentProps): React.ReactElement {
  return props.children
}

interface IRiskWriterPartProps {
  name: string
  title: string
  children: React.ReactNode
  action?: React.ReactNode
}

/**
 * Renders a Risk Writer part component.
 *
 * @param {IRiskWriterPartProps} props - The props for the component.
 * @param {string} props.name - The unique identifier of the component.
 * @param {string} props.title - The title to display in the component.
 * @param {React.ReactNode} props.children - The content to display in the component.
 * @returns {React.ReactElement} The rendered Risk Writer part component.
 */
const RiskWriterPart: React.FC<IRiskWriterPartProps> = (props: IRiskWriterPartProps): React.ReactElement => {
  const { name, title, children, action } = props

  const storageKey = `RISK_WRITER_PART_${getBaseApp()}_${name}`
  const [show, setShow] = useLocalStorage<boolean>(storageKey, true)

  const handleToggleShow = useCallback(() => {
    setShow(show1 => !show1)
  }, [])

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Grid container spacing={1} id={name}>
        <Grid item xs={12}>
          <Alert
            severity="info"
            action={
              <Grid container spacing={1}>
                <Grid item>{show === true && action}</Grid>
                <Grid item>
                  <IconButton onClick={handleToggleShow}>
                    {show === true ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </Grid>
              </Grid>
            }>
            {title}
          </Alert>
        </Grid>
        <Grid item xs={12}>
          {show === true && children}
        </Grid>
      </Grid>
    </Box>
  )
}

interface IProps {
  children: React.JSX.Element[] | React.JSX.Element
  onOpen?: () => void
  onClose?: () => void
  onRefreshTableData?: () => Promise<void>
  title?: string
  editBriefUrl?: string
}

/**
 * A component for rendering the Report Writer.
 *
 * @param {IProps} props - The component props.
 * @returns {React.FC<IProps>} The RiskWriter component.
 */
const RiskWriter: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children, onOpen, onClose, title, /* onRefreshTableData, */ editBriefUrl } = props
  const { t } = useTranslation()

  const drawerUrl = useDrawerWithUrl()
  const storageKey = `RISK_WRITER_${getBaseApp()}_SHOW_MENU`
  const [showMenu, setShowMenu] = useLocalStorage<boolean>(storageKey, true)

  const [open, setOpen] = useState<boolean>(false)
  const [openMenu, setOpenMenu] = useState<boolean>(false)
  const dialogContentRef = useRef<HTMLDivElement | null>(null)
  const contentRef = useRef<HTMLDivElement | null>(null)

  const [anchorMenuEl, setAnchorMenuEl] = useState<null | HTMLElement>(null)

  const {
    reportWriterFormat,
    autoSave,
    onSave,
    onToggleAutoSave,
    // reloadReport,
    reportWriterData,
    reportWriterDataDiff,
    setCurrentContent,
    setCurrentSection,
    setCurrentFieldGroup,
    onGenerateAll,
    onClearContent,
  } = useContext(RiskWriterContext)

  const isSmall = useMediaQuery(useTheme().breakpoints.down("lg"))

  const handleClose = useCallback(() => {
    setOpen(false)
    setCurrentSection?.(null)
    setCurrentContent?.(null)
    setCurrentFieldGroup?.(null)
    setAnchorMenuEl(null)
    onClose?.()
  }, [])

  const handleSave = useCallback(async () => {
    await onSave?.()
    setAnchorMenuEl(null)
  }, [onSave])

  const handleCloseMenu = useCallback(async () => {
    setAnchorMenuEl(null)
  }, [onSave])

  const handleSaveAndClose = useCallback(async () => {
    await onSave?.()
    handleClose()
  }, [onSave])

  const handleOpen = useCallback(() => {
    onOpen?.()
    setOpen(true)
  }, [])

  const handleOpenMenu = useCallback(() => {
    setOpenMenu(true)
  }, [])

  const handleToggleShowMenu = useCallback(() => {
    setShowMenu(showMenu1 => !showMenu1)
  }, [])

  const handleNavigation = useCallback(() => {
    setOpenMenu(false)
  }, [])

  // const handleReportReportFormat = useCallback(async () => {
  //   setAnchorMenuEl(null)
  //   await reloadReport?.()
  // }, [])
  //
  // const handleRefreshTables = useCallback(async () => {
  //   setAnchorMenuEl(null)
  //   await onRefreshTableData?.()
  // }, [])

  const openNavMenu = Boolean(anchorMenuEl)
  const handleMenuClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorMenuEl(event.currentTarget)
  }, [])

  const handleMenuClose = useCallback(() => {
    setAnchorMenuEl(null)
  }, [])

  const components = Array.isArray(children) ? children : [children]

  const renderComponent = useCallback(
    (name: any): React.JSX.Element[] => {
      return components
        .filter((child: React.JSX.Element) => child.type === name)
        .map((child: React.JSX.Element) => child)
    },
    [components],
  )

  const hasRenderComponent = useCallback(
    (name: any): boolean => {
      return components.some((child: React.JSX.Element) => child.type === name)
    },
    [components],
  )

  return (
    <>
      <Button onClick={handleOpen} variant="contained" color="secondary" disableElevation startIcon={<EditIcon />}>
        Edit Report
      </Button>

      {reportWriterFormat !== null && reportWriterData !== null && (
        <Dialog fullScreen fullWidth open={openMenu} ref={dialogContentRef} sx={{ zIndex: 1301 }}>
          <RiskWriterToc
            onNavigation={handleNavigation}
            showBuildings={hasRenderComponent(RiskWriterBuildings)}
            showRecommendations={hasRenderComponent(RiskWriterRecommendations)}
          />
        </Dialog>
      )}

      <Dialog fullScreen fullWidth open={open} ref={dialogContentRef} sx={{ zIndex: 1300 }}>
        <Box sx={{ "& .MuiPaper-root": { p: 0 } }}>
          <AppBar sx={{ position: "relative" }} color="secondary">
            <Grid container alignItems="center" sx={{ pr: 2, pl: 2, pt: 1, pb: 1 }}>
              <Grid item>
                {isSmall && (
                  <IconButton edge="start" color="inherit" onClick={handleOpenMenu}>
                    <MenuIcon />
                  </IconButton>
                )}
              </Grid>
              {!isSmall && (
                <Grid item xs>
                  <Typography variant="h6">Report Writer</Typography>
                </Grid>
              )}
              <Grid item xs sx={{ textAlign: "center" }}>
                <Typography variant="h6">
                  <TruncateText>{title}</TruncateText>
                </Typography>
              </Grid>
              <Grid item xs sx={{ textAlign: "right" }}>
                {!isSmall && (
                  <>
                    <Button autoFocus color="inherit" onClick={onSave}>
                      <Badge badgeContent={reportWriterDataDiff?.length ?? 0} color="warning" variant="dot">
                        Save
                      </Badge>
                    </Button>
                    {renderComponent(RiskWriterBuilder)}
                  </>
                )}
                <Button color="inherit" onClick={handleClose}>
                  Close
                </Button>
                <IconButton edge="end" color="inherit" onClick={handleMenuClick}>
                  <MoreIcon />
                </IconButton>
                <Menu anchorEl={anchorMenuEl} open={openNavMenu} onClose={handleMenuClose}>
                  {/* <MenuItem onClick={handleReportReportFormat}>Reload Report Format</MenuItem> */}
                  {/* <MenuItem onClick={handleRefreshTables}>Refresh Tables</MenuItem> */}
                  {/* <Divider /> */}
                  {isSmall && renderComponent(RiskWriterBuilder)}
                  <MenuItem component={Link} to={drawerUrl("file_upload")} onClick={handleCloseMenu}>
                    <ListItemIcon>
                      <UploadIcon />
                    </ListItemIcon>
                    File Upload
                  </MenuItem>
                  <MenuItem onClick={onToggleAutoSave}>
                    <ListItemIcon>{autoSave && <CheckIcon />}</ListItemIcon>
                    Autosave
                  </MenuItem>
                  <Divider />
                  {isSmall && (
                    <MenuItem onClick={handleSave}>
                      <Badge badgeContent={reportWriterDataDiff?.length ?? 0} color="warning" variant="dot">
                        Save
                      </Badge>
                    </MenuItem>
                  )}
                  <MenuItem onClick={handleSaveAndClose}>Save &amp; Close</MenuItem>
                  <MenuItem onClick={handleClose}>Close</MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </AppBar>
        </Box>
        <DialogContent sx={{ p: 0, overflow: "hidden" }}>
          {reportWriterFormat !== null && (
            <Grid container>
              {!isSmall && (
                <Grid item>
                  <Box sx={{ ml: 1, mt: 1, position: "absolute" }}>
                    <IconButton onClick={handleToggleShowMenu}>
                      <MenuIcon />
                    </IconButton>
                  </Box>
                </Grid>
              )}

              {!isSmall && showMenu === true && (
                <Grid item xs={3}>
                  <Box sx={tableOfContentsSx}>
                    <RiskWriterToc
                      showBuildings={hasRenderComponent(RiskWriterBuildings)}
                      showRecommendations={hasRenderComponent(RiskWriterRecommendations)}
                    />
                  </Box>
                </Grid>
              )}

              <Grid item xs={isSmall || showMenu !== true ? 12 : 9}>
                <Box ref={contentRef} sx={reportRenderSx(isSmall)}>
                  <Container>
                    {hasRenderComponent(RiskWriterBuildings) && (
                      <RiskWriterPart name="buildings" title="Buildings">
                        {renderComponent(RiskWriterBuildings)}
                      </RiskWriterPart>
                    )}
                    {reportWriterFormat?.tables !== undefined && reportWriterFormat.tables !== null && (
                      <RiskWriterPart name="tables" title="Tables">
                        <RiskWriterTableEditor />
                      </RiskWriterPart>
                    )}
                    {hasRenderComponent(RiskWriterRecommendations) && (
                      <RiskWriterPart name="recommendations" title="Recommendations">
                        {renderComponent(RiskWriterRecommendations)}
                      </RiskWriterPart>
                    )}
                    {reportWriterFormat?.brief_form !== undefined && reportWriterFormat.brief_form !== null && (
                      <RiskWriterPart
                        name="brief"
                        title={nameToLabel(reportWriterFormat.brief_form.name, reportWriterFormat.brief_form.title)}
                        action={
                          editBriefUrl !== undefined ? (
                            <Grid container>
                              <Grid item>
                                <Button component={Link} to={editBriefUrl}>
                                  {t("Edit")}
                                </Button>
                              </Grid>
                            </Grid>
                          ) : (
                            <></>
                          )
                        }>
                        {renderComponent(RiskWriterBrief)}
                      </RiskWriterPart>
                    )}
                    {reportWriterFormat?.sections !== undefined && reportWriterFormat.sections !== null && (
                      <RiskWriterPart
                        name="narrative"
                        title="Narrative"
                        action={
                          <Grid container>
                            <Grid item>
                              <Button onClick={onClearContent}>Clear</Button>
                            </Grid>
                            <Grid item>
                              <Button startIcon={<AutoModeIcon />} onClick={onGenerateAll}>
                                Write Content
                              </Button>
                            </Grid>
                          </Grid>
                        }>
                        {reportWriterFormat.sections.map((section: IRiskWriterSection) => {
                          return (
                            <Grid container spacing={1} key={section.name}>
                              <Grid item xs={12}>
                                <RiskWriterSection section={section}>
                                  {section.sections?.map((subSection: IRiskWriterSection) => {
                                    return <RiskWriterSection key={subSection.name} isSubSection section={subSection} />
                                  })}
                                </RiskWriterSection>
                              </Grid>
                            </Grid>
                          )
                        })}
                      </RiskWriterPart>
                    )}
                  </Container>
                </Box>
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default RiskWriter
