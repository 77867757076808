import React, { type ChangeEvent, useCallback, useState } from "react"
import { Button, Grid, TextField } from "@mui/material"
import { useFilterDataAvailable } from "./FiltersData"
import AddIcon from "@mui/icons-material/Add"
import { type IFilter } from "../../models/components/IFilter"
import FormatNumber from "../format/FormatNumber"

interface IProps {
  field: string
  title: string
  numberType?: "currency" | "number"
  children?: React.JSX.Element[]
}

const twoDecimalFormatOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}

/**
 * Renders a number range filter component that allows users to specify minimum and maximum values.
 *
 * @param {IProps} props - The input props containing field and title for the filter.
 * @returns {React.ReactElement} The rendered filter component for specifying a number range.
 */
const FilterNumber = (props: IProps): React.ReactElement => {
  const { field, title } = props

  const [minNumber, setMinNumber] = useState<number | null>(0)
  const [maxNumber, setMaxNumber] = useState<number | null>(1000)

  const filtersDataContext = useFilterDataAvailable(field, title)

  const handleAddFilter = useCallback(() => {
    if (minNumber !== null && maxNumber !== null) {
      const minFormatted = minNumber.toLocaleString(undefined, twoDecimalFormatOptions)
      const maxFormatted = maxNumber.toLocaleString(undefined, twoDecimalFormatOptions)
      const filter: IFilter = {
        title: `Range for ${title}`,
        field,
        value: `${minNumber},${maxNumber}`,
        display: `${minFormatted} to ${maxFormatted}`,
      }
      filtersDataContext?.addFilter(filter)
    }
  }, [field, title, minNumber, maxNumber, filtersDataContext?.addFilter])

  const handleStartDateChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setMinNumber(Number(event.target.value))
  }, [])

  const handleEndDateChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setMaxNumber(Number(event.target.value))
  }, [])

  return (
    <>
      {filtersDataContext?.selectedFilter?.value === field && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Min Number"
              onChange={handleStartDateChange}
              value={minNumber}
              helperText={<FormatNumber value={minNumber} prefixUnits={false} suffixUnits={false} />}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Max Number"
              onChange={handleEndDateChange}
              value={maxNumber}
              helperText={<FormatNumber value={maxNumber} prefixUnits={false} suffixUnits={false} />}
            />
          </Grid>
          <Grid item xs />
          <Grid item>
            <Button
              startIcon={<AddIcon />}
              disabled={minNumber === null || maxNumber === null}
              onClick={handleAddFilter}>
              Add Filter
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default FilterNumber
