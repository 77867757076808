import React from "react"
import { Alert, Container, Grid } from "@mui/material"
import ViewLoading from "../ViewLoading"

interface IProps {
  children: React.JSX.Element[] | React.JSX.Element | undefined | null | boolean
  loading?: boolean | undefined
}

/**
 * Renders a page view body with optional loading indicator.
 *
 * @param {IProps} props - The props for the PageViewBody component.
 * @returns {React.ReactElement} A React element representing the page view body.
 */
const PageViewBody: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children, loading } = props

  return (
    <Container fixed>
      <Grid container spacing={2}>
        {loading !== undefined && (
          <>
            <Grid item xs={12}>
              <ViewLoading loading={loading} />
            </Grid>
            {!loading && children === false && (
              <Grid item xs={12}>
                <Alert severity="error">Sorry, could not find what you are looking for.</Alert>
              </Grid>
            )}
          </>
        )}
        {children}
      </Grid>
    </Container>
  )
}

export default PageViewBody
