import React from "react"
import useAuth from "../../hooks/useAuth"

interface IProps {
  component: any
  componentElse?: any
  path?: string
  groupNames?: string[]
}

/**
 * This component show or hide a component and check to see if it is accessible.
 *
 * @param {IProps} props see IProps for details.
 * @returns {React.FC<IProps>} the component or empty fragment.
 */
const PrivateComponent: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { component, componentElse, groupNames } = props
  const { currentUser, isUserInGroups } = useAuth()

  // Check to see if the route has an access group and if the user is in that group.
  if (isUserInGroups?.(groupNames, currentUser) === false) {
    if (componentElse !== undefined) {
      return componentElse
    }
    return <></>
  }

  return component
}

export default PrivateComponent
